import React, { useEffect } from 'react';

import Page from './page';
import Header from './header';
import Sidebar from './sidebar';
import Content from './content';
import ContentHeader from './content-header';

import Main from './main';
import SelectGroup from 'containers/elements/@commons/select-bank-acc-groups';
import authentication from 'utils/authentication';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { selectCompaniesActions, selectCurrencyActions } from 'src/redux/actions';

import styles from './styles.scss';
import { Button } from 'components/index';

const Layout = ({ children }) => {
  const isSession = authentication.hasSession();

  const dispatch = useAppDispatch();

  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedCompaniesInitialized, selectedVkn },
  } = useAppSelector((state) => state);

  useEffect(() => {
    if (isSession && !selectedCompaniesInitialized) {
      dispatch(selectCompaniesActions.initialize());
    }
  }, [isSession]);

  useEffect(() => {
    if (selectedVkn === null) return;
    if (isSession) {
      dispatch(selectCurrencyActions.initializeCurrencies({ selectedVkn, selectedBankAccountGroups }));
    }
  }, [isSession, selectedVkn, selectedBankAccountGroups]);

  if (!isSession) {
    return children;
  }

  return (
    <Page>
      <Header />
      <ContentHeader />
      <Content>
        <div className={styles.sidebar}>
          <div style={{ marginBottom: '10px' }}>
            <SelectGroup />
          </div>
          <Sidebar />
        </div>

        <Main>{children}</Main>
        <Button
          style={{ position: 'fixed', bottom: '30px', right: '30px' }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}>
          <i className={'icon-icon-navigation-expand-less-24-px'} />
        </Button>
      </Content>
    </Page>
  );
};

export default Layout;
