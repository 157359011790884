import React from 'react';

import Language from 'library/@e5p-languages';

import LanguageButton from 'components/language-button';
import { Select, SelectItem } from 'components';

import tr from 'assets/images/flags/turkey.svg';
import en from 'assets/images/flags/uk.svg';

import styles from './styles.scss';

const SelectLanguage = ({ lang, changeLanguage }) => {
  const [flag, setFlag] = React.useState(lang === 'tr' ? tr : en);

  React.useEffect(() => {
    setFlag(lang === 'tr' ? tr : en);
  }, [lang]);
  const languages = [
    {
      value: 'tr',
      flag: tr,
    },
    {
      value: 'en',
      flag: en,
    },
  ];
  return (
    <div className={styles.buttons}>
      <Select
        placeholder={<LanguageButton flag={flag} />}
        className={styles['select-language']}
        selectClassName={styles['select-language-container']}>
        {languages.map((language, index) => (
          <SelectItem
            key={index}
            value={language.value}
            className={styles['select-language-item']}
            onClick={() => {
              changeLanguage(language.value);
            }}>
            <LanguageButton flag={language.flag} />
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

export default Language(SelectLanguage);
