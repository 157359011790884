import React from 'react';

import CurrencyItem from 'components/currency-item';

import request from 'library/@e5p-request';

import styles from './styles.scss';

import { useAppSelector } from 'src/redux/store';

import cx from 'classnames';
import { Loader } from 'components/index';

const CurrencyList = () => {
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = React.useState(false);
  const [currencyBalanceList, setCurrencyBalanceList] = React.useState([]);
  React.useEffect(() => {
    if (selectedVkn === null) return;
    setLoading(true);
    request
      .getBalanceList({ selectedVkns: selectedVkn, selectedBankAccountGroups })
      .then((res) => {
        setCurrencyBalanceList(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [selectedVkn, selectedBankAccountGroups]);
  if (loading) {
    return <Loader />;
  }
  return (
    <div className={cx(styles['currency-list-wrapper'], styles[`currency-list-wrapper-${theme}`])}>
      <div className={cx(styles['currency-list'], styles[`currency-list-${theme}`])}>
        {currencyBalanceList.map((item, index) => {
          return (
            <CurrencyItem
              key={(Math.random() * index).toString()}
              symbol={item.currencySymbol}
              value={item.totalBalance}
              currency={item.currency}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CurrencyList;
