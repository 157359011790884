import React from 'react';
import cx from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Checkbox, Icon, Select } from 'components';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';
import Input from 'components/input';
import { Loader } from 'components';

const SelectBankAccounts = ({ intl, accounts = [], banksWithAccountsList, onChange, loading }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [selectedBankAccounts, setSelectedBankAccounts] = React.useState(accounts);
  const [selectedAccordion, setSelectedAccordion] = React.useState(null);
  const [bankListWithAccountList, setBankListWithAccountList] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');

  const selectAccount = (e, bankItem, account) => {
    e.stopPropagation();

    const selectedBankAccountsClone = [...selectedBankAccounts];
    const bankListWithAccountListClone = [...bankListWithAccountList];

    const selectedBank = bankListWithAccountListClone.find((bank) => bank.bankCode === bankItem.bankCode);
    const selectedBankAccount = selectedBank.accounts.find(
      (selectedAccount) => selectedAccount.bankAccountNo === account,
    );

    let items = [];

    if (selectedBankAccount?.isSelected) {
      selectedBankAccount.isSelected = false;

      if (bankItem.isChecked) {
        if (selectedBank) {
          selectedBank.isChecked = false;
        }
      }

      items = selectedBankAccountsClone.filter((item) => item !== account);
    } else {
      if (selectedBankAccount) {
        selectedBankAccount.isSelected = true;
      }

      const hasNoSelectedBankAccount = bankItem.accounts.find((selectedAccount) => !selectedAccount.isSelected);

      if (!hasNoSelectedBankAccount) {
        selectedBank.isChecked = true;
      }

      items = [...selectedBankAccountsClone, account];
    }

    setBankListWithAccountList(bankListWithAccountListClone);
    setSelectedBankAccounts(items);
  };

  const selectAllAccounts = (e, bankItem) => {
    e.stopPropagation();

    const bankListWithAccountListClone = [...bankListWithAccountList];

    const selectedBank = bankListWithAccountListClone.find((bank) => bank.bankCode === bankItem.bankCode);

    if (selectedBank?.isChecked) {
      selectedBank.isChecked = false;

      const selectedBankAccountsClone = [...selectedBankAccounts];

      selectedBank.accounts.forEach((bankAccount) => {
        const selectedBankAccountItemIndex = selectedBankAccountsClone.findIndex(
          (selectedBankAccount) => selectedBankAccount.bankAccountNo === bankAccount.bankAccountNo,
        );

        if (!selectedBankAccountItemIndex) {
          bankAccount.isSelected = true;
          selectedBankAccountsClone.push(bankAccount.bankAccountNo);
        } else {
          bankAccount.isSelected = false;
          selectedBankAccountsClone.splice(selectedBankAccountItemIndex, 1);
        }
      });

      setSelectedBankAccounts(selectedBankAccountsClone);
      setBankListWithAccountList(bankListWithAccountListClone);
    } else if (selectedBank) {
      selectedBank.isChecked = true;

      const selectedBankAccountsClone = [...selectedBankAccounts];

      selectedBank.accounts.forEach((bankAccount) => {
        const selectedBankAccountItem = selectedBankAccountsClone.find(
          (selectedBankAccount) => selectedBankAccount === bankAccount.bankAccountNo,
        );

        if (!selectedBankAccountItem) {
          bankAccount.isSelected = true;
          selectedBankAccountsClone.push(bankAccount.bankAccountNo);
        }
      });

      setSelectedBankAccounts(selectedBankAccountsClone);
      setBankListWithAccountList(bankListWithAccountListClone);
    }
  };

  React.useEffect(() => {
    onChange(selectedBankAccounts);
  }, [selectedBankAccounts]);

  React.useEffect(() => {
    const banksWithAccountsListClone = [...banksWithAccountsList];

    const sortedBankAccountsList = banksWithAccountsListClone.sort((a, b) => a.bankName.localeCompare(b.bankName));

    const formattedBankAccountsList = sortedBankAccountsList.map((sortedBankItem) => {
      const isCheckedBankItem = sortedBankItem.accounts?.length === accounts.length;

      const newSortedBankItem = {
        ...sortedBankItem,
        isChecked: isCheckedBankItem,

        accounts: sortedBankItem.accounts.map((sortedBankItemAccount) => {
          const isSelectedAccount = accounts.find((acc) => acc === sortedBankItemAccount.bankAccountNo);
          return {
            ...sortedBankItemAccount,
            isSelected: isSelectedAccount ? true : false,
          };
        }),
      };

      return newSortedBankItem;
    });

    setBankListWithAccountList(formattedBankAccountsList);
    setSelectedBankAccounts(accounts);
  }, [banksWithAccountsList]);

  const clearSelectedBankAccounts = () => {
    if (selectedBankAccounts.length > 0) {
      const clearBankListWithAccountList = bankListWithAccountList.map((bankListWithAccount) => {
        const newBankItem = {
          ...bankListWithAccount,

          accounts: bankListWithAccount.accounts.map((accountItem) => {
            return {
              ...accountItem,
              isSelected: false,
            };
          }),
        };

        return newBankItem;
      });

      setBankListWithAccountList(clearBankListWithAccountList);
      setSelectedBankAccounts([]);
    }
  };

  const renderPlaceholder = () => {
    if (selectedBankAccounts.length > 1) {
      return (
        <>
          {selectedBankAccounts.length}{' '}
          <FormattedMessage id="organisms.activities.filter.currencySelectionLabel.selected" defaultMessage="seçim" />
        </>
      );
    }

    if (selectedBankAccounts.length === 1) {
      return (
        <div className={cx(styles['select-item-selected'], styles[`select-item-selected-${theme}`])}>
          {selectedBankAccounts[0]}
        </div>
      );
    }

    return <FormattedMessage id="molecule.dropdown.chooseBankAccount" defaultMessage="Banka Hesabı Seçin" />;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Select
      multiple
      placeholder={renderPlaceholder()}
      selected={selectedBankAccounts}
      className={cx(styles['filters-select-bank-accounts'], styles[`filters-select-bank-accounts-${theme}`])}
      selectClassName={styles['filters-select-bank-accounts-container']}
      clearSelectedItems={clearSelectedBankAccounts}>
      <Input
        onClick={(e) => {
          e.stopPropagation();
        }}
        value={searchText}
        placeholder={intl.formatMessage({
          id: 'molecule.dropdown.selectBankAccounts.searchInputPlaceholder',
          defaultMessage: 'Hesap No',
        })}
        onChange={(e) => setSearchText(e.target.value)}
      />

      {bankListWithAccountList.map((bankItem, index) => {
        const isSelectedAccordion = selectedAccordion === index;

        return (
          <div className={cx(styles['option-item'], styles[`option-item-${theme}`])} key={index.toString()}>
            <div
              className={cx(styles['option-item-header'], styles[`option-item-header-${theme}`])}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedAccordion(isSelectedAccordion ? null : index);
              }}>
              <div className={cx(styles['option-item-header-left'])}>
                <Checkbox
                  checked={bankItem.isChecked}
                  onClick={(e) => {
                    selectAllAccounts(e, bankItem);
                  }}
                />
                <img
                  src={bankItem.bankIconLogo}
                  className={styles['option-item-header-logo']}
                  //  alt={`${bankItem.bankName} logo`}
                  alt=""
                />
                {bankItem.bankName}
              </div>
              <Icon
                name={isSelectedAccordion ? 'icon-navigation-expand-less-24-px' : 'icon-navigation-expand-more-24-px'}
              />
            </div>
            {isSelectedAccordion && (
              <div className={styles['option-item-sub']}>
                {bankItem.accounts
                  .filter((account) => account.bankAccountNo.includes(searchText))
                  .map((account, i) => {
                    return (
                      <div
                        className={cx(styles['option-item-sub-item'], styles[`option-item-sub-item-${theme}`], {
                          [styles['option-item-sub-item-selected']]: account.isSelected,
                        })}
                        key={i.toString()}
                        onClick={(e) => selectAccount(e, bankItem, account.bankAccountNo)}>
                        <div>
                          <span
                            style={{ fontSize: 20 }}
                            className={cx(
                              `${account.currency}`,
                              styles['currency-symbol'],
                              styles[`currency-symbol-${theme}`],
                            )}>
                            {account.currencySymbol}
                          </span>
                          <div style={{ fontSize: 14 }}>
                            {account.bankAccountTitle
                              ? account.bankAccountTitle
                              : `${account.branchName ? '-' + account.branchName : ''}  ${account.bankAccountNo}`}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        );
      })}
    </Select>
  );
};

export default injectIntl(SelectBankAccounts);
