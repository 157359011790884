import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedBank: null,
  bankCode: null,
  selectedBankLoading: true,
};

export const selectBankReducer = createSlice({
  initialState,
  name: 'selectBank',
  reducers: {
    setSelectedBank: (state, action) => {
      const selectedBank = action.payload;

      state.selectedBank = {
        ...selectedBank,
      };

      state.selectedBankLoading = false;
    },
    setBankCode: (state, action) => {
      const bankCode = action.payload;

      state.bankCode = bankCode;
    },
    setSelectedBankLoading: (state, action) => {
      state.selectedBankLoading = action.payload;
    },
  },
});

export const { setSelectedBank, setBankCode, setSelectedBankLoading } = selectBankReducer.actions;

export default selectBankReducer.reducer;
