import { gql } from '@apollo/client';

const companyGroups = () => {
  return {
    query: gql`
      query {
        companyQuery {
          companies(onlyUnGroupedCompanies: true) {
            vkn
            name
          }
          groups {
            name
            isLastChild
            groupCompanies {
              name
              vkn
            }
            children {
              name
              isLastChild
              children {
                children {
                  name
                  isLastChild
                  groupCompanies {
                    name
                    vkn
                  }
                }
                groupCompanies {
                  name
                  vkn
                }
              }
              groupCompanies {
                vkn
                name
              }
            }
          }
        }
      }
    `,
    variables: {},
  };
};

export default companyGroups;
