import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Authentication from 'utils/authentication';
import request from 'library/@e5p-request';

import cx from 'classnames';

import Page from 'containers/layouts/page';
import Content from 'containers/layouts/content';
import Main from 'containers/layouts/main';

import Card from 'components/card';
import Widget from 'components/widget';
import Input from 'components/input';
import Button from 'components/button';

import Loader from 'components/loader';

import Logo from 'components/logo';

import SelectLanguage from 'containers/elements/@commons/select-language';
import SelectTheme from 'containers/elements/@commons/select-theme';

import Text from 'components/text';

import styles from './styles.scss';

const Login = ({ history }) => {
  const router = useHistory();
  const { checkSso } = request;

  useEffect(() => {
    const isSession = Authentication.hasSession();

    if (isSession) {
      router.replace('/dashboard');
    }
  }, []);

  const [ssoLogin, setSsoLogin] = React.useState(false);
  const [ssoUrl, setSsoUrl] = React.useState('');
  useEffect(() => {
    checkSso().then((res) => {
      if (res.data.ssoRedirect === true) {
        setSsoLogin(true);
        setSsoUrl(res.data.redirectUrl);
      }
    });
  }, []);

  const ssoButton = () => {
    if (ssoUrl !== '' || ssoUrl !== null) {
      window.location.href = ssoUrl;
    }
  };

  const [form, setForm] = React.useState({
    username: {
      value: '',
      error: false,
    },
    password: {
      value: '',
      error: false,
    },
  });

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const onChange = (key, e) => {
    setForm({
      ...form,
      [key]: {
        value: e.target.value,
        error: false,
      },
    });
    setError(false);
  };

  const onSubmit = () => {
    const { username, password } = form;

    if (!username.value || !password.value || username.value === '' || password.value === '') {
      setForm({
        username: {
          value: username.value,
          error: !username.value,
        },
        password: {
          value: password.value,
          error: !password.value,
        },
      });
      return;
    }

    setLoading(true);

    Authentication.login({
      username: form.username.value,
      password: form.password.value,
    })
      .then((data) => {
        if (data.data.redirectUrl) {
          window.open(data.data.redirectUrl, '_blank');
        }
        if (data.data.ticket) {
          history.push('/verify', { ...data.data });
        }
        if (data.data.token) {
          Authentication.setToken(data.data.token);
          localStorage.setItem('userBankDataSource', data.data?.bankDataSource);
          window.location = '/';
        }
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };
  return (
    <Page>
      <Content className={styles.content}>
        <Main>
          <Widget row={3} center>
            <Card
              style={{}}
              leftItem={<div />}
              rightItem={
                <div className={styles.rightItems}>
                  <div>
                    <SelectLanguage />
                  </div>
                  <div>
                    <SelectTheme />
                  </div>
                </div>
              }>
              <Logo className={styles.logo} />
              {(form.username.error || form.username.error || error) && (
                <Text className={styles.error}>
                  <FormattedMessage id="login.error" defaultMessage="Lütfen bilgilerinizi kontrol ediniz." />
                </Text>
              )}
              <FormattedMessage id="login.username" defaultMessage="Username">
                {(msg) => {
                  return (
                    <Input
                      value={form.username.value}
                      placeholder={msg}
                      onChange={onChange.bind(this, 'username')}
                      error={form.username.error}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') return onSubmit();
                      }}
                    />
                  );
                }}
              </FormattedMessage>

              <FormattedMessage id="login.password" defaultMessage="Password">
                {(msg) => {
                  return (
                    <Input
                      value={form.password.value}
                      type="password"
                      placeholder={msg}
                      onChange={onChange.bind(this, 'password')}
                      error={form.password.error}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') return onSubmit();
                      }}
                    />
                  );
                }}
              </FormattedMessage>
              <Button
                transparent
                style={{ justifyContent: 'flex-end' }}
                onClick={() => {
                  window.location = '/forgot-password';
                }}>
                <FormattedMessage id="login.forgotPassword" defaultMessage="Şifremi unuttum" />
              </Button>
              <Button onClick={onSubmit} disabled={loading} className={styles.button}>
                {loading ? (
                  <Loader />
                ) : (
                  <Text button className={styles['button-text']}>
                    <FormattedMessage id="general.login" defaultMessage="Giriş Yap" />
                  </Text>
                )}
              </Button>
              <br></br>
              {ssoLogin && (
                <Button onClick={ssoButton} disabled={loading} className={cx(styles['button-group'])}>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Text button className={styles['button-text']}>
                      <FormattedMessage id="general.ssologin" defaultMessage="SSO İle Giriş Yap" />
                    </Text>
                  )}
                </Button>
              )}
            </Card>
          </Widget>
        </Main>
      </Content>
    </Page>
  );
};

export default Login;
