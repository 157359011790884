import React from 'react';
import cx from 'classnames';

import Select from './items/dropdown-select';
import { Input } from 'components/index';
import { formattedOptions, getLabelFromValue, getDefaultValues, getSelectedItemIndex } from './items/helpers';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Dropdown = ({
  value,
  options,
  placeholder,
  onChange,
  className,
  selectClassName,
  iconWrapperClassname,
  renderOptions,
  multiSelect = false,
  multiSelectText,
  removedDisabled = false,
  transparent,
  hideDefaultClearButton = false,
  showSearchInput = false,
  searchInputPlaceHolder,
}) => {
  const { theme } = useAppSelector((state) => state.theme);

  /** DROPDOWN STATE */
  const [isShowOptions, setShowOptions] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  /** SELECTED VALUES STATE */
  const [selectedValue, setSelectedValue] = React.useState(getDefaultValues(value, multiSelect));

  /* DROPDOWN TOGGLE */
  const showOptionsToggle = (e) => {
    e.stopPropagation();
    setShowOptions(!isShowOptions);
  };

  /** OPTION SELECT */
  const selectOption = (selectedItem, e) => {
    e.stopPropagation();
    let cloneSelectedValues;
    if (multiSelect) {
      cloneSelectedValues = [...selectedValue];
      const index = getSelectedItemIndex(cloneSelectedValues, selectedItem);
      if (index < 0) {
        cloneSelectedValues.push(selectedItem);
      } else {
        cloneSelectedValues.splice(index, 1);
      }
    } else {
      cloneSelectedValues = selectedItem;
    }

    setSelectedValue(cloneSelectedValues);
    if (onChange) {
      onChange(cloneSelectedValues);
    }
  };

  /** SELECTED VALUE ONCHANGE */
  React.useEffect(() => {
    setShowOptions(false);
  }, [selectedValue]);

  React.useEffect(() => {
    const values = getDefaultValues(value, multiSelect);
    setSelectedValue(values);
  }, [value]);

  /** RESET DROPDOWN SELECTED ITEM */
  const removeSelectedItems = (e) => {
    e.stopPropagation();

    if (!removedDisabled && multiSelect && selectedValue.length > 0) {
      setSelectedValue([]);
      setShowOptions(false);
      onChange([]);
      return;
    }
    if (!removedDisabled && !multiSelect && selectedValue) {
      setSelectedValue(null);
      setShowOptions(false);
      onChange(null);
      return;
    }

    setShowOptions(!isShowOptions);
  };

  /** WHEN OUTSIDE CLICK AUTO CLOSED */
  React.useEffect(() => {
    if (isShowOptions === true) {
      document.addEventListener('click', () => {
        setShowOptions(false);
      });
      document.removeEventListener('click', () => {});
    }

    setSearchText('');
  }, [isShowOptions]);
  /** FINISH WHEN OUTSIDE CLICK AUTO CLOSED */

  /* IS SELECTED ITEM */
  const isSelectedItem = (selectedItem, isMultiSelect) => {
    if (!isMultiSelect) return selectedItem === selectedValue;

    const cloneSelectedValues = [...selectedValue];
    const index = getSelectedItemIndex(cloneSelectedValues, selectedItem);
    return index > -1;
  };

  return (
    <div
      className={cx(
        styles.dropdown,
        {
          [styles[`dropdown-transparent`]]: transparent,
        },
        className,
      )}
      onClick={showOptionsToggle}>
      <Select
        placeholder={placeholder}
        value={getLabelFromValue(formattedOptions(options), selectedValue, multiSelect)}
        removeItem={removeSelectedItems}
        multiSelect={multiSelect}
        multiSelectText={multiSelectText}
        removedDisabled={removedDisabled}
        className={selectClassName}
        iconWrapperClassname={iconWrapperClassname}
        isShowOptions={isShowOptions}
        theme={theme}
        transparent={transparent}
        hideDefaultClearButton={hideDefaultClearButton}
      />
      <div
        className={cx(styles.options, styles[`options-${theme}`], {
          [styles['options-visible']]: isShowOptions,
        })}>
        {showSearchInput && (
          <Input
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={searchText}
            className={cx([styles['options-input']], styles[`options-input-${theme}`])}
            placeholder={searchInputPlaceHolder || ''}
            onChange={(e) => setSearchText(e.target.value)}
          />
        )}
        {formattedOptions(options)
          .filter((option) => option?.label?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()))
          .map((item, index) => {
            const isSelected = isSelectedItem(item.value, multiSelect);
            return React.cloneElement(renderOptions({ item, index }), {
              onChangeOption: selectOption,
              isSelected,
            });
          })}
      </div>
    </div>
  );
};

export default Dropdown;

export { default as Select } from './items/dropdown-select';
export { default as Option } from './items/dropdown-option';
