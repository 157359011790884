/* eslint-disable no-unused-vars */
import React from 'react';
import cx from 'classnames';

import formattedCurrency from 'utils/formatted-currency';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const CurrencyItem = ({ currency, symbol, value }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={cx(styles['currency-item'], styles[`currency-item-${theme}`], `${currency}`)}>
      <span className={cx(styles['currency-symbol'], styles[`currency-symbol-${theme}`], {})}>{symbol}</span>
      <span className={cx(styles['currency-balance'], styles[`currency-balance-${theme}`])}>
        {formattedCurrency(value)}
      </span>
    </div>
  );
};

export default CurrencyItem;
