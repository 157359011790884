export default Object.freeze({
  name: 'production',
  prod: true,
  'storage-prefix': 'e5p',
  api: {
    'REST-URL': window.configEnv.APP_REST_URL,
    'REST-URL-STATIC': window.configEnv.APP_REST_URL_STATIC,
    'GRAPHQL-URI': window.configEnv.APP_GRAPHQL_URI,
  },
});
