import React from 'react';

import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const useImageError = () => {
  const [el, setEl] = React.useState(null); // contains image reference
  const [error, setError] = React.useState(false); // contains error flag

  const _handleError = () => {
    setError(true);
  }; // set error
  const retry = () => {
    setError(false);
  }; // set error false to img can re-render

  React.useEffect(() => {
    // use of error event of the image tag
    el?.addEventListener('error', _handleError);

    return () => {
      el?.removeEventListener('error', _handleError);
    };
  }, [el]);

  return [
    setEl, // set the image ref
    error, // error flag
    retry, // a func, which can be used to re-render image
    el, // img ref(for special case which requires ref)
  ];
};

const BankLogo = ({ logoUrl, bankName }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [setImg, hasError] = useImageError();

  if (hasError) {
    return (
      <div className={cx(styles['bank-logo'])}>
        <div className={cx(styles['bank-name'], styles[`bank-name-${theme}`])}>{bankName}</div>
      </div>
    );
  }
  return (
    <div className={cx(styles['bank-logo'])}>
      <img ref={setImg} src={logoUrl} alt="" />
    </div>
  );
};

export default BankLogo;
