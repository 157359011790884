import React from 'react';
import cx from 'classnames';

import Language from 'library/@e5p-languages';

import Loader from 'components/loader';
import { useAppSelector } from 'src/redux/store';
import { useIntl } from 'react-intl';

import styles from './styles.scss';

const CompanySelectLabel = ({ lang }) => {
  const {
    selectCompanies: { selectedCompanies, loading },
    theme: { theme },
  } = useAppSelector((state) => state);

  const intl = useIntl();

  const [label, setLabel] = React.useState('');

  const getLabelFromSelectedCompanies = (selectedCompanies) => {
    if (selectedCompanies.length > 1) {
      const groupCount = selectedCompanies.filter((i) => i.isNode).length;
      const companyCount = selectedCompanies.filter((i) => i.isLeaf).length;

      const labelItem =
        groupCount > 0
          ? `${groupCount} ${intl.formatMessage({
              id: 'organism.selectCompaniesNode.label',
              defaultMessage: 'Şirket Grubu',
            })}`
          : '';

      const labelItem2 =
        companyCount > 0
          ? `${companyCount} ${intl.formatMessage({
              id: 'organism.selectCompaniesLeaf.label',
              defaultMessage: 'Şirket',
            })}`
          : '';
      const and =
        groupCount > 0 && companyCount > 0
          ? intl.formatMessage({ id: 'organism.selectCompaniesAnd.label', defaultMessage: 've' })
          : '';

      return `${labelItem} ${and} ${labelItem2} ${intl.formatMessage({
        id: 'organism.selectCompaniesSelected.label',
        defaultMessage: 'seçildi',
      })}`;
    }

    const selectedCompanyName =
      selectedCompanies?.[0]?.name === 'Tüm Şirketler'
        ? intl.formatMessage({ id: 'organism.selectCompaniesAllCompanies.label', defaultMessage: 'Tüm Şirketler' })
        : selectedCompanies?.[0]?.name;

    return selectedCompanyName;
  };

  React.useEffect(() => {
    const labelText = getLabelFromSelectedCompanies(selectedCompanies);

    setLabel(labelText);
  }, [selectedCompanies, lang]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={cx(styles['company-select-button'], styles[`company-select-button-${theme}`])}>
        <p>
          <abbr title={label}>{label}</abbr>
        </p>
      </div>
    </>
  );
};

export default Language(CompanySelectLabel);
