import request from 'library/@e5p-request';

import { storageKeys } from 'library/@e5p-request/config/constants';
import { local, token } from 'library/@e5p-request/helpers';

class Authentication {
  constructor() {
    this.token = this.getToken();

    if (token.isExpired(this.token)) {
      this.clearCache();
    }
  }

  login(params) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await request.login(params);
        if (!response.data.isSuccess) {
          throw new Error(response.data.message);
        }

        resolve(response);
      } catch (error) {
        const status = error.response?.status;
        if (status === 302) {
          resolve({ ...error.response });
        } else {
          reject(error);
        }
      }
    });
  }

  forgotPassword(params) {
    return new Promise((resolve, reject) => {
      request
        .forgotPassword(params)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  resetPassword(params) {
    return new Promise((resolve, reject) => {
      request
        .resetPassword(params)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  logout() {
    this.clearCache();
    setTimeout(() => {
      window.location = '/';
    }, 0);
  }

  setToken(token) {
    if (token) {
      local.setItem(storageKeys.token, token);
    }
  }

  getToken() {
    return local.getItem(storageKeys.token);
  }

  hasSession() {
    const token = this.getToken();
    return !!token;
  }

  getUserDataByKey(keys) {
    const user = local.getItem(storageKeys.user);
    if (!keys || keys.length === 0) return user;
    return keys.map((key) => {
      return user[key];
    });
  }

  verifyCode(params) {
    return request
      .verifyCode(params)
      .then(({ data }) => {
        if (!data.isSuccess) {
          throw new Error(data.message);
        }
        this.setToken(data.token);
        return data.token;
      })
      .catch((err) => {
        return err;
      });
  }

  resendCode(params) {
    return request
      .resendCode(params)
      .then(({ data }) => {
        if (!data.isSuccess) {
          throw new Error(data.message);
        }
        return data;
      })
      .catch((err) => {
        return err;
      });
  }

  clearCache() {
    local.removeItem(storageKeys.user);
    local.removeItem(storageKeys.token);
    local.removeItem('selectedVkn');
    local.removeItem('defaultCompanies');
    local.removeItem('selectedCompanies');
    local.removeItem('formattedCompanies');
    local.removeItem('getCompaniesByGroup');
  }
}

export default new Authentication();
