import React, { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';

import PublicRoute from 'routes/public-route';
import PrivateRoute from 'routes/private-route';

import Authentication from 'utils/authentication';

import Layout from 'containers/layouts';

import * as Pages from 'pages';
import authentication from 'utils/authentication';

import { initTheme } from 'src/redux/reducers/theme/theme-reducer';
import { useAppDispatch } from 'src/redux/store';

const Routes = () => {
  const dispatch = useAppDispatch();
  const isSession = Authentication.hasSession();
  const redirect = isSession ? '/dashboard' : '/login';

  const logoutTime = 14400000;

  useLayoutEffect(() => {
    dispatch(initTheme());
  }, []);

  useEffect(() => {
    if (isSession) {
      document.addEventListener('visibilitychange', function () {
        let interval;
        if (document.visibilityState === 'visible') {
          clearInterval(interval);
        } else {
          interval = setInterval(() => {
            authentication.clearCache();
            window.location.href = '/';
          }, logoutTime);
        }
      });
    }
  }, []);
  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <PublicRoute path="/forgot-password" component={Pages.ForgotPassword} />
          <PublicRoute path="/reset-password" component={Pages.ResetPassword} />
          <PublicRoute path="/login" component={Pages.Login} />
          <PublicRoute path="/verify" component={Pages.Verify} />
          <PublicRoute path="/loginWithToken" component={Pages.loginWithToken} />
          <PrivateRoute path="/bank-accounts" component={Pages.BankAccounts} permission={isSession} />
          <PrivateRoute path="/bank-activity" component={Pages.BankActivity} permission={isSession} />
          <PrivateRoute path="/banks/:bankId?" component={Pages.Banks} permission={isSession} />
          <PrivateRoute path="/dashboard" component={Pages.Dashboard} permission={isSession} />
          <PrivateRoute path="/daily-summary" component={Pages.DailySummary} permission={isSession} />
          <PrivateRoute path="/company-accounts" component={Pages.CompanyAccounts} permission={isSession} />
          <PrivateRoute path="/defaultCompanySelect" component={Pages.defaultCompanySelect} permission={isSession} />
          {/*        <PrivateRoute path="/money-transfers" component={Pages.MoneyTransfers} permission={isSession} /> */}
          <PrivateRoute path="/maturity-accounts" component={Pages.MaturityAccounts} permission={isSession} />
          <PrivateRoute path="/tangible-assets" component={Pages.TangibleAssets} permission={isSession} />
          <PrivateRoute path="/receipt-preview" component={Pages.ReceiptPreview} permission={isSession} />
          <Redirect to={redirect} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Routes;
