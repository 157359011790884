import { gql } from '@apollo/client';

const balanceSummary = (selectedVkns = [], selectedBanks = [], selectedBankAccountGroups) => {
  return {
    query: gql`
      query($selectedVkns: [String], $selectedBanks: [Int], $selectedBankAccountGroups: [Guid]) {
        bankAccountQuery {
          balanceSummary(
            selectedVkns: $selectedVkns
            selectedBanks: $selectedBanks
            selectedBankAccountGroups: $selectedBankAccountGroups
          ) {
            balanceSummaries {
              accountCount
              bankCount
              currency
              currencySymbol
              notViewedAccountCount
              accountCountHasData
              totalBalance
              blockedAmount
            }
            lastBankTransactionDate
            reportMessage
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBanks,
      selectedBankAccountGroups,
    },
  };
};

export default balanceSummary;

/*
const response = {
  "data": {
    "bankAccountQuery": {
      "balanceSummary": [
        {
          "accountCount": 3,
          "bankCount": 3,
          "currency": "TRY",
          "currencySymbol": "₺",
          "notViewedAccountCount": 0,
          "totalBalance": 97200.3
        },
        {
          "accountCount": 3,
          "bankCount": 3,
          "currency": "USD",
          "currencySymbol": "$",
          "notViewedAccountCount": 0,
          "totalBalance": 2980
        },
        {
          "accountCount": 3,
          "bankCount": 3,
          "currency": "EUR",
          "currencySymbol": "€",
          "notViewedAccountCount": 0,
          "totalBalance": 1250.28
        }
      ]
    }
  }
}
*/
