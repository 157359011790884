import React from 'react';

import request from 'library/@e5p-request';

import styles from './styles.scss';

import PrintIcon from 'assets/images/receipt-icons/print.png';
import DownloadIcon from 'assets/images/receipt-icons/download.png';
import EmailIcon from 'assets/images/receipt-icons/email.png';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

const { getBankReceiptHTML, getBankReceipt, sendReceiptToMail } = request;

const ReceiptPreview = () => {
  const [html, setHtml] = React.useState(null);

  const getParamsFromUrl = () => {
    const params = new URLSearchParams(document.location.search.substring(1));
    const bankCode = parseInt(params.get('bankCode'), 10);
    const accountNo = params.get('accountNo');
    const transactionId = params.get('transactionId');

    return {
      bankCode,
      accountNo,
      transactionId,
      fileType: 'HTML',
    };
  };

  React.useEffect(() => {
    const params = getParamsFromUrl();
    getBankReceiptHTML(params)
      .then((res) => {
        const { data } = res;
        setHtml(data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const print = () => {
    if (html) {
      const pri = document.getElementById('ifmcontentstoprint').contentWindow;
      pri.document.open();
      pri.document.write(html);
      pri.document.close();
      pri.focus();
      setTimeout(() => {
        pri.print();
      }, 1000);
    }
  };

  const download = async () => {
    try {
      const params = getParamsFromUrl();
      const { bankCode, accountNo, transactionId } = params;
      await getBankReceipt(bankCode, accountNo, transactionId);
    } catch (error) {
      console.log({ error });
      return error;
    }
  };

  const send = async () => {
    try {
      const params = getParamsFromUrl();
      const { bankCode, accountNo, transactionId } = params;
      await sendReceiptToMail({ bankCode, accountNo, transactionId });
    } catch (error) {
      console.log({ error });
      return error;
    }
  };

  return (
    <div
      className={cx({
        [styles.bodycontent]: true,
      })}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.95)',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        overflowY: 'scroll',
        zIndex: 99,
      }}>
      <div className={styles.content}>
        <div
          id={styles.receipt}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />

        <div className={styles.buttons}>
          <div className={styles.button} onClick={print}>
            <img src={PrintIcon} alt="print" />
            <FormattedMessage id="organisms.activities.filter.receipt.print" defaultMessage="YAZDIR" />
          </div>
          <div className={styles.line} />
          <div className={styles.button} onClick={download}>
            <img src={DownloadIcon} alt="download" />
            <FormattedMessage id="organisms.activities.filter.receipt.download" defaultMessage="İNDİR" />
          </div>
          <div className={styles.line} />
          <div className={styles.button} onClick={send}>
            <img src={EmailIcon} alt="email" />
            <FormattedMessage id="organisms.activities.filter.receipt.eReceipt" defaultMessage="E-DEKONTs" />
          </div>
        </div>
        <iframe
          id="ifmcontentstoprint"
          style={{ display: 'none', height: '0px', width: '0px', position: 'absolute' }}></iframe>
      </div>
    </div>
  );
};

export default ReceiptPreview;
