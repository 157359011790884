import { gql } from '@apollo/client';

const notifications = (filter = '', onlyNotViewed = true, pageNumber = 1, pageSize = 10) => {
  return {
    query: gql`
      query($filter: String, $onlyNotViewed: Boolean, $pageNumber: Int, $pageSize: Int) {
        notificationQuery {
          history(filter: $filter, onlyNotViewed: $onlyNotViewed, pageNumber: $pageNumber, pageSize: $pageSize) {
            result {
              content
              date
              id
              image
              title
              type
              viewed
            }
            totalCount
          }
        }
      }
    `,
    variables: {
      filter,
      onlyNotViewed,
      pageNumber,
      pageSize,
    },
  };
};

export default notifications;
