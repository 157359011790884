import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import { FormattedMessage, useIntl } from 'react-intl';

import decoder from 'library/@e5p-request/helpers/jwt-decoder';
import Authentication from 'utils/authentication';

import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { useModal } from 'src/hooks/useModal';

import toast, { Toaster } from 'react-hot-toast';

import request from 'library/@e5p-request';

import Icon from 'components/icon';
import Text from 'components/text';
import Modal from 'components/modal';
import Loader from 'components/loader';

import styles from './styles.scss';
import { getDefaultCompanies } from 'src/redux/reducers/select-companies/select-companies-reducer';
import SelectCompanyDefaultPopup from 'containers/elements/@commons/SelectCompanyDefaultPopup';

const { connectToPlaid, getCompanies, userLogout } = request;

const sidebarItems = [
  /*   {
    id: 'menu',
    default: 'Menu',
    onClick: () => {},
    icon: 'icon-navigation-menu-24-px',
  }, */
  {
    id: 'dashboard',
    default: <FormattedMessage id="sidebar.dashboard" defaultMessage="Genel Bakış" />,
    onClick: () => {},
    route: 'dashboard',
    icon: 'icon-action-dashboard-24-px',
  },
  {
    id: 'myAssets',
    default: <FormattedMessage id="sidebar.myAssets" defaultMessage="Varlıklarım" />,
    onClick: () => {},
    route: 'tangible-assets',
    icon: 'icon-action-varl-klar-m',
  },
  {
    id: 'myAssetsx',
    default: <FormattedMessage id="sidebar.myMaturityAssets" defaultMessage="Vadeli Varlıklarım" />,
    onClick: () => {},
    route: 'maturity-accounts',
    icon: 'icon-pie-chart',
  },
  {
    id: 'bankAccounts',
    default: <FormattedMessage id="sidebar.bankAccounts" defaultMessage="Banka Hesapları" />,
    onClick: () => {},
    route: 'bank-accounts',
    icon: 'icon-places-account-balance-wallet-24-px',
  },
  {
    id: 'banks',
    default: <FormattedMessage id="sidebar.banks" defaultMessage="Bankalar" />,
    onClick: () => {},
    route: 'banks',
    icon: 'icon-places-account-balance-24-px',
  },
  {
    id: 'accountActivities',
    default: <FormattedMessage id="sidebar.accountActivities" defaultMessage="Hesap Hareketleri" />,
    onClick: () => {},
    route: 'bank-activity',
    icon: 'icon-action-bar-chart-24',
  },
  {
    id: 'dailySummary',
    default: <FormattedMessage id="sidebar.dailySummary" defaultMessage="Günlük Rapor" />,
    onClick: () => {},
    route: 'daily-summary',
    icon: 'icon-daily-summary',
  },

  /*   {
    id: 'moneyTransfer',
    default: <FormattedMessage id="sidebar.moneyTransfer" defaultMessage="Para Transferi" />,
    onClick: () => {},
    route: 'money-transfers',
    icon: 'icon-places-money-transfer-24-px-v-2',
  }, */
];

const SideBar = () => {
  const dispatch = useAppDispatch();
  const {
    theme: { theme },
    selectCompanies: { isPopUp, defaultVkns },
  } = useAppSelector((state) => state);

  const intl = useIntl();
  const { isShowing, toggle } = useModal();

  const [loading, setLoading] = useState(false);
  const [plaidData, setPlaidData] = useState();
  const [companies, setCompanies] = useState([]);
  const [userBankDataSource, setUserBankDataSource] = useState(false);
  const [defaultCompanyEmpty, setDefaultCompanyEmpty] = useState(true);

  const user = decoder(Authentication.getToken());
  const panelUrl = `${
    user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/webpage']
  }/admin/loginwithtoken?token=${Authentication.getToken()}`;

  const handleClick = async (vkn) => {
    setLoading(true);

    const data = { vkn, oAuthType: 'PLAID', uid: '' };

    try {
      const response = await connectToPlaid(data);

      setPlaidData(response?.data);
      setLoading(false);
    } catch (error) {
      setPlaidData(undefined);
      setLoading(false);

      toast.error(error.response?.data?.message, {
        duration: 4000,
        style: {
          fontFamily: '"ProximaNova-Medium", sans-serif',
        },
      });
    }
  };

  const getCompanyList = async () => {
    const companies = await getCompanies();
    await dispatch(await getDefaultCompanies({ companies }));
    setCompanies(companies);
  };

  const getUserBankDataSource = () => {
    const data = localStorage.getItem('userBankDataSource');

    if (data == 'true') {
      setUserBankDataSource(true);
    } else {
      setUserBankDataSource(false);
    }
  };

  useEffect(() => {
    getCompanyList();

    getUserBankDataSource();
  }, []);

  useEffect(() => {
    if (plaidData) {
      localStorage.setItem('isConnectToPlaid', 'true');
      window.location.href = plaidData.redirectUrl;
    }
  }, [plaidData]);

  const renderModalContent = () => {
    if (loading) {
      return <Loader />;
    }

    return companies?.map((company, index) => (
      <div
        className={cx(styles['sidebar-modal-company-row'], styles[`sidebar-modal-company-row-${theme}`])}
        onClick={() => {
          handleClick(company.vkn);
        }}
        key={`${company.id}-${index}`}>
        {company.name}
      </div>
    ));
  };

  const handleModalToggleClick = () => {
    if (companies?.length === 1) {
      handleClick(companies[0].vkn);

      return;
    }

    toggle();
  };
  const [popUpError, setPopUpError] = useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const funcSetModalLoading = () => {
    setModalLoading(true);
  };
  const changeErrorMessage = (value) => {
    setPopUpError(value);
  };
  return (
    <div className={cx(styles.wrapper, styles[`wrapper-${theme}`])}>
      <div className={cx(styles.sidebar, styles[`sidebar-${theme}`])}>
        {sidebarItems.map((item, index) => {
          return (
            <NavLink
              key={`${item.route}-${index}`}
              to={item.route}
              className={(isActive) =>
                cx(styles[`sidebar-item`], styles[`sidebar-item-${theme}`], {
                  [styles[`sidebar-item-${theme}--active`]]: isActive,
                })
              }>
              <Icon name={item.icon} className={cx(styles[`sidebar-item-${theme}-icon`])} />
              <Text className={styles['sidebar-item-text']}>
                <span>{item.default}</span>
              </Text>
            </NavLink>
          );
        })}
      </div>
      <div className={cx(styles.sidebar, styles[`sidebar-${theme}`])}>
        {userBankDataSource && (
          <NavLink
            to="company-accounts"
            className={(isActive) =>
              cx(styles[`sidebar-item`], styles[`sidebar-item-${theme}`], {
                [styles[`sidebar-item-${theme}--active`]]: isActive,
              })
            }>
            <Icon name="icon-places-account-balance-24-px" className={cx(styles[`sidebar-item-${theme}-icon`])} />
            <Text className={styles['sidebar-item-text']}>
              <span>
                <FormattedMessage id="sidebar.companyAccounts" defaultMessage="Şirket Hesapları" />
              </span>
            </Text>
          </NavLink>
        )}

        <div className={cx(styles[`sidebar-item`], styles[`sidebar-item-${theme}`])} onClick={handleModalToggleClick}>
          <Icon
            name="icon-navigation-arrow-forward-ios-24-px"
            className={cx(styles[`sidebar-externalLink-${theme}-icon`])}
          />
          <Text className={cx(styles['sidebar-externalLink-text'], styles[`sidebar-externalLink-${theme}`])}>
            <span>
              <FormattedMessage id="sidebar.connectToPlaid" defaultMessage="Plaide Bağlan" />
            </span>
          </Text>
        </div>
        <div
          className={cx(styles[`sidebar-item`], styles[`sidebar-item-${theme}`])}
          onClick={() => {
            if (panelUrl) {
              window.open(panelUrl, '_blank');
            }
          }}>
          <Icon name="icon-action-visibility" className={cx(styles[`sidebar-externalLink-${theme}-icon`])} />
          <Text className={cx(styles['sidebar-externalLink-text'], styles[`sidebar-externalLink-${theme}`])}>
            <span>
              <FormattedMessage id={`sidebar.adminPanel`} defaultMessage="Admin Panel" tagName="span" />
            </span>
          </Text>
        </div>
        <div
          className={cx(styles[`sidebar-item`], styles[`sidebar-item-${theme}`])}
          onClick={async () => {
            await userLogout();
            await localStorage.removeItem('userBankDataSource');
            await Authentication.logout();
          }}>
          <Icon name="icon-action-logout-24-px" className={cx(styles[`sidebar-externalLink-${theme}-icon`])} />
          <Text className={cx(styles['sidebar-externalLink-text'], styles[`sidebar-externalLink-${theme}`])}>
            <span>
              <FormattedMessage id={`sidebar.signOut`} tagName="span" />
            </span>
          </Text>
        </div>
      </div>

      {isShowing && (
        <Modal
          theme={theme}
          hide={toggle}
          title={intl.formatMessage({
            id: 'sidebar.connectToPlaid.modalHeader',
            defaultMessage: 'Şirket seçiniz',
          })}
          closeOnClickOutside={!loading}
          showCloseIcon={!loading}>
          {renderModalContent()}
        </Modal>
      )}
      {isPopUp && !defaultCompanyEmpty && (
        <Modal
          theme={theme}
          title={
            modalLoading === false
              ? intl.formatMessage({
                  id: 'defaultSelectedCompany.popUp',
                  defaultMessage: 'Lütfen Varsayılan Şirket Seçiniz',
                })
              : null
          }
          showCloseIcon={false}>
          {!modalLoading && (
            <div>
              <p style={{ position: `relative`, marginBottom: '40px' }}>
                <Icon
                  name="icon-action-setting-24-px"
                  className={cx(styles[`sidebar-item-${theme}-icon`])}
                  style={{ position: `absolute`, top: '-25px' }}
                />
                <span style={{ position: `absolute`, top: '-22px', left: '30px', fontSize: '13px' }}>
                  <FormattedMessage
                    id={`defaultSelectedCompany.popUpWarning`}
                    defaultMessage="Butonu İle Varsayılan Şirket Seçimlerinizi Değiştirebilirsiniz."
                  />
                </span>
              </p>
            </div>
          )}
          <SelectCompanyDefaultPopup
            onSetModalLoading={() => funcSetModalLoading()}
            changeErrorMessage={(value) => changeErrorMessage(value)}
          />
        </Modal>
      )}

      {popUpError && (
        <Modal
          theme={theme}
          title={intl.formatMessage({
            id: 'defaultSelectedCompany.error',
            defaultMessage: 'Uyarı',
          })}
          closeOnClickOutside
          showCloseIcon
          hide={() => setPopUpError(false)}>
          {popUpError}
        </Modal>
      )}

      {defaultVkns !== null && defaultVkns?.length === 0 && defaultCompanyEmpty && (
        <Modal
          theme={theme}
          title={intl.formatMessage({
            id: 'defaultSelectedCompany.error',
            defaultMessage: 'Uyarı',
          })}
          closeOnClickOutside
          showCloseIcon
          hide={() => setDefaultCompanyEmpty(false)}>
          <FormattedMessage
            id="defaultSelectedCompany.error.company"
            defaultMessage="En Az Bir Varsayılan Şirket Seçmelisiniz."
          />
        </Modal>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default SideBar;
