import Services from 'library/@e5p-services';
import { local } from 'library/@e5p-request/helpers';
import { storageKeys } from 'library/@e5p-request/config/constants';
import config from 'library/@e5p-request/config';
import * as Mutation from './mutations';

const userToken = local.getItem(storageKeys.token);
export const { axios, staticAxios, client, setHeaders } = new Services({
  baseURL: config?.api['REST-URL'] || null,
  staticURL: config?.api['REST-URL-STATIC'] || null,
  uri: config?.api['GRAPHQL-URI'] || null,
  headers: {
    Authorization: userToken ? `Bearer ${userToken}` : undefined,
  },
});

export default {
  saveDefaultCompanies: async ({ data }) => {
    try {
      return await client
        .mutate(Mutation.defaultCompanies({ userDefaultCompany: data }))
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    } catch (error) {
      console.log(`Error ==>`, error);
    }
  },
  savePlaidAccountUid: async ({ bankCode = 0, vkn = '', companyCode = '', uid = '' }) => {
    if (!companyCode) return;
    try {
      return await client
        .mutate(Mutation.setPlaidCompanyCode({ bankCode, vkn, companyCode, uid }))
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    } catch (error) {
      console.log(`Error ==>`, error);
    }
  },
};
