import { gql } from '@apollo/client';

const accountActivities = (
  selectedVkns,
  selectedBankAccounts,
  startDate,
  endDate,
  pageNumber = 1,
  pageSize,
  selectedBanks,
  description,
) => {
  return {
    query: gql`
      query(
        $selectedVkns: [String]
        $selectedBankAccounts: [String]
        $startDate: DateTime
        $endDate: DateTime
        $pageNumber: Int
        $pageSize: Int
        $selectedBanks: [Int]
        $description: String
      ) {
        bankAccountActivityQuery {
          activityHistory(
            selectedVkns: $selectedVkns
            selectedBankAccounts: $selectedBankAccounts
            startDate: $startDate
            endDate: $endDate
            pageNumber: $pageNumber
            pageSize: $pageSize
            selectedBanks: $selectedBanks
            description: $description
          ) {
            result {
              bankName
              branchCode
              accountNo
              currency
              totalBalance
            }
            totalCount
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccounts,
      startDate,
      endDate,
      pageNumber,
      pageSize,
      selectedBanks,
      description,
    },
  };
};

export default accountActivities;
