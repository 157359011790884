import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedGroupLoading: true,
  selectedBankAccountGroupList: [],
  selectedGroupTotalBalance: undefined,
  selectedBankAccountGroups: [], // guid list
  selectedGroup: [], // bank account list for options
};

export const selectGroupsReducer = createSlice({
  initialState,
  name: 'selectGroups',
  reducers: {
    setSelectedGroupLoading: (state, action) => {
      state.selectedGroupLoading = action.payload;
    },
    setSelectedGroupTotalBalance: (state, action) => {
      state.selectedGroupTotalBalance = action.payload;
    },
    onFilterApply: (state, { payload }) => {
      state.selectedBankAccountGroupList = payload;
      state.selectedBankAccountGroups = state.selectedBankAccountGroupList.map((s) => s.guid).filter((s) => Boolean(s));
    },
    setBankAccountGroupList: (state, action) => {
      const { data, isReset } = action.payload;

      state.selectedBankAccountGroups = [];
      state.selectedBankAccountGroupList = [];

      if (isReset) {
        state.selectedGroup = [];

        return;
      }

      state.selectedGroup = data;
    },
    clearSelectBankAccountGroup: (state) => {
      state.selectedBankAccountGroups = [];
      state.selectedBankAccountGroupList = [];
    },
  },
});

export const {
  onFilterApply,
  setSelectedGroupLoading,
  setBankAccountGroupList,
  clearSelectBankAccountGroup,
  setSelectedGroupTotalBalance,
} = selectGroupsReducer.actions;

export default selectGroupsReducer.reducer;
