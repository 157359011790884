import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const useImageError = () => {
  const [el, setEl] = React.useState(null); // contains image reference
  const [error, setError] = React.useState(false); // contains error flag

  const _handleError = () => {
    setError(true);
  }; // set error
  const retry = () => {
    setError(false);
  }; // set error false to img can re-render

  React.useEffect(() => {
    // use of error event of the image tag
    el?.addEventListener('error', _handleError);

    return () => {
      el?.removeEventListener('error', _handleError);
    };
  }, [el]);

  return [
    setEl, // set the image ref
    error, // error flag
    retry, // a func, which can be used to re-render image
    el, // img ref(for special case which requires ref)
  ];
};

const SmallBankButton = ({ active, logo, name, onClick, rate }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [setImg, hasError] = useImageError();

  return (
    <div
      onClick={onClick}
      className={cx(styles.button, styles[`button-${theme}`], {
        [styles[`button-active`]]: active,
      })}>
      <div className={cx(styles['button-icon'], styles[`button-icon-${theme}`])}>
        {!hasError && <img ref={setImg} src={logo} alt={logo} />}
      </div>
      <div
        className={cx(styles['button-text'], styles[`button-text-${theme}`], {
          [styles[`button-text-active`]]: active,
        })}>
        {name}
        {` `}
        {rate}
      </div>
    </div>
  );
};

export default SmallBankButton;
