import { gql } from '@apollo/client';

const balanceSummaryForTangible = (selectedVkns = [], selectedBankAccountGroups = [], accountType = 0) => {
  return {
    query: gql`
      query($selectedVkns: [String], $selectedBankAccountGroups: [Guid], $accountType: Int) {
        bankAccountQuery {
          balanceSummary(
            selectedVkns: $selectedVkns
            selectedBankAccountGroups: $selectedBankAccountGroups
            accountType: $accountType
            selectedBanks: $selectedBanks
            targetCurrency: $targetCurrency
          ) {
            balanceSummaries {
              accountCount
              bankCount
              currency
              currencySymbol
              notViewedAccountCount
              totalBalance
              accountCountHasData
              totalBalanceForDepositAccounts
              blockedAmount
              banks {
                bankCode
                bankName
                iconLogo
                logo
                summary
                totalBalance
              }
            }
            lastBankTransactionDate
            reportMessage
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccountGroups,
      accountType,
    },
  };
};

export default balanceSummaryForTangible;

/*
const response = {
  "data": {
    "bankAccountQuery": {
      "balanceSummary": [
        {
          "accountCount": 3,
          "bankCount": 3,
          "currency": "TRY",
          "currencySymbol": "₺",
          "notViewedAccountCount": 0,
          "totalBalance": 97200.3
        },
        {
          "accountCount": 3,
          "bankCount": 3,
          "currency": "USD",
          "currencySymbol": "$",
          "notViewedAccountCount": 0,
          "totalBalance": 2980
        },
        {
          "accountCount": 3,
          "bankCount": 3,
          "currency": "EUR",
          "currencySymbol": "€",
          "notViewedAccountCount": 0,
          "totalBalance": 1250.28
        }
      ]
    }
  }
}
*/
