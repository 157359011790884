import React from 'react';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';
import { FormattedMessage } from 'react-intl';

import { Widget, Card } from 'components';
import SelectCompanyDefault from 'containers/elements/@commons/default-select-company';
import cx from 'classnames';
import styles from './styles.scss';

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="defaultSelectedCompany.defaultCompanies" defaultMessage="Varsayılan Şirketler" />,
    path: '/defaultCompanySelect',
  },
];

const DefaultCompanySelect = () => {
  return (
    <Widget>
      <Breadcrumb
        title={<FormattedMessage id="defaultSelectedCompany.defaultCompanies" defaultMessage="Varsayılan Şirketler" />}
        breadcrumbs={breadcrumbs}
      />
      <Card style={{ marginTop: `15px` }} className={cx(styles['card'])}>
        <SelectCompanyDefault />
      </Card>
    </Widget>
  );
};

export default DefaultCompanySelect;
