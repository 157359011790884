import React, { useEffect } from 'react';
import cx from 'classnames';

import Icon from 'components/icon';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const SortItem = ({ sortType, item }) => {
  if (sortType.key === item.key && sortType.type === 'inc') {
    return <Icon name="icon-navigation-expand-less-24-px" />;
  }

  return <Icon name="icon-navigation-expand-more-24-px" />;
};

const TableHeaderItem = ({ theme, item, sortType, style, sort }) => {
  return (
    <div
      className={cx(styles['table-header-item'], styles[`table-header-item-${theme}`])}
      style={style}
      onClick={sort.bind(this, item.key)}>
      <span>{item.name}</span>
      <SortItem sortType={sortType} item={item} />
    </div>
  );
};

const Table = ({ columns, data, className, onClick }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [list, setList] = React.useState(data);

  const [sortType, setSortType] = React.useState({
    type: 'inc',
    key: '',
  });

  const sort = (key) => {
    const sortedList = data.slice().sort((a, b) => {
      if (sortType.type === 'desc') {
        return a[key] > b[key] ? -1 : 1;
      }
      return a[key] > b[key] ? 1 : -1;
    });

    setSortType({
      type: sortType.type === 'inc' ? 'desc' : 'inc',
      key,
    });

    setList(sortedList);
  };

  useEffect(() => {
    setList(data);
  }, [data]);

  return (
    <div>
      <div className={cx(styles.table, className)}>
        <div className={cx(styles['table-wrapper'])}>
          <div className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
            {columns?.length > 0 &&
              columns.map((column, index) => {
                return (
                  <React.Fragment key={index.toString()}>
                    {column.render ? (
                      React.Children.map(column.render(column.name), (child) => {
                        return (
                          <>
                            {React.cloneElement(
                              <div style={child.props.style}>
                                {child.props.children}
                                {column.isSort && <SortItem sortType={sortType} item={column} />}
                              </div>,
                              {
                                className: cx(
                                  child.props.className,
                                  styles['table-header-single-item'],
                                  styles[`table-header-single-item-${column.key}`],
                                  styles[`table-header-single-item-${theme}`],
                                ),
                                onClick: sort.bind(this, column.key),
                              },
                            )}
                          </>
                        );
                      })
                    ) : (
                      <TableHeaderItem
                        key={index.toString()}
                        item={column}
                        sort={sort}
                        sortType={sortType}
                        theme={theme}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </div>
          <div className={cx(styles['table-content'])}>
            {list.map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  className={cx(
                    styles['table-content-row'],
                    styles[`table-content-row-${theme}`],
                    styles[`table-content-row-${item.hasNoData ? 'noData' : 'haveData'}`],
                  )}>
                  {columns.map((column, i) => {
                    const value = item[column?.key] ? item[column?.key] : item[column?.key] === 0 ? '0' : null;
                    const renderedValue = column && column.valueRender ? column.valueRender(value) : value;
                    return (
                      <React.Fragment key={`list-item-row-${i.toString()}`}>
                        {column.render ? (
                          React.Children.map(column.render(renderedValue), (child) => {
                            return React.cloneElement(child, {
                              className: cx(
                                child.props.className,
                                styles['table-content-row-item'],
                                styles[`table-content-row-item-${theme}`],
                              ),
                              style: child.props.style,
                              onClick:
                                column?.key === 'action' || column?.key === 'maturityEndDate'
                                  ? onClick.bind(this, item)
                                  : undefined,
                              data: { item },
                            });
                          })
                        ) : (
                          <div
                            className={cx(styles['table-content-row-item'], styles[`table-content-row-item-${theme}`])}>
                            {value}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
