import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tippy';
import cx from 'classnames';

import Card from 'components/card';
import Icon from 'components/icon';
import Loader from 'components/loader';
import BankSymbol from 'components/bank-symbol';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';
import moment from 'moment-timezone';

const BankSummary = ({ loading }) => {
  const {
    selectBank: { selectedBank },
    theme: { theme },
  } = useAppSelector((state) => state);

  if (!selectedBank) {
    return null;
  }

  const renderFormattedTime = ({ lastBankTransactionDate, reportMessage }) => {
    if (lastBankTransactionDate === null || lastBankTransactionDate === '') {
      return (
        <div className={cx(styles['bank-name'], styles[`bank-name-${theme}`])}>
          <FormattedMessage id="general.lastTransactionDate" defaultMessage="Son İşlem Tarihi" />
          :&nbsp; -
        </div>
      );
    }

    if (lastBankTransactionDate.length > 16) {
      return (
        <div className={cx(styles['bank-name'], styles[`bank-name-${theme}`])}>
          <span className={cx(styles['lastDate-error'], styles[`lastDate-error-${theme}`])}>
            <FormattedMessage id="general.lastTransactionDate" defaultMessage="Son İşlem Tarihi" />:
          </span>
          <span>&nbsp;{lastBankTransactionDate}&nbsp;</span>
          {reportMessage && (
            <Tooltip title={reportMessage} position="top" trigger="mouseenter">
              <span className={cx(styles['tooltip'], styles[`tooltip-${theme}`])}>?</span>
            </Tooltip>
          )}
        </div>
      );
    }

    const langFromStorage = localStorage.getItem('@ep-language');
    if (langFromStorage === 'en') {
      lastBankTransactionDate = moment(lastBankTransactionDate, 'DD-MM-YYYY H:mm').format('MM-DD-YYYY h:mm A');
    } else {
      lastBankTransactionDate = moment(lastBankTransactionDate, 'DD-MM-YYYY H:mm').format('DD.MM.YYYY H:mm');
    }

    return (
      <div className={cx(styles['bank-name'], styles[`bank-name-${theme}`])}>
        <span className={cx(styles['lastDate-success'], styles[`lastDate-success-${theme}`])}>
          <FormattedMessage id="general.lastTransactionDate" defaultMessage="Son İşlem Tarihi" />:
        </span>
        <span>&nbsp;{lastBankTransactionDate}&nbsp;</span>
        {reportMessage && (
          <Tooltip title={reportMessage} position="top" trigger="mouseenter">
            <span className={cx(styles['tooltip'], styles[`tooltip-${theme}`])}>?</span>
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <Card className={styles.card} loading={loading} loader={<Loader />}>
      <div className={styles.wrapper}>
        <div className={cx(styles['bank-name'], styles[`bank-name-${theme}`])}>{selectedBank.bankName}</div>
        {renderFormattedTime(selectedBank)}
        <div className={styles['bank-detail']}>
          <div className={styles['currency-row']}>
            {selectedBank.currencies.map((currency, index) => {
              return (
                <BankSymbol key={index.toString()} currency={currency.currency} symbol={currency.currencySymbol} />
              );
            })}
          </div>
          <div className={cx(styles['account-row'], styles[`account-row-${theme}`])}>
            <Icon className="icon-icon-places-account-balance-wallet-24-px" />
            <p>
              {selectedBank.totalAccount}
              <FormattedMessage id="general.account" defaultMessage="Hesap" />
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BankSummary;
