import React from 'react';
import cx from 'classnames';

import { FormattedMessage, injectIntl } from 'react-intl';
import request from 'library/@e5p-request';

import Authentication from 'utils/authentication';

import { useAppSelector } from 'src/redux/store';

import { Input, Select, SelectItem } from 'components';

import styles from './styles.scss';

const { getBanksOptions } = request;

const SelectBanks = ({ intl, selectedBanks = [], onChange }) => {
  const {
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [banks, setBanks] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [loading, setLoading] = React.useState(true);

  const [selectedBankItems, setSelectedBankItems] = React.useState([]);

  React.useEffect(() => {
    if (selectedBanks.length === 0) {
      setSelectedBankItems([]);
    }
  }, [selectedBanks]);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    getBanksOptions(selectedVkn)
      .then((res) => {
        setBanks(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [selectedVkn]);

  React.useEffect(() => {
    if (banks.length > 0) {
      const selectedItems = selectedBanks.reduce((acc, cur) => {
        const arr = [...acc];
        const bank = banks.find((b) => b.value === cur);

        if (bank) {
          arr.push(bank);
        }

        return arr;
      }, []);

      setSelectedBankItems(selectedItems);
    }
  }, [banks]);

  const onChangeOption = (bankItem) => {
    const isBankSelected = selectedBankItems.find((b) => b.value === bankItem.value);

    if (isBankSelected) {
      const selectedBankItemsList = selectedBankItems.filter((b) => b.value !== bankItem.value);

      setSelectedBankItems(selectedBankItemsList);
      onChange(selectedBankItemsList.map((selectedBakItem) => selectedBakItem.value));

      return;
    }

    const selectedBankItemsList = [...selectedBankItems, bankItem];

    setSelectedBankItems(selectedBankItemsList);
    onChange(selectedBankItemsList.map((selectedBakItem) => selectedBakItem.value));
  };

  const clearSelectedItems = () => {
    setSelectedBankItems([]);
    onChange([]);
  };

  const renderPlaceholder = () => {
    if (selectedBankItems.length > 1) {
      return (
        <>
          {selectedBankItems.length}{' '}
          <FormattedMessage id="organisms.activities.filter.currencySelectionLabel.selected" defaultMessage="seçim" />
        </>
      );
    }

    if (selectedBankItems.length === 1) {
      return (
        <div className={cx(styles['select-item-selected'], styles[`select-item-selected-${theme}`])}>
          {selectedBankItems[0].label}
        </div>
      );
    }

    return <FormattedMessage id="organisms.activities.filter.bank.select.languageId" defaultMessage="Banka Seçiniz" />;
  };

  const session = Authentication.hasSession();

  if (loading & !session) {
    return <div />;
  }

  return (
    <Select
      multiple
      placeholder={renderPlaceholder()}
      selected={selectedBankItems}
      className={cx(styles['filters-select-bank'], styles[`filters-select-bank-${theme}`])}
      selectClassName={styles['filters-select-bank-container']}
      clearSelectedItems={clearSelectedItems}>
      <Input
        onClick={(e) => {
          e.stopPropagation();
        }}
        value={searchText}
        placeholder={intl.formatMessage({
          id: 'molecule.dropdown.selectBank.searchInputPlaceholder',
          defaultMessage: 'Banka Adı',
        })}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {banks
        .filter((bank) => bank?.label?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()))
        .map((bank, index) => (
          <SelectItem
            key={`filters-bank-item-${index}`}
            className={
              selectedBankItems.find((b) => b === bank.value)
                ? cx(styles['filters-select-item'], styles['filters-select-item-active'])
                : styles['filters-select-item']
            }
            value={bank.value}
            isActive={selectedBankItems.find((b) => b.value === bank.value)}
            onClick={() => {
              onChangeOption(bank);
            }}>
            {bank.label}
          </SelectItem>
        ))}
    </Select>
  );
};

export default injectIntl(SelectBanks);
