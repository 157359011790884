import { gql } from '@apollo/client';

const setPlaidCompanyCode = ({ bankCode = 0, vkn = '', companyCode = '', uid = '' }) => {
  return {
    mutation: gql`
      mutation($bankCode: Int, $vkn: String, $companyCode: String, $uid: String) {
        companyAccountMutation {
          setCompanyCode(bankCode: $bankCode, vkn: $vkn, companyCode: $companyCode, uid: $uid) {
            setCompanyCode
          }
        }
      }
    `,
    variables: {
      bankCode,
      vkn,
      companyCode,
      uid,
    },
  };
};

export default setPlaidCompanyCode;
