import React from 'react';

import cx from 'classnames';

import styles from './styles.scss';

const LanguageButton = ({ flag, onClick }) => {
  return (
    <div className={cx(styles['language-button'])} onClick={onClick}>
      <img src={flag} alt="flag" />
    </div>
  );
};

export default LanguageButton;
