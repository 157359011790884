import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { Widget, Card, Button, Loader } from 'components';
import SelectCurrenciesMulti from 'containers/elements/company-accounts/select-currencies-multi';
import SelectCurrenciesSingle from 'containers/elements/company-accounts/select-currencies-single';

import cx from 'classnames';
import styles from './styles.scss';

const Filters = ({ banks, filterParams, setFilterParams, onClear, onApplyFilter }) => {
  const onChangeSelectedBank = (banks) => {
    setFilterParams((prevState) => ({ ...prevState, banks, selectedBanks: banks.map(({ bankCode }) => bankCode) }));
  };

  const onChangeStatus = (status) => {
    setFilterParams((prevState) => ({ ...prevState, status }));
  };

  return (
    <Widget>
      <Card loader={<Loader />} className={cx(styles['card'])} row>
        <div className={cx(styles['card'])}>
          <div className={cx(styles['filter-input'])}>
            <div className={cx(styles['second-group'])}>
              <SelectCurrenciesMulti
                title={'bank'}
                selectedCurrencies={filterParams.banks}
                onChange={(params) => onChangeSelectedBank(params)}
                banks={banks}
              />
            </div>
            <div className={cx(styles['second-group'])}>
              <SelectCurrenciesSingle
                title={'errors'}
                selectedCurrencies={filterParams.status}
                onChange={(params) => onChangeStatus(params)}
              />
            </div>
            <div className={cx(styles['second-group'], styles['basis-group-banks'])}>
              <Button className={cx(styles['button-group'])} onClick={() => onClear()}>
                <FormattedMessage id="molecule.accountActivities.clearButton" defaultMessage="Temizle" />
              </Button>
            </div>
            <div className={cx(styles['second-group'], styles['basis-group-banks'])}>
              <Button className={cx(styles['button-group'])} onClick={() => onApplyFilter()}>
                <FormattedMessage id="molecule.accountActivities.applyFilters" defaultMessage="Filtrele" />
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </Widget>
  );
};

export default injectIntl(Filters);
