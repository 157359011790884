import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useModal } from 'src/hooks/useModal';
import { useAppSelector } from 'src/redux/store';
import Modal from 'src/components/modal/modal';
import { Button, Input, Text } from 'src/components/index';
import toast from 'react-hot-toast';
import mutations from 'library/mutation';

import { useHistory } from 'react-router-dom';
const { savePlaidAccountUid } = mutations;

const PlaidModal = () => {
  const intl = useIntl();
  const {
    theme: { theme },
  } = useAppSelector((state) => state);
  const router = useHistory();

  const [loading, setLoading] = useState(false);
  const [plaidUid, setPlaidUid] = useState('');
  const { isShowing, toggle } = useModal();

  const oauth = new URLSearchParams(location.search).get('oauth');
  const vkn = new URLSearchParams(location.search).get('vkn');
  const bankakodu = new URLSearchParams(location.search).get('bankakodu');
  const uid = new URLSearchParams(location.search).get('uid');

  useEffect(() => {
    if (oauth && oauth === 'ok' && vkn && bankakodu && uid) {
      toggle();
      return;
    }

    if (oauth && oauth === 'error') {
      toast.error(
        intl.formatMessage({
          id: 'dashboard.error.message.oauth',
          defaultMessage: 'Bağlantı sisteme eklenemedi.',
        }),
        {
          duration: 4000,
          style: {
            fontFamily: '"ProximaNova-Medium", sans-serif',
          },
        },
      );
    }
  }, []);

  const postPlaidUid = async () => {
    setLoading(true);

    try {
      const result = await savePlaidAccountUid({ bankCode: bankakodu, vkn, companyCode: plaidUid, uid: uid });
      if (result?.companyAccountMutation?.setCompanyCode) {
        toast.success(
          intl.formatMessage({
            id: 'dashboard.success.message.oauth',
            defaultMessage: 'Bağlantı başarıyla eklendi.',
          }),
          {
            duration: 4000,
            style: {
              fontFamily: '"ProximaNova-Medium", sans-serif',
            },
          },
        );
        toggle();
        router.push('/');
      } else {
        toast.error(
          intl.formatMessage({
            id: 'dashboard.error.message.oauth',
            defaultMessage: 'Bağlantı sisteme eklenemedi.',
          }),
          {
            duration: 4000,
            style: {
              fontFamily: '"ProximaNova-Medium", sans-serif',
            },
          },
        );
      }
    } catch (error) {
      console.log('error', error);
      toast.error(
        intl.formatMessage({
          id: 'dashboard.error.message.oauth',
          defaultMessage: 'Bağlantı sisteme eklenemedi.',
        }),
        {
          duration: 4000,
          style: {
            fontFamily: '"ProximaNova-Medium", sans-serif',
          },
        },
      );
    } finally {
      setLoading(false);
    }
  };

  const renderModalContent = () => {
    return (
      <>
        <Text>
          <FormattedMessage
            id="dashboard.plaidModal.uid.description"
            defaultMessage="Banka şifrenizi talep etmiyoruz, UID ile banka hesap kullanıcınızı ilişkilendirip size göstermek adına yalnızca
                    banka kullanıcı hesabına ihtiyacımız var."
          />
        </Text>
        <Input
          onChange={(e) => setPlaidUid(e.target.value)}
          value={plaidUid}
          name="plaidUid"
          placeholder={intl.formatMessage({
            id: 'dashboard.plaidModal.uid.input.placeholder',
            defaultMessage: 'Banka hesap kullanıcı bilgisi',
          })}
          disabled={loading}
        />
        <Button
          style={{ backgroundColor: '#0f62fe', color: '#fff' }}
          disabled={loading || !plaidUid}
          onClick={() => {
            postPlaidUid();
          }}>
          <FormattedMessage id="defaultSelectedCompany.save" defaultMessage="Kaydet" />
        </Button>
      </>
    );
  };
  return (
    <>
      {isShowing && (
        <Modal
          theme={theme}
          hide={toggle}
          title={intl.formatMessage({
            id: 'dashboard.plaidModal.uid.header',
            defaultMessage: 'Banka Kullanıcı Bilgisi Girişi',
          })}
          closeOnClickOutside={false}
          showCloseIcon={false}>
          {renderModalContent()}
        </Modal>
      )}
    </>
  );
};

export default injectIntl(PlaidModal);
