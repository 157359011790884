import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import moment from 'moment';
import 'moment/locale/tr';
import Button from '../button';

import { DateRangePicker, SingleDatePicker as DatePickerSingle } from 'react-dates';
import { FormattedMessage, useIntl } from 'react-intl';
import 'react-dates/initialize';

import 'react-dates/lib/css/_datepicker.css';

import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const fastDates = [
  {
    count: 0,
    text: <FormattedMessage id="atoms.datePicker.today" defaultMessage="Bugün" />,
  },
  {
    count: 1,
    text: <FormattedMessage id="atoms.datePicker.todayAndYesterday" defaultMessage="Bugün ve Dün" />,
  },
  {
    count: 6,
    text: <FormattedMessage id="atoms.datePicker.lastWeek" defaultMessage="Son 1 Hafta" />,
  },
  {
    count: 30,
    text: <FormattedMessage id="atoms.datePicker.lastMonth" defaultMessage="Son 1 Ay" />,
  },
  {
    count: 92,
    text: <FormattedMessage id="atoms.datePicker.lastThreeMonths" defaultMessage="Son 3 Ay" />,
  },
  {
    count: 185,
    text: <FormattedMessage id="atoms.datePicker.lastSixMonths" defaultMessage="Son 6 Ay" />,
  },
  {
    count: 364,
    text: <FormattedMessage id="atoms.datePicker.lastYear" defaultMessage="Son 1 Yıl" />,
  },
];

const DatePicker = ({
  onChange = () => {},
  className,
  reset = false,
  dateFormat = 'DD.MM.YYYY',
  icon,

  defaultDate = {
    startDate: null,
    endDate: null,
  },
  ...props
}) => {
  const intl = useIntl();
  const { theme } = useAppSelector((state) => state.theme);

  const [date, setDate] = useState({
    startDate: defaultDate.startDate ? moment(defaultDate.startDate) : null,
    endDate: defaultDate.endDate ? moment(defaultDate.endDate) : null,
  });

  const [focusedInput, setFocusedInput] = useState(false);

  const [selectedFastDate, setSelectedFastDate] = React.useState(1);

  const fastDateSelect = (count) => {
    setDate({
      startDate: moment(Date.now()).subtract(count, 'days'),
      endDate: moment(),
    });
    setSelectedFastDate(count);
  };

  useEffect(() => {
    moment.locale(intl.locale);
  }, [intl.locale]);

  useEffect(() => {
    if (date && date.startDate && date.endDate) {
      onChange({
        startDate: date.startDate,
        endDate: date.endDate,
      });
    }
  }, [date]);

  useEffect(() => {
    if (!defaultDate.startDate && !defaultDate.endDate) {
      setDate({
        startDate: null,
        endDate: null,
      });

      setSelectedFastDate(null);
    }
  }, [defaultDate.startDate, defaultDate.endDate]);

  useEffect(() => {
    if (reset) {
      setDate({ startDate: null, endDate: null });
    }
  }, [reset]);

  const showLabel = props.label && (!date.startDate || !date.endDate);

  return (
    <div data-theme={theme} className={cx(styles[`datepicker-${theme}`], className)} {...props}>
      <div
        className={styles.label}
        onClick={() => {
          setFocusedInput('startDate');
        }}>
        {icon}
        {showLabel ? (
          <span>{props.label}</span>
        ) : (
          <>
            <div className={styles.dates}>
              <span className={styles['start-date']}>
                {date.startDate && moment(date.startDate).format(dateFormat)}
              </span>
              {date.endDate && date.endDate !== date.startDate && (
                <>
                  <span className={styles['separator-date']}>{` - `}</span>
                  <span className={styles['end-date']}>{moment(date.endDate).format(dateFormat)}</span>
                </>
              )}
            </div>
            <div
              className={styles['remove-dates']}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFastDate(null);
                setDate({
                  endDate: null,
                  startDate: null,
                });
                onChange({
                  endDate: null,
                  startDate: null,
                });
              }}>
              <i className="icon-icon-navigation-close-24-px-1" />
            </div>
          </>
        )}
      </div>

      <DateRangePicker
        isOutsideRange={(day) => day.isAfter(moment().endOf('day'))}
        focusedInput={focusedInput}
        onFocusChange={(isFocus) => {
          setFocusedInput(isFocus);
        }}
        displayFormat={() => 'DD/MM/YYYY'}
        block
        mont
        small
        minimumNights={0}
        orientation={window.innerWidth <= 960 ? 'vertical' : 'horizontal'}
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={''}
        verticalBorderSpacing={2}
        endDatePlaceholderText={''}
        renderCalendarInfo={() => (
          <div className={styles['calendarInfo']}>
            {fastDates.map((dates) => (
              <div key={dates.count} className={styles['button-style']}>
                <Button
                  className={cx(selectedFastDate !== dates.count ? '' : styles['button-active'], styles['fast-button'])}
                  onClick={() => {
                    fastDateSelect(dates.count);
                  }}
                  color="primary">
                  {dates.text}
                </Button>
              </div>
            ))}
          </div>
        )}
        daySize={40}
        startDate={date.startDate}
        startDateId="your_unique_start_date_id"
        endDate={date.endDate}
        endDateId="your_unique_end_date_id"
        onDatesChange={({ startDate, endDate }) => {
          setDate({ startDate, endDate });
          setSelectedFastDate(null);
        }}
        {...props}
      />
    </div>
  );
};

export default DatePicker;

export const SingleDatePicker = ({
  onChange = () => {},
  className,
  reset = false,
  dateFormat = 'DD.MM.YYYY',
  icon,
  dateProp,
  ...props
}) => {
  const intl = useIntl();
  const { theme } = useAppSelector((state) => state.theme);

  const [date, setDate] = useState(dateProp);

  const [focusedInput, setFocusedInput] = useState(false);

  useEffect(() => {
    moment.locale(intl.locale);
  }, [intl.locale]);

  useEffect(() => {
    if (date) {
      onChange(date);
    }
  }, [date]);

  useEffect(() => {
    if (reset) {
      setDate(null);
    }
  }, [reset]);

  const showLabel = props.label && !date;

  return (
    <div data-theme={theme} className={cx(styles[`datepicker-${theme}`], className)} {...props}>
      <div
        className={styles.label}
        onClick={() => {
          setFocusedInput('startDate');
        }}>
        {icon}
        {showLabel ? (
          <span>{props.label}</span>
        ) : (
          <>
            <div className={styles.dates}>
              <span className={styles['start-date']}>{date && moment(date).format(dateFormat)}</span>
            </div>
            <div
              className={styles['remove-dates']}
              onClick={(e) => {
                e.stopPropagation();
                setDate(null);
                onChange(null);
              }}>
              <i className="icon-icon-navigation-close-24-px-1" />
            </div>
          </>
        )}
      </div>
      <DatePickerSingle
        isOutsideRange={(day) => day.isAfter(moment().endOf('day'))}
        date={date}
        onDateChange={(date) => setDate(date)}
        focused={focusedInput}
        onFocusChange={({ focused }) => setFocusedInput(focused)}
        id={props.id}
        displayFormat={() => 'DD/MM/YYYY'}
        orientation={window.innerWidth <= 960 ? 'vertical' : 'horizontal'}
        hideKeyboardShortcutsPanel
        small
        block
        {...props}
      />
    </div>
  );
};
