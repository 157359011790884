import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'library/@e5p-request';
import { local } from 'library/@e5p-request/helpers/index';
import { deepFormat, getOptions, getFlattenedVkn } from './helpers';

const { getCompaniesByGroup } = request;

export const initialize = createAsyncThunk('initialize', async () => {
  let companies = null;
  let localCompanies = local.getItemIfNotExpired('getCompaniesByGroup');
  let localSelectedCompanies = local.getItemIfNotExpired('selectedCompanies');
  let localFormattedCompanies = local.getItemIfNotExpired('formattedCompanies');

  if (localCompanies) {
    companies = localCompanies.data;
  } else {
    companies = await getCompaniesByGroup();
    if (companies.length > 0) local.setItemWithExpire('getCompaniesByGroup', companies);
  }
  const formattedCompanies = localFormattedCompanies?.data || deepFormat(companies);
  const selectedCompanies = localSelectedCompanies?.data || getOptions(formattedCompanies);
  const vkn = selectedCompanies.map((selectedCompany) => {
    return selectedCompany.vkn;
  });

  return {
    loading: false,
    companies,
    selectedCompanies,
    formattedCompanies,
    error: null,
    selectedVkn: getFlattenedVkn(vkn),
  };
});

const selectCompaniesActions = {
  initialize,
};

export default selectCompaniesActions;
