import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import formattedCurrency from 'utils/formatted-currency';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const AccountListItem = ({ currencySymbol, currency, totalBalance, bankCount, accountCount, accountCountHasData }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={styles['accordion-header-row']}>
      <div
        className={cx(
          `${currency}-bg`,
          styles['accordion-header-row-item'],
          styles[`accordion-header-row-item-${theme}`],
          { [styles['accordion-header-row-currencySymbolText']]: currencySymbol?.length >= 2 },
        )}>
        {currencySymbol}
      </div>
      <div
        className={cx(
          `${currency}`,
          styles['accordion-header-row-item'],
          styles[`accordion-header-row-item-${theme}`],
        )}>
        {currency}
      </div>
      <div
        className={cx(
          styles['accordion-header-row-item'],
          styles[`accordion-header-row-item-${theme}`],
          styles['accordion-header-row-item-total'],
        )}>
        {formattedCurrency(totalBalance)}
      </div>
      <div className={cx(styles['accordion-header-row-item'], styles[`accordion-header-row-item-${theme}`])}>
        {bankCount && (
          <>
            <i className={cx('icon-icon-places-account-balance-24-px', styles['accordion-header-row-icon'])} />
            {bankCount}{' '}
            <span>
              <FormattedMessage id="general.bank" defaultMessage="Banka" />
            </span>
          </>
        )}
      </div>

      <div className={cx(styles['accordion-header-row-item'], styles[`accordion-header-row-item-${theme}`])}>
        <i className={cx('icon-icon-places-account-balance-wallet-24-px', styles['accordion-header-row-icon'])} />

        <span>
          {accountCount} / {accountCountHasData} <FormattedMessage id="general.account" defaultMessage="Hesap" />
          {/*TODO :  accountCountHasData alanı yeşil gösterilcek */}
        </span>
      </div>
    </div>
  );
};

export default AccountListItem;
