import React from 'react';
import cx from 'classnames';

import request from 'library/@e5p-request';

import { FormattedMessage, useIntl } from 'react-intl';

import { Card, Loader, Select, SelectItem } from 'components';

import formattedCurrency from 'utils/formatted-currency';

const { getCurrencyRates } = request;

import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const BalanceConvertCard = () => {
  const intl = useIntl();
  const lang = intl.locale;

  const {
    selectBank: { selectedBank, selectedBankLoading },
    selectCurrency: { defaultCurrencyItem, currencyList, currencyListLoading },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [currencyRatesloading, setCurrencyRatesLoading] = React.useState(true);

  const [selectedCurrency, setSelectedCurrency] = React.useState(null);
  const [requestTimeoutList, setRequestTimeOutList] = React.useState([]);

  const [rate, setRate] = React.useState(null);

  React.useEffect(() => {
    setSelectedCurrency(currencyList[0]);
  }, [currencyList]);

  React.useEffect(() => {
    if (requestTimeoutList.length > 1) {
      for (let i = 0; i < requestTimeoutList.length - 1; i++) {
        const timeOut = requestTimeoutList[i];

        clearTimeout(timeOut);
      }
    }
  }, [requestTimeoutList]);

  React.useEffect(() => {
    if (defaultCurrencyItem?.summary && selectedCurrency?.summary) {
      setCurrencyRatesLoading(true);

      const timeOut = setTimeout(() => {
        getCurrencyRates(defaultCurrencyItem.summary, [selectedCurrency.summary])
          .then((res) => {
            setRate(res.rates[0].rate?.toFixed(2));
            setCurrencyRatesLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setCurrencyRatesLoading(false);
          });
      }, 1000);

      /**
       * sitenin tamamını etkileyen currencyList değiştiğinde bu bölümde selectedCurrency, globalde ise defaultCurrencyItem değişiyor
       * bu nedenle useEffect 2 kez çalışıp 2 kere istek atıyor, bu da response süresine bağlı olarak bazen yanlış sonuçları ekrana getirebiliyor
       * bu nedenle requestleri handle etmek için setTimeout kullanıldı buraya gelen requestler bir array içine atıldı
       * ardından requestTimeoutList'i dinleyen useEffect içinde sonuncu request calısacak şekilde handle edildi
       */
      setRequestTimeOutList([...requestTimeoutList, timeOut]);
    }
  }, [defaultCurrencyItem?.summary, selectedCurrency?.summary]);

  const renderCurrencySelect = () => {
    return (
      <Select
        placeholder={selectedCurrency?.summary}
        className={cx(styles['card-select-currency'], styles[`card-select-currency-${theme}`])}>
        {currencyList.map((currency, index) => (
          <SelectItem
            key={`currency-item-${index}`}
            value={currency.summary}
            isActive={selectedCurrency?.summary === currency.summary}
            onClick={() => {
              onChange(currency);
            }}>
            {currency.summary}
          </SelectItem>
        ))}
      </Select>
    );
  };

  const onChange = (value) => {
    setSelectedCurrency(value);
  };

  const loading = selectedCurrency?.summary
    ? selectedBankLoading || currencyListLoading || currencyRatesloading
    : selectedBankLoading || currencyListLoading;

  return (
    <>
      <Card
        loading={loading || selectedBankLoading}
        loader={<Loader />}
        dataTag="balance-convert-card"
        className={styles.card}
        contentClassNames={styles['card-content']}
        leftItem={selectedBank?.totalBalance && renderCurrencySelect()}>
        {(selectedBank?.totalBalance >= 0 || selectedBank?.totalBalance <= 0) &&
        defaultCurrencyItem?.summary &&
        rate ? (
          <>
            <div className={cx(styles['bank-name'], styles[`bank-name-${theme}`])}>{selectedBank?.bankName}</div>

            <div className={cx(styles['card-balance'], styles[`card-balance-${theme}`])}>
              {lang === 'tr' ? (
                <>
                  {formattedCurrency(selectedBank?.totalBalance / rate)} <span>{selectedCurrency?.symbol}</span>
                </>
              ) : (
                <>
                  <span>{selectedCurrency?.symbol}</span> {formattedCurrency(selectedBank?.totalBalance / rate)}
                </>
              )}
            </div>

            <div className={cx(styles['card-balance-exchange'], styles[`card-balance-exchange-${theme}`])}>
              {selectedCurrency?.summary && defaultCurrencyItem?.summary && (
                <span>
                  {`1 ${selectedCurrency.summary} `} = {` ${formattedCurrency(rate)} ${defaultCurrencyItem.summary}`}
                </span>
              )}
            </div>
          </>
        ) : (
          <div className={cx(styles['card-balance-exchange'], styles[`card-balance-exchange-${theme}`])}>
            <FormattedMessage
              id="dashboard.convert.card.info"
              defaultMessage="Bankanın güncel bakiye bilgisi alınamadı."
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default React.memo(BalanceConvertCard);
