import React, { useEffect } from 'react';

import toast, { Toaster } from 'react-hot-toast';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';

/* import BalanceChart from 'containers/elements/dashboard/balance-chart'; */
import AccountListByCurrency from 'containers/elements/dashboard/account-list-by-currency';
import BankAccountsRatio from 'containers/elements/dashboard/bank-accounts-ratio';
import DailyActivitiesTable from 'containers/elements/dashboard/daily-activities-table';

import BalanceConvertCard from 'containers/elements/dashboard/balance-convert-card';
import Widget from 'components/widget';
import PlaidModal from 'containers/elements/dashboard/plaidModal/plaidModal';

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="sidebar.dashboard" defaultMessage="Genel Bakış" />,
    path: '/dashboard',
  },
];

const Dashboard = (props) => {
  const intl = useIntl();

  useEffect(() => {
    const lang = localStorage.getItem('@ep-language');

    if (lang === intl.locale) {
      const isConnectToPlaid = localStorage.getItem('isConnectToPlaid');
      const oauth = new URLSearchParams(props.location.search).get('oauth');

      if (oauth && oauth === 'ok' && isConnectToPlaid === 'true') {
        toast.success(
          intl.formatMessage({
            id: 'dashboard.success.message.oauth',
            defaultMessage: 'Bağlantı başarıyla eklendi.',
          }),
          {
            duration: 4000,
            style: {
              fontFamily: '"ProximaNova-Medium", sans-serif',
            },
          },
        );

        localStorage.removeItem('isConnectToPlaid');

        return;
      }

      if (oauth && oauth === 'error' && isConnectToPlaid === 'true') {
        toast.error(
          intl.formatMessage({
            id: 'dashboard.error.message.oauth',
            defaultMessage: 'Bağlantı sisteme eklenemedi.',
          }),
          {
            duration: 4000,
            style: {
              fontFamily: '"ProximaNova-Medium", sans-serif',
            },
          },
        );

        localStorage.removeItem('isConnectToPlaid');
      }
    }
  }, [intl.locale]);

  return (
    <>
      <PlaidModal />
      <Widget>
        <Breadcrumb
          title={<FormattedMessage id="sidebar.dashboard" defaultMessage="Genel Bakış" />}
          breadcrumbs={breadcrumbs}
        />
      </Widget>
      <Widget row={window.screen.width === 768 ? 1 : 2} style={{ flexDirection: 'row !important' }}>
        <BalanceConvertCard />
        <BankAccountsRatio />
      </Widget>

      <Widget>
        <DailyActivitiesTable />
      </Widget>

      <AccountListByCurrency history={props.history} />
      <Widget row={window.screen.width === 768 ? 1 : 2} style={{ flexDirection: 'row !important' }}>
        {/*        <BalanceChart /> */}
        {/*         <BankAccountsRatio /> */}
      </Widget>

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default injectIntl(Dashboard);
