import React from 'react';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Page = ({ children }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div id={styles.page} className={styles[`theme-${theme}`]}>
      <div className={styles[`theme-${theme}`]}>{children}</div>
    </div>
  );
};

export default Page;
