import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import React from 'react';

import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { LanguageProvider } from 'library/@e5p-languages';
import ErrorBoundary from 'pages/error-boundary';

import Version from 'providers/versions';

import translations from 'languages';

import Routes from 'routes';

import reportWebVitals from 'utils/reportWebVitals';

import { Provider } from 'react-redux';
import { store } from 'src/redux/store';

import 'theme/styles/_index.css';
import 'theme/styles/app.scss';

const isProd = window.configEnv.APP_ENVIRONMENT !== 'DEVELOPMENT';

const t = localStorage.getItem('e5p-token');

if (isProd) {
  Sentry.init({
    dsn: 'https://407ca2590f2d410a87d6645b837e4ad1@o943592.ingest.sentry.io/5892498',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      // Modify the event here
      if (event.user) {
        // Don't send user's email address
        delete event.user.email;
      }
      if (t) {
        event.breadcrumbs[0].data.arguments[0].stack = `${t}-/-${event.breadcrumbs[0].data.arguments[0].stack}`;
      }
      return event;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <LanguageProvider translations={translations} defaultLanguage="tr">
        <Provider store={store}>
          <Version>
            <Routes />
          </Version>
        </Provider>
      </LanguageProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
