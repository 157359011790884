function fileDownloader(file, name, title) {
  const url = `${window.URL.createObjectURL(new Blob([file]))}`;
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  link.setAttribute('title', title);
  document.body.appendChild(link);
  link.click();
}

export default fileDownloader;
