import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Input = ({ type = 'text', box, error, icon, className, value, onChange, ...props }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [inputValue, setInputValue] = React.useState('');

  const onChangeInput = (e) => {
    onChange(e);
  };

  React.useEffect(() => {
    if (!value) {
      setInputValue('');
    }

    if (value) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div className={cx(styles[`wrapper`], className)}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <input
        type={type}
        className={cx(styles.input, styles[`input-${theme}`], {
          [styles['input-password']]: type === 'password',
          [styles['input-error']]: error,
          [styles['input-icon']]: icon,
          [styles['input-mini-box']]: box,
        })}
        value={inputValue}
        onChange={onChangeInput}
        {...props}
      />
    </div>
  );
};

export default Input;
