import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Checkbox = ({ checked, onClick, ...props }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={styles['wrapper']} onClick={onClick}>
      <input type="checkbox" checked={checked} className={styles.checkbox} onChange={() => {}} {...props} />
      <div
        className={cx(styles['checkbox'], styles[`checkbox-${theme}`], {
          [styles['checkbox-checked']]: checked,
          [styles[`checkbox-checked-${theme}`]]: checked,
        })}>
        <svg viewBox="0 0 24 24" className={cx(styles['checkbox-svg'])}>
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
    </div>
  );
};

export default Checkbox;
