import moment from 'moment';

import { jwtDecoder } from '../helpers';
class Token {
  getDecoded(token) {
    return jwtDecoder(token);
  }

  isExpired(token) {
    if (!token) return new Error('Token not found!');
    const { exp } = this.getDecoded(token);

    const expDate = moment(new Date(exp * 1000));
    const now = moment();

    if (now > expDate) {
      return true;
    }

    return false;
  }
}

export default new Token();
