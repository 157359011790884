import { useDispatch, useSelector } from 'react-redux';

import {
  selectCurrencyReducer,
  selectGroupsReducer,
  selectCompaniesReducer,
  selectBankReducer,
  themeReducer,
} from 'src/redux/reducers';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    selectCurrency: selectCurrencyReducer,
    selectGroups: selectGroupsReducer,
    selectCompanies: selectCompaniesReducer,
    selectBank: selectBankReducer,
    theme: themeReducer,
  },
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
