import { gql } from '@apollo/client';

const budgetDailyBalancesQuery = (selectedVkns = [], selectedBankAccountGroups) => {
  return {
    query: gql`
      query($selectedVkns: [String], $selectedBankAccountGroups: [Guid]) {
        budgetQuery {
          dailyBalances(selectedVkns: $selectedVkns, selectedBankAccountGroups: $selectedBankAccountGroups) {
            balances {
              currency
              lastBalance
              lastTransactionDate
            }
            bankCode
            bankName
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccountGroups,
    },
  };
};

export default budgetDailyBalancesQuery;
