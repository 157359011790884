import React from 'react';
import cx from 'classnames';
import styles from './styles.scss';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'src/redux/store';

const BankAccountSelectLabel = () => {
  // other hooks
  const intl = useIntl();

  // redux hooks
  const {
    selectGroups: { selectedBankAccountGroupList },
    theme: { theme },
  } = useAppSelector((state) => state);

  return (
    <>
      <div className={cx(styles['company-select-button'], styles[`company-select-button-${theme}`])}>
        <div className={cx(styles['company-select-button-item'])}>
          <p>
            {selectedBankAccountGroupList.length > 0
              ? selectedBankAccountGroupList.length === 1
                ? selectedBankAccountGroupList[0].name
                : `${selectedBankAccountGroupList.length} ${intl.formatMessage({
                    id: 'organism.selectBankAccGroupsSelected.label',
                    defaultMessage: 'Grup Seçildi',
                  })}`
              : intl.formatMessage({
                  id: 'organism.selectBankAccGroupsEmpty.label',
                  defaultMessage: 'Tüm Gruplar',
                })}
          </p>
        </div>
      </div>
    </>
  );
};

export default BankAccountSelectLabel;
