import React from 'react';
import { useAppSelector } from 'src/redux/store';

import { FormattedMessage } from 'react-intl';
import { Select, SelectItem } from 'components';

import cx from 'classnames';
import styles from './styles.scss';

const SelectCurrenciesSingle = ({ title, selectedCurrencies, onChange }) => {
  const {
    theme: { theme },
  } = useAppSelector((state) => state);

  const [defaultCurrencies, setDefaultCurrencies] = React.useState(null);

  React.useEffect(() => {
    if (selectedCurrencies === null) {
      setDefaultCurrencies(null);
    }
  }, [selectedCurrencies]);

  const onChangeOption = (currency) => {
    setDefaultCurrencies(currency);
    onChange(currency);
  };

  const clearSelectedItems = () => {
    setDefaultCurrencies(null);
    onChange(null);
  };

  const renderPlaceholder = () => {
    return (
      <>
        {defaultCurrencies === null && (
          <FormattedMessage id={`molecule.dropdown.${title}`} defaultMessage={`${title} Seçiniz`} />
        )}
        {defaultCurrencies === true && <FormattedMessage id="molecule.dropdown.success" defaultMessage="Başarılı" />}
        {defaultCurrencies === false && <FormattedMessage id="molecule.dropdown.failed" defaultMessage="Başarısız" />}
      </>
    );
  };

  return (
    <Select
      placeholder={renderPlaceholder()}
      selected={defaultCurrencies}
      className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}
      selectClassName={styles['filters-select-currency-container']}
      clearSelectedItems={clearSelectedItems}>
      <SelectItem
        className={
          defaultCurrencies === null
            ? cx(styles['filters-select-item'], styles['filters-select-item-active'])
            : styles['filters-select-item']
        }
        value={null}
        isActive={defaultCurrencies === null}
        onClick={() => onChangeOption(null)}>
        <FormattedMessage id="molecule.dropdown.all" defaultMessage="Hepsi" />
      </SelectItem>
      <SelectItem
        className={
          defaultCurrencies === true
            ? cx(styles['filters-select-item'], styles['filters-select-item-active'])
            : styles['filters-select-item']
        }
        value={true}
        isActive={defaultCurrencies === true}
        onClick={() => onChangeOption(true)}>
        <FormattedMessage id="molecule.dropdown.success" defaultMessage="Başarılı" />
      </SelectItem>
      <SelectItem
        className={
          defaultCurrencies === false
            ? cx(styles['filters-select-item'], styles['filters-select-item-active'])
            : styles['filters-select-item']
        }
        value={false}
        isActive={defaultCurrencies === false}
        onClick={() => onChangeOption(false)}>
        <FormattedMessage id="molecule.dropdown.failed" defaultMessage="Başarısız" />
      </SelectItem>
    </Select>
  );
};

export default SelectCurrenciesSingle;
