import React, { Fragment, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import cx from 'classnames';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';
import Filters from 'containers/elements/banks-activities/filters';
import ActivitiesTable from 'containers/elements/banks-activities/activities-table';

import Widget from 'components/widget';
import Card from 'components/card';
import Loader from 'components/loader';
import Icon from 'components/icon';
import Text from 'components/text';
import { Tooltip } from 'react-tippy';
import { useIntl } from 'react-intl';

import request from 'library/@e5p-request';

import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';
import { Switch } from 'components/index';
import ActivitiesDetailTable from 'containers/elements/banks-activities/activities-detail-table/activities-detail-table';
import { local } from 'library/@e5p-request/helpers/index';
import { useDebounce } from 'src/hooks/useDebounce';
import { ExcelIcon } from 'assets/icons/icons';

const {
  getExportHistory,
  getBankAccountsByFilter,
  getBankAccBalancesReport,
  getBankBalancesReport,
  getBankReceipts,
  getAccountInformations,
} = request;

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="sidebar.accountActivities" defaultMessage="Hesap Hareketleri" />,
    path: '/bank-activity',
  },
];

const activitiesTableColumns = [
  {
    name: <FormattedMessage id="general.receipt" defaultMessage="Dekont" />,
    key: 'receipt',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.branchCode" defaultMessage="Şube Kodu" />,
    key: 'branchCode',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.accountNumber" defaultMessage="Hesap Numarası" />,
    key: 'accountNo',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.operation" defaultMessage="İşlem" />,
    key: 'operation',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.operationDate" defaultMessage="İşlem Tarihi" />,
    key: 'operationDate',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.vkn" defaultMessage="Vkn" />,
    key: 'vkn',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.description" defaultMessage="Açıklama" />,
    key: 'description',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.balance" defaultMessage="Tutar" />,
    key: 'balance',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.netBalance" defaultMessage="Net Bakiye" />,
    key: 'totalBalance',
    isSort: true,
  },
];

const pageSizesArray = [20, 50, 100, 250, 500, 1000, 99999];

const BankActivity = ({ history }) => {
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const { location } = history;
  const intl = useIntl();

  const [bankAccountsListByFilter, setBankAccountsListByFilter] = useState([]);
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [transactionIds, setTransactionIds] = useState([]);
  const [totalBalance, setTotalBalance] = useState();
  const [totalCount, setTotalCount] = useState();
  const [filterParams, setFilterParams] = useState({
    selectedCompanies: selectedVkn,
    selectedBankAccountGroups: selectedBankAccountGroups,
    selectedBanks: [],
    selectedBankAccounts: [],
    selectedCurrencies: [],
    pageNumber: 1,
    onlyHasTransaction: false,
    useAbsoluteValues: false,
    incomeActivities: true,
    outgoingActivities: true,
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
    endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    pageSize: 20,
    description: '',
    accountTitle: '',
    minAmount: null,
    maxAmount: null,
    deneme: null,
  });
  const [loading, setloading] = useState(true);
  const [downloadLoading, setDownloadloading] = useState(false);
  const [onlyActivities, setOnlyActivities] = useState(false);
  const [onlyActivitiesBankAccounts, setOnlyActivitiesBankAccounts] = useState([]);
  const [accountInformations, setAccountInformations] = useState([]);
  const [bankAccountOptionsLoading, setBankAccountOptionsLoading] = useState(true);

  const [activeFilters, setActiveFilters] = useState(filterParams);
  const debouncedFilters = useDebounce(activeFilters, 750);

  useEffect(() => {
    if (selectedVkn === null) return;
    const savedFilters = local.getItem('savedFilters');

    if (savedFilters) {
      const parsedSavedFilters = null || JSON.parse(savedFilters);
      setFilterParams((prevState) => ({
        ...prevState,
        ...parsedSavedFilters,
      }));
      setSelectedReceipts([]);
      getFilteredBankAccountsForApply({
        ...filterParams,
        ...parsedSavedFilters,
      });
      local.removeItem('savedFilters');
      return;
    }

    if (location.state) {
      setFilterParams((prevState) => ({
        ...prevState,
        selectedBankAccounts: location.state.selectedBankAccounts,
        selectedCurrencies: location.state.selectedCurrencies,
        selectedBanks: location.state.selectedBanks,
        startDate:
          location.state.startDate || moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate: location.state.endDate || moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
      }));
      setSelectedReceipts([]);
      getFilteredBankAccountsForApply({
        ...filterParams,
        selectedBankAccounts: location.state.selectedBankAccounts,
        selectedCurrencies: location.state.selectedCurrencies,
        selectedBanks: location.state.selectedBanks,
        startDate:
          location.state.startDate || moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate: location.state.endDate || moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
      });
      return;
    }

    setloading(true);
    getBankAccountsByFilter({
      ...debouncedFilters,
      selectedCompanies: selectedVkn,
      startDate:
        !debouncedFilters.startDate || debouncedFilters.startDate === null
          ? moment().subtract(30, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0]
          : moment(debouncedFilters.startDate).format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
      endDate:
        !debouncedFilters.endDate || debouncedFilters.endDate === null
          ? moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0]
          : moment(debouncedFilters.endDate).format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
      selectedBankAccounts: location.state?.selectedBankAccounts
        ? location.state.selectedBankAccounts
        : debouncedFilters.selectedBankAccounts,
      selectedBanks: location.state?.selectedBanks ? location.state.selectedBanks : debouncedFilters.selectedBanks,
      selectedCurrencies: location.state?.selectedCurrencies
        ? location.state.selectedCurrencies
        : debouncedFilters.selectedCurrencies,
    })
      .then((res) => {
        const newData = res.result.map((item) => {
          return {
            ...item,
            branchAndAccountNo: item.branchCode + ' / ' + item.accountNo,
          };
        });
        setOnlyActivitiesBankAccounts(res.result.map((bankAccount) => bankAccount.accountNo));
        setBankAccountsListByFilter(newData);

        setTotalBalance(res.totalBalance);
        setTotalCount(res.totalCount);
        setloading(false);
      })
      .catch((err) => {
        console.log({ err });
        setBankAccountsListByFilter([]);
        setloading(false);
      });
  }, [debouncedFilters]);

  useLayoutEffect(() => {
    if (location.state) {
      const locationState = { ...location.state };
      const {
        selectedCurrencies = [],
        selectedBankAccounts = [],
        selectedBanks = [],
        startDate = moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate = moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
      } = locationState;

      resetLocationState().then(() => {
        setFilterParams((prevState) => ({
          ...prevState,
          selectedBankAccounts,
          selectedCurrencies,
          selectedBanks,
          startDate,
          endDate,
        }));
      });
    }
  }, [location.state]);

  useEffect(() => {
    const arraysHaveSameValues =
      selectedVkn.length === filterParams.selectedCompanies.length &&
      selectedVkn.every((value) => filterParams.selectedCompanies.includes(value));

    if (!arraysHaveSameValues) {
      getFilteredBankAccountsForApply({
        ...filterParams,
        pageNumber: 1,
      });
      setFilterParams((prevState) => ({
        ...prevState,
        pageNumber: 1,
      }));
    }
  }, [selectedVkn]);

  useEffect(() => {
    setFilterParams((prevState) => ({ ...prevState, selectedBankAccountGroups }));
    setActiveFilters((prevState) => ({ ...prevState, selectedBankAccountGroups }));
  }, [selectedBankAccountGroups]);

  const applyFilter = () => {
    if (location.state) {
      setFilterParams((prevState) => ({
        ...prevState,
        pageNumber: 1,
        selectedCompanies: selectedVkn,
        selectedBankAccounts: location.state.selectedBankAccounts,
        selectedCurrencies: location.state.selectedCurrencies,
        selectedBanks: location.state.selectedBanks,
        startDate:
          location.state.startDate || moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate: location.state.endDate || moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
      }));
    } else {
      setSelectedReceipts([]);
      getFilteredBankAccountsForApply(filterParams);
    }
  };

  useEffect(() => {
    if (selectedVkn === null) return;
    getBankAccounts();
  }, [selectedBankAccountGroups, selectedVkn?.length]);

  const getBankAccounts = () => {
    setBankAccountOptionsLoading(true);
    getAccountInformations({
      selectedVkns: selectedVkn,
      selectedBankAccountGroups,
      onlyHasTransaction: filterParams.onlyHasTransaction,
      startDate:
        filterParams.startDate || moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
      endDate: filterParams.endDate || moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    })
      .then((res) => {
        setAccountInformations(res);
        setBankAccountOptionsLoading(false);
      })
      .catch(() => {
        setBankAccountOptionsLoading(false);
      });
  };

  const resetLocationState = async () => {
    return new Promise((resolve) => {
      history.replace({ state: undefined });
      resolve(true);
    });
  };

  const getFilteredBankAccountsForApply = (filters) => {
    setActiveFilters((prevState) => ({
      ...prevState,
      ...filters,
    }));
  };

  const exportActivitiesByType = async (activityType) => {
    try {
      setDownloadloading(true);

      const isAll =
        (filterParams.incomeActivities && filterParams.outgoingActivities) ||
        (!filterParams.incomeActivities && !filterParams.outgoingActivities);
      const onlyIncome = filterParams.incomeActivities && !filterParams.outgoingActivities;
      const type = isAll ? 'All' : onlyIncome ? 'Incoming' : 'Outgoing';

      await getExportHistory({
        vknList: selectedVkn,
        bankCodes: filterParams.selectedBanks,
        bankAccountNos:
          selectedAccounts.length > 0
            ? selectedAccounts
            : filterParams.selectedBankAccounts.length > 0
            ? filterParams.selectedBankAccounts
            : accountInformations.map((acc) => acc.bankAccountNo),
        startDate:
          !filterParams.startDate || filterParams.startDate === null
            ? moment().subtract(1, 'months').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0]
            : moment(filterParams.startDate).format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate:
          !filterParams.endDate || filterParams.endDate === null
            ? moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0]
            : moment(filterParams.endDate).format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
        description: filterParams.description,
        minAmount: filterParams.minAmount ? Number(filterParams.minAmount) : null,
        maxAmount: filterParams.maxAmount ? Number(filterParams.maxAmount) : null,
        activityType: type,
        fileType: activityType,
        exportType: activityType === 'CSV' ? 0 : 1,
        currencies: filterParams.selectedCurrencies,
        accountName: filterParams.accountTitle,
      });

      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const exportActivitiesByTransactionIds = async (activityType) => {
    try {
      setDownloadloading(true);

      await getExportHistory({
        vknList: selectedVkn,
        bankCodes: filterParams.selectedBanks,
        bankAccountNos: filterParams.selectedBankAccounts,
        startDate:
          !filterParams.startDate || filterParams.startDate === null
            ? moment().subtract(1, 'months').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0]
            : moment(filterParams.startDate).format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate:
          !filterParams.endDate || filterParams.endDate === null
            ? moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0]
            : moment(filterParams.endDate).format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
        description: filterParams.description,
        minAmount: filterParams.minAmount ? Number(filterParams.minAmount) : null,
        maxAmount: filterParams.maxAmount ? Number(filterParams.maxAmount) : null,
        transactionIds,
        activityType: 'All',
        fileType: activityType,
        exportType: activityType === 'CSV' ? 0 : 1,
        currencies: filterParams.selectedCurrencies,
      });

      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const getBankAccountBalancesReportFunc = async () => {
    try {
      setDownloadloading(true);

      await getBankAccBalancesReport({
        vknList: selectedVkn,
        bankCodes: filterParams.selectedBanks,
        bankAccountNos:
          filterParams.selectedBankAccounts.length > 0
            ? filterParams.selectedBankAccounts
            : accountInformations.map((acc) => acc.bankAccountNo),
        startDate:
          !filterParams.startDate || filterParams.startDate === null
            ? moment().subtract(1, 'months').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0]
            : moment(filterParams.startDate).format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate:
          !filterParams.endDate || filterParams.endDate === null
            ? moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0]
            : moment(filterParams.endDate).format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
        currencies: filterParams.selectedCurrencies,
      });
      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const getBankBalancesReportFunc = async () => {
    try {
      setDownloadloading(true);

      await getBankBalancesReport({
        vknList: selectedVkn,
        bankCodes: filterParams.selectedBanks,
        bankAccountNos:
          filterParams.selectedBankAccounts.length > 0
            ? filterParams.selectedBankAccounts
            : accountInformations.map((acc) => acc.bankAccountNo),
        startDate:
          !filterParams.startDate || filterParams.startDate === null
            ? moment().subtract(1, 'months').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0]
            : moment(filterParams.startDate).format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
        endDate:
          !filterParams.endDate || filterParams.endDate === null
            ? moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0]
            : moment(filterParams.endDate).format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
        currencies: filterParams.selectedCurrencies,
      });
      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const getCollectiveReceipts = async () => {
    try {
      setDownloadloading(true);

      await getBankReceipts(selectedReceipts);
      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const handleSelectSingleReceipt = useCallback(
    (column) => {
      const { accountNo, bankCode, transactionId } = column;
      const selectedData = { accountNo, bankCode, transactionId };

      if (selectedReceipts.findIndex((e) => e.transactionId === selectedData.transactionId) === -1) {
        setSelectedReceipts([...selectedReceipts, selectedData]);
        setTransactionIds([...transactionIds, selectedData.transactionId]);
      } else {
        const removedArray = selectedReceipts.filter((e) => e.transactionId !== selectedData.transactionId);
        const removedTransactionIdsArray = transactionIds.filter((t) => t !== selectedData.transactionId);

        setSelectedReceipts(removedArray);
        setTransactionIds(removedTransactionIdsArray);
      }
    },
    [selectedReceipts],
  );

  const handleSelectMultiReceipts = useCallback(
    (selectedAccountActivities, allSelect) => {
      const newData = selectedAccountActivities.reduce(
        (total, item) => {
          if (selectedReceipts.findIndex((e) => e.transactionId === item.transactionId) === -1) {
            const { accountNo, bankCode, transactionId } = item;
            const selectedData = { accountNo, bankCode, transactionId };
            total.push(selectedData);
          } else {
            if (allSelect) {
              total = total.filter((e) => e.transactionId !== item.transactionId);
            }
          }
          return total;
        },
        [...selectedReceipts],
      );

      setSelectedReceipts([...newData]);
    },
    [selectedReceipts],
  );

  const handleSelectSingleAccount = useCallback(
    (column) => {
      const { accountNo } = column;
      if (selectedAccounts.findIndex((acc) => acc === accountNo) === -1) {
        setSelectedAccounts([...selectedAccounts, accountNo]);
      } else {
        const removedArray = selectedAccounts.filter((acc) => acc !== accountNo);
        setSelectedAccounts([...removedArray]);
      }
    },
    [selectedAccounts],
  );

  const handleSelectMultiAccount = useCallback(
    (accounts, allSelect) => {
      const newData = accounts.reduce(
        (total, item) => {
          if (selectedAccounts.findIndex((acc) => acc === item.accountNo) === -1) {
            total.push(item.accountNo);
          } else {
            if (allSelect) {
              total = total.filter((acc) => acc !== item.accountNo);
            }
          }
          return total;
        },
        [...selectedAccounts],
      );
      setSelectedAccounts([...newData]);
    },
    [selectedAccounts],
  );

  const onChangeActivitiesTable = (params) => {
    getFilteredBankAccountsForApply({ ...params, pageNumber: params.pageNumber });
    setFilterParams((prevState) => ({ ...prevState, ...params, pageNumber: params.pageNumber }));
  };

  const handleChangePageSize = (size) => {
    getFilteredBankAccountsForApply({ ...filterParams, pageSize: size, pageNumber: 1 });
    setFilterParams((prevState) => ({ ...prevState, pageSize: size, pageNumber: 1 }));
  };

  const onClear = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      selectedCompanies: selectedVkn,
      selectedBankAccountGroups: selectedBankAccountGroups,
      selectedBanks: [],
      selectedBankAccounts: [],
      selectedCurrencies: [],
      pageNumber: 1,
      onlyHasTransaction: false,
      useAbsoluteValues: false,
      incomeActivities: true,
      outgoingActivities: true,
      startDate: null,
      endDate: null,
      pageSize: 20,
      description: '',
      accountTitle: '',
      minAmount: null,
      maxAmount: null,
    }));
  };

  const renderCardRightItems = () => {
    if (downloadLoading) {
      return (
        <div className={styles['card-download-loader']}>
          <Loader />
        </div>
      );
    }

    return (
      <div className={styles['card-container']}>
        {selectedReceipts.length === 0 && (
          <Fragment>
            <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
              <ExcelIcon />
              <div
                onClick={() => {
                  getBankAccountBalancesReportFunc('XLSX');
                }}>
                <FormattedMessage id="molecule.accountActivities.accountBalance" defaultMessage="HESAP BAKİYELERİ" />
              </div>
            </div>
            <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
              <ExcelIcon />
              <div
                onClick={() => {
                  getBankBalancesReportFunc('XLSX');
                }}>
                <FormattedMessage id="molecule.accountActivities.bankAccounts" defaultMessage="BANKA BAKİYELERİ" />
              </div>
            </div>
            <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
              <ExcelIcon />
              <div
                onClick={() => {
                  exportActivitiesByType('XLSX');
                }}>
                <FormattedMessage id="sidebar.accountActivities.accountActivities" defaultMessage="HESAP HAREKETLERİ" />{' '}
                {selectedAccounts.length > 0 && `(${selectedAccounts.length})`}
              </div>
            </div>
          </Fragment>
        )}

        {selectedReceipts.length > 0 && (
          <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
            <Icon name="icon-navigation-file-download-24-px" className={styles.downloadIcon} />
            <div
              onClick={() => {
                getCollectiveReceipts('XLSX');
              }}>
              <FormattedMessage
                id="molecule.accountActivities.batchReceiptDownloadText"
                defaultMessage="Toplu Dekont İndir"
              />{' '}
              ({selectedReceipts.length})
            </div>
          </div>
        )}

        {selectedReceipts.length > 0 && (
          <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
            <ExcelIcon />
            <div
              onClick={() => {
                exportActivitiesByTransactionIds('XLSX');
              }}>
              <FormattedMessage
                id="molecule.accountActivities.batchXLSXDownloadText"
                defaultMessage="Toplu Hareket İndir"
              />{' '}
              ({selectedReceipts.length})
            </div>
          </div>
        )}
        <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
          <Tooltip title={intl.messages['info.dashboard.currencyTable']} position="bottom" trigger="mouseenter">
            <Icon name={'icon-info-24-px'} />
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <>
      <Widget>
        <Breadcrumb
          title={<FormattedMessage id="sidebar.accountActivities" defaultMessage="Hesap Hareketleri" />}
          breadcrumbs={breadcrumbs}
        />
      </Widget>
      <Filters
        filterParams={filterParams}
        setFilterParams={setFilterParams}
        onClear={onClear}
        onApplyFilter={() => applyFilter()}
        hasActivityAccounts={onlyActivitiesBankAccounts}
        accountInformations={accountInformations}
        bankAccountOptionsLoading={bankAccountOptionsLoading}
        getBankAccounts={getBankAccounts}
      />
      <Widget>
        <Card
          loading={loading}
          showLoadingOnData={bankAccountsListByFilter.length > 0}
          loader={<Loader />}
          className={styles['card']}
          contentClassNames={styles['card-item']}
          rightItem={renderCardRightItems()}
          title={
            <div className={styles['card-title']}>
              <FormattedMessage id="molecule.activies.accordionHeader.leftTitle" defaultMessage="Detaylar" />
            </div>
          }>
          <table className={cx(styles['table'], styles[`table-${theme}`])}>
            <tbody className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
              <tr>
                <th>
                  <div
                    className={cx(
                      styles['card-subContent'],
                      styles[`card-subContent-${theme}`],
                      styles['onlyActivities'],
                    )}>
                    <Switch
                      round
                      onChecked={() => setOnlyActivities(!onlyActivities)}
                      checked={onlyActivities}
                      containerStyle={cx(styles.switch)}
                      className={cx(styles[`switch-${theme}`], {
                        [styles[`icon-${theme}`]]: true,
                        [styles['icon-selected']]: onlyActivities,
                        'icon-unSelected': !onlyActivities,
                      })}
                    />
                    <Text>
                      <FormattedMessage
                        id="molecule.accountActivities.showOnlyHasActivity"
                        defaultMessage="Sadece Hareketleri Göster"
                      />
                    </Text>
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
          {onlyActivities && bankAccountsListByFilter.length > 0 && (
            <div className={styles['card-content']}>
              <ActivitiesDetailTable
                accountNo={onlyActivitiesBankAccounts}
                startDate={filterParams.startDate}
                endDate={filterParams.endDate}
                description={filterParams.description}
                minAmount={filterParams.minAmount}
                maxAmount={filterParams.maxAmount}
                useAbsoluteValues={filterParams.useAbsoluteValues}
                incomeActivities={filterParams.incomeActivities}
                outgoingActivities={filterParams.outgoingActivities}
                bankCode={filterParams.selectedBanks}
                selectedBranches={[]}
                selectedReceipts={selectedReceipts}
                onSelectSingleReceipt={handleSelectSingleReceipt}
                onSelectMultiReceipt={handleSelectMultiReceipts}
                pageSizesArray={pageSizesArray}
                showDailyActivities={true}
                customColumns={activitiesTableColumns}
              />
            </div>
          )}
          {bankAccountsListByFilter.length > 0 && !onlyActivities && (
            <div className={styles['card-content']}>
              <ActivitiesTable
                totalBalance={totalBalance}
                data={bankAccountsListByFilter}
                totalCount={totalCount}
                defaultParams={filterParams}
                handleSelectSingleReceipt={handleSelectSingleReceipt}
                handleSelectMultiReceipts={handleSelectMultiReceipts}
                selectedReceipts={selectedReceipts}
                onChange={onChangeActivitiesTable}
                handleChangePageSize={handleChangePageSize}
                handleSelectSingleAccount={handleSelectSingleAccount}
                handleSelectMultiAccount={handleSelectMultiAccount}
                selectedAccounts={selectedAccounts}
              />
            </div>
          )}
          {bankAccountsListByFilter.length === 0 && (
            <div className={cx(styles[`emptyView`], styles[`emptyView-${theme}`])}>
              <FormattedMessage
                id="general.nullMessageForAccountActivity"
                defaultMessage="Hesap hareketi bulunmamaktadır."
              />
            </div>
          )}
        </Card>
      </Widget>
    </>
  );
};

export default injectIntl(BankActivity);
