import { createSlice } from '@reduxjs/toolkit';

const themeStorageKey = 'app-theme';

const themes = {
  dark: 'dark',
  light: 'light',
};

const initialState = {
  theme: themes.dark,
};

export const themeReducer = createSlice({
  initialState,
  name: 'theme',
  reducers: {
    initTheme: (state) => {
      const themeFromStorage = localStorage.getItem(themeStorageKey);

      if (themeFromStorage && state.theme !== themeFromStorage) {
        state.theme = themeFromStorage;
      }
    },
    toggleTheme: (state) => {
      const theme = state.theme === 'light' ? 'dark' : 'light';

      localStorage.setItem(themeStorageKey, theme);
      state.theme = theme;
    },
  },
});

export const { initTheme, toggleTheme } = themeReducer.actions;

export default themeReducer.reducer;
