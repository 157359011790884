import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import request from 'library/@e5p-request';

import cx from 'classnames';

import formattedCurrency from 'utils/formatted-currency';

import styles from './styles.scss';
import { Select, SelectItem } from 'components';
import Card from 'components/card';
import Loader from 'components/loader';
import Text from 'components/text';
import { useAppSelector } from 'src/redux/store';
import { useModal } from 'src/hooks/useModal';
import Modal from 'components/modal';
import moment from '../../../../../node_modules/moment-timezone/index';
import ActivitiesTable from 'containers/elements/banks-activities/activities-table/activities-table';
import DailySummaryModalDownloadArea from 'containers/elements/dailySummary/daily-summary-modal-download-area/daily-summary-modal-download-area';
import { Checkbox } from 'components/index';

const activityType = {
  incoming: {
    incomeActivities: true,
    outgoingActivities: false,
  },
  outgoing: {
    incomeActivities: false,
    outgoingActivities: true,
  },
  all: {
    incomeActivities: true,
    outgoingActivities: true,
  },
};

const { getBankAccountsByFilter } = request;

const DailyActivitiesTable = ({ intl }) => {
  const lang = intl.locale;
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const OPTIONS = [
    {
      label: intl.formatMessage({
        id: 'dashboard.dailyActivitiesTab.todayText',
        defaultMessage: 'Bugün',
      }),
      value: 'TODAY',
      startDate: moment().format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
      endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.dailyActivitiesTab.weekText',
        defaultMessage: 'Bu Hafta',
      }),
      value: 'THIS_WEEK',
      startDate: moment().subtract(1, 'weeks').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
      endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    },
    {
      label: intl.formatMessage({
        id: 'dashboard.dailyActivitiesTab.monthText',
        defaultMessage: 'Bu Ay',
      }),
      value: 'THIS_MONTH',
      startDate: moment().subtract(1, 'months').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
      endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    },
  ];

  const [dateRange, setDateRange] = React.useState(OPTIONS[0]);

  const [currencies, setCurrencies] = React.useState([]);
  const [currenciesLoading, setCurrenciesLoading] = React.useState(true);

  const [budgets, setBudgets] = React.useState([]);
  const [budgetsLoading, setBudgetsLoading] = React.useState(true);

  const [activitiesDetailTableData, setActivitiesDetailTableData] = React.useState(null);

  const [selectedReceipts, setSelectedReceipts] = React.useState([]);
  const [selectedAccounts, setSelectedAccounts] = React.useState([]);
  const [transactionIds, setTransactionIds] = React.useState([]);
  const [totalBalance, setTotalBalance] = React.useState();
  const [totalCount, setTotalCount] = React.useState();
  const [bankAccountsListByFilter, setBankAccountsListByFilter] = React.useState([]);
  const [modalLoading, setModalLoading] = React.useState(false);

  const [filterParams, setFilterParams] = React.useState({
    selectedCompanies: selectedVkn,
    selectedBankAccountGroups: selectedBankAccountGroups,
    selectedBanks: [],
    selectedBankAccounts: [],
    selectedCurrencies: [],
    pageNumber: 1,
    onlyHasTransaction: true,
    useAbsoluteValues: false,
    incomeActivities: true,
    outgoingActivities: true,
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
    endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    pageSize: 20,
    description: '',
    accountTitle: '',
    minAmount: null,
    maxAmount: null,
    deneme: null,
  });

  const { isShowing, toggle } = useModal();

  React.useEffect(() => {
    if (!isShowing) {
      setFilterParams((prevState) => ({ ...prevState, pageNumber: 1 }));
      setBankAccountsListByFilter([]);
      setTotalBalance(0);
      setTotalCount(0);
      setSelectedReceipts([]);
    }
  }, [isShowing]);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    request
      .currencies(selectedVkn, selectedBankAccountGroups)
      .then((res) => {
        setCurrencies(res);
        setCurrenciesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCurrenciesLoading(false);
      });
  }, [selectedVkn, selectedBankAccountGroups]);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    setBudgetsLoading(true);

    request
      .getBudgetSummaryDetails(selectedVkn, dateRange.value, selectedBankAccountGroups)
      .then((res) => {
        setBudgets(res);
        setBudgetsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBudgetsLoading(false);
      });
  }, [selectedVkn, selectedBankAccountGroups, dateRange.value]);

  React.useEffect(() => {
    if (dateRange.value === 'TODAY') {
      setDateRange(OPTIONS[0]);
    } else if (dateRange.value === 'THIS_WEEK') {
      setDateRange(OPTIONS[1]);
    } else {
      setDateRange(OPTIONS[2]);
    }
  }, [lang]);

  const priceCellOnClickHandler = (budget, currency, activityType) => {
    setActivitiesDetailTableData({
      budget,
      currency,
      activityType,
    });
    !isShowing && toggle();
  };

  React.useEffect(() => {
    if ((activitiesDetailTableData, isShowing)) {
      setModalLoading(true);
      getBankAccountsByFilter({
        ...filterParams,
        selectedCompanies: selectedVkn,
        selectedBankAccountGroups: selectedBankAccountGroups,
        selectedBanks: [activitiesDetailTableData.budget.bankCode],
        selectedBankAccounts: [],
        selectedCurrencies: [activitiesDetailTableData.currency],
        ...activitiesDetailTableData.activityType,
        ...dateRange,
      })
        .then((res) => {
          const newData = res.result.map((item) => {
            return {
              ...item,
              branchAndAccountNo: item.branchCode + ' / ' + item.accountNo,
            };
          });

          setBankAccountsListByFilter(newData);
          setTotalBalance(res.totalBalance);
          setTotalCount(res.totalCount);
          setSelectedReceipts([]);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => setModalLoading(false));
    }
  }, [activitiesDetailTableData, filterParams, isShowing]);

  const renderModalContent = () => {
    if (!activitiesDetailTableData) return <></>;
    return (
      <>
        <Card loading={modalLoading} loader={<Loader />}>
          <div className={styles.modalFilterArea}>
            <div className={styles.checkbox}>
              <Checkbox
                onClick={() => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    onlyHasTransaction: !filterParams.onlyHasTransaction,
                  }));
                }}
                checked={filterParams.onlyHasTransaction}
              />
              <Text>
                <FormattedMessage
                  id="general.showOnlyActivity"
                  defaultMessage="Sadece hareketi olan hesapları göster"
                />
              </Text>
            </div>
            <DailySummaryModalDownloadArea
              selectedReceipts={selectedReceipts}
              selectedAccounts={selectedAccounts}
              filterParams={filterParams}
              activitiesDetailTableData={activitiesDetailTableData}
              dateRange={dateRange}
              transactionIds={transactionIds}
            />
          </div>
          <ActivitiesTable
            totalBalance={totalBalance}
            data={bankAccountsListByFilter}
            totalCount={totalCount}
            defaultParams={{ ...filterParams, ...dateRange, ...activitiesDetailTableData.activityType }}
            handleSelectSingleReceipt={handleSelectSingleReceipt}
            handleSelectMultiReceipts={handleSelectMultiReceipts}
            selectedReceipts={selectedReceipts}
            onChange={onChangeActivitiesTable}
            handleChangePageSize={handleChangePageSize}
            showGoDetailButton
            goDetailDateRange={dateRange}
            handleSelectSingleAccount={handleSelectSingleAccount}
            handleSelectMultiAccount={handleSelectMultiAccount}
            selectedAccounts={selectedAccounts}
          />
        </Card>
      </>
    );
  };

  const onChangeActivitiesTable = (params) => {
    setFilterParams((prevState) => ({ ...prevState, pageNumber: params.pageNumber }));
  };

  const handleChangePageSize = (size) => {
    setFilterParams((prevState) => ({ ...prevState, pageSize: size, pageNumber: 1 }));
  };

  const handleSelectSingleReceipt = (column) => {
    const { accountNo, bankCode, transactionId } = column;
    const selectedData = { accountNo, bankCode, transactionId };
    if (selectedReceipts.findIndex((e) => e.transactionId === selectedData.transactionId) === -1) {
      setSelectedReceipts([...selectedReceipts, selectedData]);
      setTransactionIds([...transactionIds, selectedData.transactionId]);
    } else {
      const removedArray = selectedReceipts.filter((e) => e.transactionId !== selectedData.transactionId);
      const removedTransactionIdsArray = transactionIds.filter((t) => t !== selectedData.transactionId);
      setSelectedReceipts(removedArray);
      setTransactionIds(removedTransactionIdsArray);
    }
  };

  const handleSelectMultiReceipts = React.useCallback(
    (selectedAccountActivities, allSelect) => {
      const newData = selectedAccountActivities.reduce(
        (total, item) => {
          if (selectedReceipts.findIndex((e) => e.transactionId === item.transactionId) === -1) {
            const { accountNo, bankCode, transactionId } = item;
            const selectedData = { accountNo, bankCode, transactionId };
            total.push(selectedData);
          } else {
            if (allSelect) {
              total = total.filter((e) => e.transactionId !== item.transactionId);
            }
          }
          return total;
        },
        [...selectedReceipts],
      );

      setSelectedReceipts([...newData]);
    },
    [selectedReceipts],
  );

  const handleSelectSingleAccount = React.useCallback(
    (column) => {
      const { accountNo } = column;
      if (selectedAccounts.findIndex((acc) => acc === accountNo) === -1) {
        setSelectedAccounts([...selectedAccounts, accountNo]);
      } else {
        const removedArray = selectedAccounts.filter((acc) => acc !== accountNo);
        setSelectedAccounts([...removedArray]);
      }
    },
    [selectedAccounts],
  );

  const handleSelectMultiAccount = React.useCallback(
    (accounts, allSelect) => {
      const newData = accounts.reduce(
        (total, item) => {
          if (selectedAccounts.findIndex((acc) => acc === item.accountNo) === -1) {
            total.push(item.accountNo);
          } else {
            if (allSelect) {
              total = total.filter((acc) => acc !== item.accountNo);
            }
          }
          return total;
        },
        [...selectedAccounts],
      );
      setSelectedAccounts([...newData]);
    },
    [selectedAccounts],
  );

  const renderTableBody = () => {
    return budgets.map((budget, key) => (
      <tr key={`${budget.bankCode}-${key}`}>
        <td className={styles['table-cell']}>
          <Text className={styles['table-cell-text']}>{budget.bankName}</Text>
        </td>

        {currencies.map((currency, key) => {
          const curr = budget.amounts.find((amount) => amount.currency === currency.summary);

          return curr ? (
            <React.Fragment key={`daily-activities-table-${budget.bankCode}-${key}`}>
              <td
                className={cx(styles['table-cell'], {
                  [styles['table-cell-hasBalance']]: curr.incomingTotalAmount,
                  [styles['table-cell-rightAlign']]: curr.incomingTotalAmount !== null,
                })}
                onClick={() =>
                  curr.incomingTotalAmount && priceCellOnClickHandler(budget, curr.currency, activityType.incoming)
                }>
                <Text className={styles['table-cell-text']}>
                  {lang === 'tr' ? (
                    <>
                      {curr.incomingTotalAmount ? formattedCurrency(curr.incomingTotalAmount) : ' '}{' '}
                      {curr.incomingTotalAmount && <span style={{ color: currency.color }}>{currency.symbol}</span>}
                    </>
                  ) : (
                    <>
                      {curr.incomingTotalAmount && <span style={{ color: currency.color }}>{currency.symbol}</span>}{' '}
                      {curr.incomingTotalAmount ? formattedCurrency(curr.incomingTotalAmount) : ' '}
                    </>
                  )}
                </Text>
              </td>

              <td
                className={cx(styles['table-cell'], {
                  [styles['table-cell-hasBalance']]: curr.outgoingTotalAmount,
                  [styles['table-cell-rightAlign']]: curr.outgoingTotalAmount !== null,
                })}
                onClick={() =>
                  curr.outgoingTotalAmount && priceCellOnClickHandler(budget, curr.currency, activityType.outgoing)
                }>
                <Text className={styles['table-cell-text']}>
                  {lang === 'tr' ? (
                    <>
                      {curr.outgoingTotalAmount ? formattedCurrency(curr.outgoingTotalAmount) : ' '}{' '}
                      {curr.outgoingTotalAmount && <span style={{ color: currency.color }}>{currency.symbol}</span>}
                    </>
                  ) : (
                    <>
                      {curr.outgoingTotalAmount && <span style={{ color: currency.color }}>{currency.symbol}</span>}{' '}
                      {curr.outgoingTotalAmount ? formattedCurrency(curr.outgoingTotalAmount) : ' '}
                    </>
                  )}
                </Text>
              </td>

              <td
                className={cx(styles['table-cell'], {
                  [styles['table-cell-hasBalance']]: curr.outgoingTotalAmount || curr.incomingTotalAmount,
                  [styles['table-cell-rightAlign']]:
                    curr.outgoingTotalAmount !== null || curr.incomingTotalAmount !== null,
                })}
                onClick={() =>
                  (curr.outgoingTotalAmount || curr.incomingTotalAmount) &&
                  priceCellOnClickHandler(budget, curr.currency, activityType.all)
                }>
                <Text className={styles['table-cell-text']}>
                  {lang === 'tr' ? (
                    <>
                      {curr.outgoingTotalAmount || curr.incomingTotalAmount
                        ? formattedCurrency(curr.incomingTotalAmount - curr.outgoingTotalAmount)
                        : ' '}{' '}
                      {curr.outgoingTotalAmount && <span style={{ color: currency.color }}>{currency.symbol}</span>}
                    </>
                  ) : (
                    <>
                      {curr.outgoingTotalAmount && <span style={{ color: currency.color }}>{currency.symbol}</span>}{' '}
                      {curr.outgoingTotalAmount || curr.incomingTotalAmount
                        ? formattedCurrency(curr.incomingTotalAmount - curr.outgoingTotalAmount)
                        : ' '}
                    </>
                  )}
                </Text>
              </td>
            </React.Fragment>
          ) : (
            <React.Fragment key={`daily-activities-table-${budget.bankCode}-${key}`}>
              <td className={styles['table-cell']}>
                <Text className={styles['table-cell-text']}>&nbsp;</Text>
              </td>

              <td className={styles['table-cell']}>
                <Text className={styles['table-cell-text']}>&nbsp;</Text>
              </td>

              <td className={styles['table-cell']}>
                <Text className={styles['table-cell-text']}>&nbsp;</Text>
              </td>
            </React.Fragment>
          );
        })}
      </tr>
    ));
  };

  const loading = currenciesLoading || budgetsLoading;

  return (
    <>
      {isShowing && (
        <Modal
          theme={theme}
          hide={toggle}
          title={intl.formatMessage({
            id: 'dashboard.daily.activities.modal.title',
            defaultMessage: 'Hareketler',
          })}
          closeOnClickOutside={!loading}
          showCloseIcon={!loading}
          className={styles.activitiesModal}>
          {renderModalContent()}
        </Modal>
      )}
      <Card
        loading={loading}
        loader={<Loader />}
        title={
          <FormattedMessage id="dashboard.card.title.dailyActivitiesTableTitle" defaultMessage="Günlük Giriş Çıkış" />
        }
        info={'info.dashboard.dailyActivities'}>
        <div className={styles['table-wrapper']}>
          <table className={cx(styles['table'], styles[`table-${theme}`])}>
            <thead className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
              <tr>
                <th className={styles['table-cell']}>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="general.date" defaultMessage="Tarih" />
                  </Text>
                </th>

                {currencies.map((currency, key) => (
                  <th key={`${currency.summary}-${key}`} colSpan="3" className={styles['table-cell']}>
                    <Text className={styles['table-cell-text']}>{currency.summary}</Text>
                  </th>
                ))}
              </tr>
              <tr>
                <td className={styles['table-cell']}>
                  <Select
                    placeholder={dateRange.label}
                    className={styles['dailyActivities-select']}
                    selectClassName={styles['dailyActivities-select-container']}>
                    {OPTIONS.map((option, index) => (
                      <SelectItem
                        key={`dailyActivities-date-item-${index}`}
                        className={cx(styles['dailyActivities-select-item'], {
                          [styles['dailyActivities-select-item--active']]: option.value === dateRange.value,
                        })}
                        value={option.value}
                        isActive={option.value === dateRange.value}
                        onClick={() => setDateRange(option)}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </Select>
                </td>
                {currencies.map((currency, key) => (
                  <React.Fragment key={`daily-activities-table-${key}`}>
                    <td className={styles['table-cell']}>
                      <Text className={styles['table-cell-text']}>
                        <FormattedMessage id="general.incoming" defaultMessage="Giriş" />
                      </Text>
                    </td>

                    <td className={styles['table-cell']}>
                      <Text className={styles['table-cell-text']}>
                        <FormattedMessage id="general.outgoing" defaultMessage="Çıkış" />
                      </Text>
                    </td>

                    <td className={styles['table-cell']}>
                      <Text className={styles['table-cell-text']}>
                        <FormattedMessage id="general.difference" defaultMessage="Fark" />
                      </Text>
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody className={cx(styles['table-body'], styles[`table-body-${theme}`])}>{renderTableBody()}</tbody>
            <tfoot className={styles['table-footer']}>
              <tr className={styles['table-footer-row']}>
                <td className={styles['table-footer-row']}>
                  {!budgets ||
                    (budgets.length === 0 && (
                      <Text className={styles['table-cell-noData']}>
                        <FormattedMessage
                          id="general.emptyMessage"
                          defaultMessage="Gösterilecek öğe bulunmamaktadır."
                        />
                      </Text>
                    ))}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </Card>
    </>
  );
};

export default React.memo(injectIntl(DailyActivitiesTable));
