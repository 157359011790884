import React from 'react';
import cx from 'classnames';

import Authentication from 'utils/authentication';

import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { setSelectedCurrency } from 'src/redux/reducers/select-currency/select-currency-reducer';
import { Select, SelectItem } from 'components';

import styles from './styles.scss';

const SelectDefaultCurrency = () => {
  const dispatch = useAppDispatch();

  const {
    selectCurrency: { defaultCurrencyItem, currencyList },
    theme: { theme },
  } = useAppSelector((state) => state);

  const session = Authentication.hasSession();

  if (!session) {
    return <div />;
  }

  return (
    <Select
      placeholder={defaultCurrencyItem?.summary}
      className={cx(styles['header-select-currency'], styles[`header-select-currency-${theme}`])}
      selectClassName={styles['header-select-currency-container']}>
      {currencyList.map((currency, index) => (
        <SelectItem
          key={`currency-item-${index}`}
          className={styles['header-select-currency-item']}
          value={currency.summary}
          isActive={defaultCurrencyItem?.summary === currency.summary}
          onClick={() => {
            dispatch(setSelectedCurrency(currency));
          }}>
          {currency.summary}
        </SelectItem>
      ))}
    </Select>
  );
};

export default SelectDefaultCurrency;
