import React from 'react';
import cx from 'classnames';

import styles from './styles.scss';

const Content = ({ children, className, ...props }) => {
  return (
    <div className={cx(styles.content, className)} {...props}>
      {children}
    </div>
  );
};

export default Content;
