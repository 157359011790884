import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Loading = () => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={styles['sk-chase-wrapper']}>
      <div className={cx(styles['sk-chase'], styles[`sk-chase-${theme}`])}>
        <div className={cx(styles['sk-chase-dot'], styles[`sk-chase-dot-${theme}`])}></div>
        <div className={cx(styles['sk-chase-dot'], styles[`sk-chase-dot-${theme}`])}></div>
        <div className={cx(styles['sk-chase-dot'], styles[`sk-chase-dot-${theme}`])}></div>
        <div className={cx(styles['sk-chase-dot'], styles[`sk-chase-dot-${theme}`])}></div>
        <div className={cx(styles['sk-chase-dot'], styles[`sk-chase-dot-${theme}`])}></div>
        <div className={cx(styles['sk-chase-dot'], styles[`sk-chase-dot-${theme}`])}></div>
      </div>
    </div>
  );
};

export default Loading;
