import { gql } from '@apollo/client';
import BUDGET_QUERY_RANGE_ENUM from './enum';

const budgetSummaryDetailsQuery = (
  selectedVkns = [],
  dateRange = BUDGET_QUERY_RANGE_ENUM.TODAY,
  selectedBankAccountGroups,
) => {
  return {
    query: gql`
      query($selectedVkns: [String], $dateRange: DateRangeEnum, $selectedBankAccountGroups: [Guid]) {
        budgetQuery {
          summaryDetails(
            selectedVkns: $selectedVkns
            dateRange: $dateRange
            selectedBankAccountGroups: $selectedBankAccountGroups
          ) {
            amounts {
              currency
              incomingTotalAmount
              outgoingTotalAmount
              totalAmount
            }
            bankCode
            bankName
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccountGroups,
      dateRange,
    },
  };
};

export default budgetSummaryDetailsQuery;
