/* eslint-disable no-unused-vars */
import React from 'react';

import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const NotificationItem = ({ icon, title, text, isRead, onClick }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div
      className={cx(styles['notification-item'], styles[`notification-item-${theme}`], {
        [styles[`notification-item-active`]]: isRead,
        [styles[`notification-item-active-${theme}`]]: isRead,
        [styles[`notification-item-onclick`]]: onClick,
      })}
      onClick={onClick ? onClick : () => {}}>
      <div
        className={cx(styles['notification-item-icon'], styles[`notification-item-icon-${theme}`], {
          [styles[`notification-item-icon-active`]]: isRead,
        })}>
        <i className={cx(icon)} />
      </div>
      <div className={cx(styles['notification-item-text-area'], styles[`notification-item-text-area-${theme}`])}>
        <div className={cx(styles['notification-item-text-area-title'])}>{title}</div>
        <div className={cx(styles['notification-item-text-area-subtitle'], styles[`notification-item-${theme}`])}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default NotificationItem;
