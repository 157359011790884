import React from 'react';

import { FormattedMessage } from 'react-intl';

import cx from 'classnames';

import Card from 'components/card';
import Accordion from 'components/accordion';
import Icon from 'components/icon';
import Table from 'components/table';

import BankLogo from 'components/bank-logo';
import { Tooltip } from 'react-tippy';
import Loader from 'components/loader';

import BankCardItem from 'components/bank-card-item';

import formattedCurrency from 'utils/formatted-currency';
import 'react-tippy/dist/tippy.css';
import styles from './styles.scss';
import moment from 'moment-timezone';
import { formattedLongText } from 'utils/format-long-text';
const columns = [
  {
    name: <FormattedMessage id="molecules.column.amount.title" defaultMessage="Tutar" />,
    key: 'balance',
    isSort: false,
    render: (props) => {
      return (
        <div className={cx(styles['balance'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return value ? <span className={cx(styles['balance-value'])}>{formattedCurrency(value)}</span> : value;
    },
  },
  {
    name: <FormattedMessage id="molecules.column.companyName.title" defaultMessage="Şirket Adı" />,
    key: 'companyName',
    isSort: false,
    render: (props) => {
      return (
        <div className={cx(styles['branchName'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <span className={cx(styles['branchName-value'])}>{value}</span>;
    },
  },
  {
    name: <FormattedMessage id="molecules.column.branchAndAccountNo.title" defaultMessage="Şube / Hesap No" />,
    key: 'branchAndAccountNo',
    isSort: false,
    render: (props) => {
      return (
        <div className={cx(styles['branchNo'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <span className={cx(styles['branchNo-value'])}>{value}</span>;
    },
  },
  {
    name: '',
    key: 'bankAccountTitle',
    isSort: false,
    render: (props) => {
      return (
        <div className={cx(styles['bankAccountTitle'])}>
          <span className={cx(styles['bankAccountTitleSpan'])}>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return (
        <span className={cx(styles['bankAccountTitleSpan'])}>
          <Tooltip title={value?.length > 20 ? value : ''} position="top" trigger="mouseenter">
            {formattedLongText(value, 20)}
          </Tooltip>
        </span>
      );
    },
  },
  {
    name: '',
    key: 'maturityEndDate',
    isSort: false,
    render: (props) => {
      return (
        <div className={cx(styles['action'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      const renderFormattedDate = (d) => {
        const tz = moment.tz.guess();

        if (tz.indexOf('America') !== -1) {
          return moment(d).format('MM/DD/YYYY');
        }

        return moment(d).format('DD/MM/YYYY');
      };

      return (
        <div className={cx(styles['action'])}>
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {value && (
              <span>
                <Tooltip
                  title={` ${
                    moment(value) > moment()
                      ? ` Vade Bitiş Tarihi :  ${renderFormattedDate(value)}`
                      : `Vadesi :  ${renderFormattedDate(value)} tarihinde dolmuştur. `
                  }  `}
                  position="top"
                  trigger="mouseenter">
                  <span className={moment(value) > moment() ? styles.accInfoContinue : styles.accInfoFinished}>
                    VBT
                  </span>
                </Tooltip>
              </span>
            )}
            <FormattedMessage id="molecules.column.seeDetails.title" defaultMessage="Detaya Git" />
          </span>
          <Icon name="icon-navigation-east-24-px" className={cx(styles['action-icon'])} />
        </div>
      );
    },
  },
];

const BankCard = ({ history, account, loading, selectedVkn }) => {
  return (
    <Card
      loading={loading}
      loader={<Loader />}
      leftItem={<BankLogo bankName={account.bankName} logoUrl={account.bankLogo} />}
      className={styles.card}
      info={'info.bankAccounts.box'}>
      {account.balances.map((item, index) => {
        return (
          <Accordion
            key={index.toString()}
            selectedVkn={selectedVkn}
            content={
              <BankCardItem
                currency={item.currency}
                symbol={item.currencySymbol}
                currencyName={item.currencyName}
                total={formattedCurrency(item.totalBalance)}
                accountCount={item.accountCount}
                accountCountHasData={item.accountCountHasData}
              />
            }
            expantedContent={
              <div className={styles.bankAccountsTableWrapper}>
                <Table
                  columns={columns}
                  data={item.details}
                  onClick={(values) => {
                    history.push({
                      pathname: '/bank-activity',
                      state: {
                        selectedBankAccounts: [values.bankAccountNo],
                        selectedCurrencies: [item.currency],
                        selectedBanks: [account.bankCode],
                      },
                    });
                  }}
                />
              </div>
            }
            icon={<Icon name="icon-navigation-expand-more-24-px" />}
            expantedIcon={<Icon name="icon-navigation-expand-less-24-px" />}
          />
        );
      })}
    </Card>
  );
};

export default BankCard;
