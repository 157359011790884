import React, { Fragment, useEffect } from 'react';
import { useAppSelector } from 'src/redux/store';
import { useIntl } from 'react-intl';

import { Icon } from 'components';

import cx from 'classnames';
import styles from './styles.scss';

const CompanySelectItem = (props) => {
  const intl = useIntl();
  const { theme } = useAppSelector((state) => state.theme);
  const { items, selectedItems, onHandleCompany, level = 1, searchText } = props;
  const [showMore, setShowMore] = React.useState([]);
  /** WHEN OUTSIDE CLICK AUTO CLOSED */
  useEffect(() => {
    if (showMore.length > 0) {
      document.addEventListener('click', () => {
        setShowMore([]);
      });
      document.removeEventListener('click', () => {});
    }
  }, [showMore]);
  /** FINISH WHEN OUTSIDE CLICK AUTO CLOSED */

  const getStylesByLevel = (optionLevel, appTheme) => {
    const opacity = 0.1 * (optionLevel - 1);
    const fontSize = 14 - (optionLevel - 1) * 0.5;
    const backgroundColor = appTheme === 'dark' ? `rgba(121, 121, 121,${opacity})` : `rgba(66, 66, 66,${opacity})`;
    const paddingLeft = optionLevel * 8;
    return { 'option-item': { backgroundColor }, 'option-item-name': { fontSize, paddingLeft } };
  };

  const showMoreToogle = (optionUIName) => {
    let cloneShowMore = [...showMore];
    const index = cloneShowMore.findIndex((item) => item === optionUIName);
    if (index < 0) {
      cloneShowMore.push(optionUIName);
    } else {
      cloneShowMore.splice(index, 1);
    }
    setShowMore(cloneShowMore);
  };

  const getOpenStatusByName = (name, showItems) => {
    return showItems.findIndex((item) => item === name) > -1;
  };
  return (
    <Fragment>
      {items
        .filter((item) => item?.name?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()))
        .map((item, index) => {
          const openStatus = getOpenStatusByName(item.optionUIName, showMore);
          const stylesByLevel = getStylesByLevel(level, theme);

          const itemName = item.isAll
            ? intl.formatMessage({ id: 'organism.selectCompaniesAllCompanies.label', defaultMessage: 'Tüm Şirketler' })
            : item.name;

          const isSelected =
            selectedItems.findIndex((selectedCompany) => selectedCompany.optionUIName === item.optionUIName) > -1;

          const isHalfSelected =
            selectedItems.findIndex((selectedCompany) => selectedCompany.parentUIName === item.optionUIName) > -1;

          return (
            <Fragment key={index.toString()}>
              <div
                style={stylesByLevel['option-item']}
                onClick={(e) => {
                  e.stopPropagation();
                  onHandleCompany(item);
                }}
                className={cx(styles['option-item'], styles[`option-item-${theme}`], {
                  [styles[`option-item-select-all`]]: item.isAll,
                  [styles[`option-item-select-half`]]: isHalfSelected,
                })}>
                <abbr title={itemName}>
                  <div
                    style={stylesByLevel['option-item-name']}
                    className={cx(styles['option-item-name'], {
                      [styles['option-item-name-selected']]: isSelected,
                      [styles['option-item-name-hasArrow']]: item.isNode,
                    })}>
                    <span data-parent={item.parentUIName} data-ui={item.optionUIName}>
                      {itemName}
                    </span>
                  </div>
                </abbr>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    showMoreToogle(item.optionUIName);
                  }}
                  className={cx(styles['option-item-show-more-wrapper'], {
                    [styles['option-item-show-more-wrapper-visible']]: item.isNode,
                  })}>
                  <div
                    className={cx(styles['option-item-show-more'], {
                      [styles['option-item-show-more-visible']]: item.isNode,
                      [styles['option-item-show-more-selected']]: openStatus,
                    })}>
                    <Icon
                      className={cx(styles['option-item-header-right-icon'])}
                      name={openStatus ? 'icon-navigation-expand-less-24-px' : 'icon-navigation-expand-more-24-px'}
                    />
                  </div>
                </div>
              </div>
              <div
                className={cx(styles['option-sub-items'], {
                  [styles[`option-sub-items-visible`]]: openStatus,
                })}>
                {item.children && (
                  <CompanySelectItem {...props} items={item.children} key={index.toString()} level={level + 1} />
                )}
              </div>
            </Fragment>
          );
        })}
    </Fragment>
  );
};

export default CompanySelectItem;
