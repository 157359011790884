import React from 'react';

import { storageKeys } from 'library/@e5p-request/config/constants';
import { local, session } from 'library/@e5p-request/helpers';

const Version = ({ children }) => {
  React.useEffect(() => {
    const appVersion = localStorage.getItem('app-version');
    const envVersion = window.configEnv.APP_ENV_VERSION;

    if (appVersion !== envVersion) {
      session.removeItem(storageKeys.token);
      local.removeItem(storageKeys.user);
      localStorage.removeItem('selected-companies');
      localStorage.removeItem('selected-company');
      localStorage.removeItem('selected-currency');
      localStorage.setItem('app-version', envVersion);

      setTimeout(() => {
        window.location = '/';
      }, 100);
    }
  }, []);

  return children;
};

export default Version;
