import { gql } from '@apollo/client';

const bankAccounts = (selectedBank, selectedVkn, uid) => {
  return {
    query: gql`
      query($selectedBank: Int, $selectedVkn: String, $uid: String) {
        bankAccountQuery {
          bankAccountByUid(selectedBank: $selectedBank, selectedVkn: $selectedVkn, uid: $uid) {
            uid
            companyName
            accountNo
            bankName
            vkn
            title
          }
        }
      }
    `,
    variables: {
      selectedBank,
      selectedVkn,
      uid,
    },
  };
};

export default bankAccounts;
