import React from 'react';
import cx from 'classnames';

import styles from './styles.scss';
import { useAppSelector } from 'src/redux/store';

const Widget = ({ children, title, row = 1, column, center, style, between, classname }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={cx(styles.widget, classname)}>
      {title && <div className={cx(styles[`widget-title`], styles[`widget-title-${theme}`])}>{title}</div>}
      <div
        className={cx(styles.children, {
          [styles.row]: row,
          [styles[`row-${row}`]]: row && typeof row === 'number',
          [styles.column]: column,
          [styles.center]: center,
          [styles.between]: between,
        })}
        style={style}>
        {children}
      </div>
    </div>
  );
};

export default Widget;
