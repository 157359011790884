import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';
import { Link, NavLink } from 'react-router-dom';
import Icon from 'components/icon';

import Logo from 'components/logo';

import Notifications from 'containers/elements/@commons/notifications';
import SelectLanguage from 'containers/elements/@commons/select-language';
import SelectTheme from 'containers/elements/@commons/select-theme';
import SelectDefaultCurrency from 'containers/elements/header/select-default-currency';

import SideBar from 'containers/layouts/sidebar';

import styles from './styles.scss';
import decoder from 'library/@e5p-request/helpers/jwt-decoder';
import Authentication from 'utils/authentication';

const Header = () => {
  const { theme } = useAppSelector((state) => state.theme);

  const [sidebar, setSidebar] = React.useState(false);

  const showSidebar = (e) => {
    e.stopPropagation();
    setSidebar(!sidebar);
  };

  const user = decoder(Authentication.getToken());
  React.useEffect(() => {
    if (sidebar === true) {
      document.addEventListener('click', () => {
        setSidebar(false);
      });
      document.removeEventListener('click', () => {});
    }
  }, [sidebar]);

  return (
    <div id={styles.header} className={styles[`theme-${theme}`]}>
      <div className={styles['header-default']}>
        <div id={styles['header-left']}>
          <Link to="/dashboard">
            <Logo />
          </Link>
        </div>
        <div id={styles['header-right']}>
          <div>
            <SelectDefaultCurrency />
          </div>
          <div>
            <Notifications />
          </div>
          <div>
            <SelectLanguage />
          </div>
          <div>
            <SelectTheme />
          </div>
          <div>
            <NavLink to="defaultCompanySelect" className={cx(styles[`defCompanyLink`])}>
              <Icon name="icon-action-setting-24-px" className={cx(styles[`sidebar-item-${theme}-icon`])} />
            </NavLink>
          </div>
          <div className={cx(styles.user, styles[`user-${theme}`])}>
            <Icon name="icon-icon-navigation-user" />{' '}
            {user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']}
          </div>
        </div>
      </div>
      <div className={cx(styles['mobile-header'])}>
        <div className={styles['mobile-header-left']}>
          <div className={styles['mobile-menu-button']} onClick={showSidebar}>
            <Icon name="icon-navigation-menu-24-px" />
          </div>
        </div>
        <div className={styles['mobile-header-center']}>
          <Logo />
        </div>
        <div className={styles['mobile-header-right']}>
          <div>
            <Notifications />
          </div>
        </div>
      </div>
      <div
        className={cx(styles.menu, styles[`menu-${theme}`], {
          [styles['menu-active']]: sidebar,
        })}>
        <div className={styles['menu-header']}>
          <div className={cx(styles['menu-close'], styles['mobile-menu-button'])} onClick={showSidebar}>
            <Icon name="icon-navigation-close-24-px-1" />
          </div>
        </div>
        <div className={styles['menu-content']}>
          <SideBar />
        </div>
      </div>
    </div>
  );
};

export default Header;
