import React, { useRef, useState, memo, useEffect } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { fadeOut } from 'src/redux/reducers/select-companies/select-companies-reducer';

import styles from './styles.scss';

function Select(props) {
  const dispatch = useAppDispatch();
  const {
    className,
    children,
    selectClassName,
    offset = 5,
    selected,
    selectedTreshold = 0,
    multiple = false,
    placeholder = '',
    placeholderClassName = '',
    clearSelectedItems,
    showIcon = true,
    position = 'top',
    portalBlur = false,
    ...restProps
  } = props;

  const { theme } = useAppSelector((state) => state.theme);

  const [isOpen, setIsOpen] = useState(false);

  const selectContainerRef = useRef();
  const selectItemContainerRef = useRef();

  useOnClickOutside(selectItemContainerRef, () => closeSelectContainer());

  const closeSelectContainer = async () => {
    if (selectContainerRef.current && selectItemContainerRef.current && isOpen) {
      document.getElementById('root').classList.remove('blurred');
      await selectItemContainerRef.current.classList.remove(styles['fade-in']);
      await setIsOpen(false);
      await dispatch(fadeOut(2));
      return;
    }
  };

  const closeSelectWithEventHandler = async () => {
    document.getElementById('root').classList.remove('blurred');
    await selectItemContainerRef.current.classList.remove(styles['fade-in']);
    await setIsOpen(false);
    await dispatch(fadeOut(2));
  };

  const addEventListener = () => {
    document.addEventListener('closeSelects', closeSelectWithEventHandler, false);
  };

  const removeEventListener = () => {
    document.removeEventListener('closeSelects', closeSelectWithEventHandler, false);
  };

  useEffect(() => {
    addEventListener();

    return () => removeEventListener();
  }, []);

  const handleClick = () => {
    if (isOpen) {
      closeSelectContainer();
    }

    if (selectContainerRef.current && selectItemContainerRef.current && !isOpen) {
      if (portalBlur) document.getElementById('root').classList.add('blurred');
      selectItemContainerRef.current.classList.add(styles['fade-in']);
      setIsOpen(true);

      const selectContainerRect = selectContainerRef.current.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();

      if (position === 'top') {
        selectItemContainerRef.current.setAttribute(
          'style',
          `
            top:${selectContainerRect.y + selectContainerRect.height + offset - bodyRect.y}px;
            left:${selectContainerRect.x}px;
            width:${selectContainerRect.width}px;
            `,
        );
      }

      if (position === 'bottom') {
        const selectItemContainerRect = selectItemContainerRef.current.getBoundingClientRect();
        selectItemContainerRef.current.setAttribute(
          'style',
          `
            top:${selectContainerRect.y - selectItemContainerRect.height - offset}px;
            left:${selectContainerRect.x}px;
            width:${selectContainerRect.width}px;
            `,
        );
      }
    }
  };

  const renderIcon = () => {
    if (multiple && selected) {
      if (Array.isArray(selected) && selected.length > selectedTreshold) {
        return (
          <i
            className={cx(
              styles['select-item-arrowIcon'],
              styles[`select-item-arrowIcon-${theme}`],
              'icon-icon-navigation-close-24-px-1',
            )}
            onClick={(e) => {
              e.stopPropagation();

              clearSelectedItems();
            }}
          />
        );
      }
    }

    return (
      <i
        className={
          !isOpen
            ? cx(
                styles['select-item-arrowIcon'],
                styles[`select-item-arrowIcon-${theme}`],
                'icon-icon-navigation-expand-more-24-px',
              )
            : cx(
                styles['select-item-arrowIcon'],
                styles[`select-item-arrowIcon-${theme}`],
                'icon-icon-navigation-expand-less-24-px',
              )
        }
      />
    );
  };

  const handleClickItemContainer = () => {
    closeSelectContainer();
  };

  return (
    <div
      className={cx(styles['select-container'], className)}
      ref={selectContainerRef}
      onClick={handleClick}
      {...restProps}>
      {placeholder && (
        <div
          className={cx(styles['select-item-selected'], styles[`select-item-selected-${theme}`], placeholderClassName)}>
          {placeholder}
        </div>
      )}

      {showIcon && renderIcon()}

      {createPortal(
        <ul
          className={cx(styles['select-item-container'], styles[`select-item-container-${theme}`], selectClassName)}
          ref={selectItemContainerRef}
          onClick={handleClickItemContainer}>
          {children}
        </ul>,
        document.getElementById('select-root'),
      )}
    </div>
  );
}

export default memo(Select);
