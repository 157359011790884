import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Authentication from 'utils/authentication';
import cx from 'classnames';

import Page from 'containers/layouts/page';
import Content from 'containers/layouts/content';
import Main from 'containers/layouts/main';

import Card from 'components/card';
import Widget from 'components/widget';
import Input from 'components/input';
import Button from 'components/button';

import Logo from 'components/logo';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import Text from 'components/text';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Verify = ({ location }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [codes, setCode] = useState({
    'field-0': '',
    'field-1': '',
    'field-2': '',
    'field-3': '',
    'field-4': '',
    'field-5': '',
  });

  const [time, setTime] = useState(120);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const currentFieldIndex = +name.split('-')[1];

    //* eger value yoksa silinirse  ve input sırası 0 degilse önceki input'a gitmeli
    if (!value) {
      setCode({
        ...codes,
        [name]: value,
      });
      const nextSibling = document.querySelector(
        `input[name=field-${currentFieldIndex > 0 ? currentFieldIndex - 1 : 0}]`,
      );
      if (nextSibling) {
        nextSibling.focus();
      }
      return;
    }
    //* value gelirse sıradaki inputa geç
    setCode({
      ...codes,
      [name]: value,
    });
    const nextSibling = document.querySelector(`input[name=field-${currentFieldIndex + 1}]`);
    if (nextSibling) {
      nextSibling.focus();
    }
  };

  const verifyCode = () => {
    const code = Object.values(codes).join('');
    Authentication.verifyCode({ ticket: location.state.ticket, code })
      .then(() => {
        window.location.href = '/';
      })
      .catch(() => {});
  };

  const resendCode = () => {
    Authentication.resendCode({ ticket: location.state.ticket })
      .then(() => {
        setTime(120);
      })
      .catch((error) => {
        console.log(`error`, error);
      });
  };

  const goBack = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    if (location.state === undefined || !location.state.ticket) window.location = '/';
  }, []);

  return (
    <Page>
      <Content className={styles.content}>
        <Main className={styles.main}>
          <Widget row={6} center>
            <Card className={styles.verify}>
              <Logo className={styles.logo} />
              <Text className={styles['verify-title']}>
                <FormattedMessage id="verify.title" defaultMessage="Email Onay" />
              </Text>
              <Text className={styles['verify-address']}>
                <FormattedMessage
                  id="verify.address"
                  defaultMessage="eMail adresinize gönderdigimiz dogrulama kodunu giriniz"
                />
              </Text>
              <Text className={styles['verify-subtitle']}>
                <FormattedMessage id="verify.subtitle" defaultMessage="Dogrulama kodu" />
              </Text>
              <div
                className={styles['verify-input-wrapper']}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                {['', '', '', '', '', ''].map((item, index) => {
                  return (
                    <Input
                      key={`field-${index}`}
                      maxLength="1"
                      name={`field-${index}`}
                      value={codes[`field${index}`]}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  );
                })}
              </div>
              <div onClick={resendCode}>
                <Text className={styles['verify-resend-button']}>
                  <FormattedMessage id="verify.subtitle" defaultMessage="Tekrar Gönder " />
                </Text>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '32px',
                }}>
                <CountdownCircleTimer
                  trailStrokeWidth={0.04}
                  strokeLinecap="square"
                  size={120}
                  isPlaying
                  strokeWidth={3}
                  duration={time}
                  colors={[['#0f62fe', '#121212']]}>
                  {({ remainingTime }) => remainingTime}
                </CountdownCircleTimer>
              </div>

              <div className={styles.actions}>
                <Button onClick={goBack} className={cx(styles[`textButton-${theme}`], styles.textButton)}>
                  <Text button className={cx(styles[`button-text-${theme}`], styles['button-text'])}>
                    <FormattedMessage id="general.logxin" defaultMessage="Vazgeç" />
                  </Text>
                </Button>
                {time !== 0 && (
                  <Button
                    disabled={Object.values(codes).join('').length < 6}
                    onClick={() => {
                      verifyCode();
                    }}
                    className={styles.button}>
                    <Text button className={styles['button-text']}>
                      <FormattedMessage id="general.lxogin" defaultMessage="Onayla" />
                    </Text>
                  </Button>
                )}
              </div>
            </Card>
          </Widget>
        </Main>
      </Content>
    </Page>
  );
};

export default Verify;
