import { gql } from '@apollo/client';

const balanceSummaryDetails = ({
  selectedVkns,
  currency = '',
  selectedBankAccountGroups = [],
  accountType = 0,
  pageNumber = 1,
  pageSize = 20,
}) => {
  return {
    query: gql`
      query(
        $selectedVkns: [String]
        $currency: String
        $selectedBankAccountGroups: [Guid]
        $accountType: Int
        $pageNumber: Int
        $pageSize: Int
      ) {
        bankAccountQuery {
          balanceSummaryDetailsV2(
            selectedVkns: $selectedVkns
            currency: $currency
            selectedBankAccountGroups: $selectedBankAccountGroups
            accountType: $accountType
            pageNumber: $pageNumber
            pageSize: $pageSize
          ) {
            result {
              balance
              bankAccountNo
              bankAccountTitle
              bankCode
              bankIconLogo
              bankLogo
              bankName
              branchName
              branchNo
              companyName
              companyGroupName
              currency
              currencySymbol
              hasNoData
              currencyName
              iban
              notViewed
              maturityEndDate
              blockedAmount
            }
            totalCount
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccountGroups,
      currency,
      accountType,
      pageNumber,
      pageSize,
    },
  };
};

export default balanceSummaryDetails;

/**
 const response = {
  "data": {
    "bankAccountQuery": {
      "balanceSummaryDetails": [
        {
          "balance": 80000,
          "bankAccountNo": "D-123456786",
          "bankAccountTitle": "Demo Hesap - TRY",
          "bankCode": 10,
          "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
          "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
          "bankName": "Ziraat Bankası A.Ş.",
          "branchName": "İNÖNÜ MAHALLESİ-KÜÇÜKÇEKMECE / İSTANBUL",
          "branchNo": 2507,
          "companyName": "2. Demo Şirket",
          "currency": "TRY",
          "iban": "TR670006200000000123456786",
          "notViewed": false
        },
        {
          "balance": 2200,
          "bankAccountNo": "D-123456783",
          "bankAccountTitle": "3 Demo Hesap - TRY",
          "bankCode": 67,
          "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
          "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
          "bankName": "Yapi ve Kredi Bankasi A.S.",
          "branchName": "İKİTELLİ ŞUBESİ / İSTANBUL",
          "branchNo": 756,
          "companyName": "3. Demo Şirket",
          "currency": "TRY",
          "iban": "TR670006200000000123456783",
          "notViewed": false
        },
        {
          "balance": 15000.3,
          "bankAccountNo": "D-123456789",
          "bankAccountTitle": "Demo Hesap - TRY",
          "bankCode": 62,
          "bankIconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-garanti.svg",
          "bankLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/garanti.svg",
          "bankName": "Garanti Bankası A.Ş.",
          "branchName": "Çarşı Şubesi - BAKIRKÖY / İSTANBUL",
          "branchNo": 349,
          "companyName": "Demo Şirket",
          "currency": "TRY",
          "iban": "TR670006200000000123456789",
          "notViewed": false
        }
      ]
    }
  }
}
 */
