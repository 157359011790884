import axios from 'axios';

import { checkUri } from '../utils';

class Axios {
  constructor({ baseURL }) {
    this.axios = null;

    checkUri(baseURL, 'axios');

    this.init(baseURL);

    return {
      axios: this.axios,
      setHeaders: this.setHeaders,
    };
  }

  init(baseURL) {
    this.axios = this.createAxiosInstance(baseURL);
  }

  createAxiosInstance(baseURL) {
    return axios.create({
      baseURL,
    });
  }

  setHeaders(headers) {
    if (!headers || Object.keys(headers).length === 0) {
      return {
        axios: this.axios,
      };
    }

    this.axios.defaults.headers = {
      ...this.axios.defaults.headers,
      ...headers,
    };

    return this.axios;
  }
}

export default Axios;
