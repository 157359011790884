import { gql } from '@apollo/client';

const defaultCompanies = ({ userDefaultCompany = [] }) => {
  return {
    mutation: gql`
      mutation($userDefaultCompany: [UserDefaultCompanyType]) {
        userMutation {
          updateDefaultCompany(userDefaultCompany: $userDefaultCompany) {
            isSuccess
            errors
          }
        }
      }
    `,
    variables: {
      userDefaultCompany,
    },
  };
};

export default defaultCompanies;
