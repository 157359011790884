import React from 'react';
import { FormattedMessage } from 'react-intl';
/* import cx from 'classnames'; */

import request from 'library/@e5p-request';

import { useAppSelector } from 'src/redux/store';

import Widget from 'components/widget';
import Accordion from 'components/accordion';
import Card from 'components/card';
import Icon from 'components/icon';
/* import Button from 'components/button';
import Text from 'components/text'; */
import Loader from 'components/loader';
import AccountListItem from 'components/account-list-item';
import { Tooltip } from 'react-tippy';
import { useIntl } from 'react-intl';

import Item from './item';
import cx from 'classnames';
import styles from './styles.scss';

const { getBalanceSummary, getBankAccBalancesReport } = request;

const AccountListByCurrency = ({ history }) => {
  const {
    selectGroups: { selectedBankAccountGroups, selectedGroupLoading },
    selectCompanies: { selectedVkn },
    selectCurrency: { currencyListLoading },
    theme: { theme },
  } = useAppSelector((state) => state);
  const intl = useIntl();

  const [account, setAccount] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [accountBalanceLoading, setAcountBalanceLoading] = React.useState(false);
  React.useEffect(() => {
    if (selectedVkn === null) return;
    getBalanceSummary(selectedVkn, selectedBankAccountGroups)
      .then((res) => {
        setAccount(res);
        setLoading(false);
      })
      .catch(({ err }) => {
        setLoading(false);
        console.log(err);
      });
  }, [selectedVkn, selectedBankAccountGroups]);

  const getBankAccountBalancesReportFunc = async () => {
    try {
      setAcountBalanceLoading(true);
      await getBankAccBalancesReport({
        vknList: selectedVkn,
        bankCodes: [],
        bankAccountNos: [],
      });
      setAcountBalanceLoading(false);
    } catch (error) {
      setAcountBalanceLoading(false);
      console.log({ error });
    }
  };

  return (
    <Widget>
      <Card
        loading={loading || currencyListLoading || selectedGroupLoading}
        loader={<Loader />}
        title={
          <FormattedMessage
            id="dashboard.card.title.currencyTypeTotalBalance"
            defaultMessage="Döviz Cinsi Toplam Bakiye"
          />
        }
        rightItem={
          accountBalanceLoading === true ? (
            <Loader />
          ) : (
            <div className={styles['card-container']}>
              <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
                <Icon name="icon-navigation-file-download-24-px" />
                <div
                  onClick={() => {
                    getBankAccountBalancesReportFunc('XLSX');
                  }}>
                  <FormattedMessage id="molecule.accountActivities.accountBalance" defaultMessage="HESAP BAKİYELERİ" />
                </div>
              </div>
              <Tooltip title={intl.messages['info.dashboard.currencyTable']} position="bottom" trigger="mouseenter">
                <Icon name={'icon-info-24-px'} />
              </Tooltip>
            </div>
          )
        }
        //info={'info.dashboard.currencyTable'}
      >
        <div className={styles['account-wrapper']}>
          {account.map((item, index) => {
            return (
              <Accordion
                key={index.toString()}
                content={<AccountListItem {...item} />}
                expantedContent={<Item history={history} currency={item.currency} />}
                icon={<Icon name="icon-navigation-expand-more-24-px" />}
                expantedIcon={<Icon name="icon-navigation-expand-less-24-px" />}
                selectedVkn={selectedVkn}
                selectedBankAccountGroups={selectedBankAccountGroups}
              />
            );
          })}
        </div>
      </Card>
    </Widget>
  );
};

export default AccountListByCurrency;
