import { gql } from '@apollo/client';

const bankQueryBanks = (
  selectedVkns = [],
  currency = 'TRY',
  showTotalItem = false,
  selectedBankAccountGroups,
  date = '',
) => {
  return {
    query: gql`
      query(
        $selectedVkns: [String]
        $currency: String
        $showTotalItem: Boolean
        $selectedBankAccountGroups: [Guid]
        $date: String
      ) {
        bankQuery {
          banks(
            selectedVkns: $selectedVkns
            currency: $currency
            showTotalItem: $showTotalItem
            selectedBankAccountGroups: $selectedBankAccountGroups
            date: $date
          ) {
            bankCode
            bankName
            currencySymbol
            currentCurrency
            iconLogo
            lastTransactionDate
            logo
            totalAccount
            totalBalance
            totalAccountHasData
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccountGroups,
      currency,
      showTotalItem,
      date,
    },
  };
};

export default bankQueryBanks;

/*
const response = {
  "data": {
    "bankQuery": {
      "banks": [
        {
          "bankCode": 0,
          "bankName": "Tüm bankalar",
          "currencySymbol": "₺",
          "currentCurrency": "TRY",
          "iconLogo": "",
          "lastTransactionDate": "2021-02-24T23:58:20.121757",
          "logo": "",
          "totalAccount": 9,
          "totalBalance": 133542.32760845008
        },
        {
          "bankCode": 67,
          "bankName": "Yapi ve Kredi Bankasi A.S.",
          "currencySymbol": "₺",
          "currentCurrency": "TRY",
          "iconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-yapikredi.svg",
          "lastTransactionDate": "2021-02-24T23:58:20.121757",
          "logo": "https://api.ehesapp.com/v2/assets/media/bank-logos/yapikredi.svg",
          "totalAccount": 3,
          "totalBalance": 7562.965116595989
        },
        {
          "bankCode": 10,
          "bankName": "Ziraat Bankası A.Ş.",
          "currencySymbol": "₺",
          "currentCurrency": "TRY",
          "iconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-ziraat.svg",
          "lastTransactionDate": "2021-02-24T23:58:20.05777",
          "logo": "https://api.ehesapp.com/v2/assets/media/bank-logos/ziraat.svg",
          "totalAccount": 3,
          "totalBalance": 103894.03420429787
        },
        {
          "bankCode": 62,
          "bankName": "Garanti Bankası A.Ş.",
          "currencySymbol": "₺",
          "currentCurrency": "TRY",
          "iconLogo": "https://api.ehesapp.com/v2/assets/media/bank-logos/icon-garanti.svg",
          "lastTransactionDate": "2021-02-18T22:13:22.274529",
          "logo": "https://api.ehesapp.com/v2/assets/media/bank-logos/garanti.svg",
          "totalAccount": 3,
          "totalBalance": 22085.328287556233
        }
      ]
    }
  }
};
*/
