import cx from 'classnames';
import styles from './styles.scss';
import { useIntl } from 'react-intl';
import React, { Fragment } from 'react';
import { Input, Button } from 'components';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'src/redux/store';

const GroupFilterCard = ({ filterApplyButton, filterGroupText, searchText }) => {
  const intl = useIntl();
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <Fragment>
      <div className={cx(styles['group-filter-card'], styles[`group-filter-card-${theme}`])}>
        <div style={{ width: '94%', margin: 'auto', paddingTop: '5px' }}>
          <Input
            style={{ border: '1px solid', borderRadius: '10px', marginTop: '-1000px' }}
            className={styles['filter-input-item']}
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={searchText}
            placeholder={intl.formatMessage({
              id: 'molecule.dropdown.selectBank.searchInputPlaceholderCompany',
              defaultMessage: 'Arama',
            })}
            onChange={(e) => filterGroupText(e.target.value)}
          />
        </div>
        <div style={{ margin: '-16px auto', padding: '6px', width: '94%' }}>
          <Button
            className={cx(styles['button-group'])}
            style={{ height: '15px' }}
            buttonStyle={{ width: '100%' }}
            onClick={() => filterApplyButton()}>
            <FormattedMessage id="molecule.accountActivities.applyFilters" defaultMessage="Filtrele" />
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default GroupFilterCard;
