import React from 'react';

import cx from 'classnames';

import request from 'library/@e5p-request';

import Widget from 'components/widget';
import Card from 'components/card';
import Icon from 'components/icon';
import Table from 'components/table';
import Loader from 'components/loader';

import styles from './styles.scss';

import formattedCurrency from 'utils/formatted-currency';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'src/redux/store';
import { useHistory } from 'react-router-dom';
// import data from './data';

const { getBalanceSummaryDetails } = request;

const columns = [
  {
    name: <FormattedMessage id="general.branchName" defaultMessage="Şube Adı" />,
    key: 'branchName',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['branchName'])}>
          <span>{props}</span>
        </div>
      );
    },
  },
  {
    name: <FormattedMessage id="general.companyName" defaultMessage="Şirket Adı" />,
    key: 'companyName',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['companyName'])}>
          <span>{props}</span>
        </div>
      );
    },
  },
  {
    name: <FormattedMessage id="molecules.column.branchAndAccountNo.title" defaultMessage="Şube / Hesap No" />,
    key: 'branchAndAccountNo',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['branchAndAccountNo'])}>
          <span>{props}</span>
        </div>
      );
    },
  },
  {
    name: <FormattedMessage id="general.currencyType" defaultMessage="Doviz Cinsi" />,
    key: 'currency',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['currency'])}>
          <span>{props}</span>
        </div>
      );
    },
  },

  {
    name: <FormattedMessage id="general.blockedAmount" defaultMessage="Bloke Bakiye" />,
    key: 'blockedAmount',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['balance'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <div style={{ textAlign: 'right', paddingRight: '5%' }}>{value ? formattedCurrency(value) : value}</div>;
    },
  },
  {
    name: <FormattedMessage id="general.totalBalance" defaultMessage="Toplam bakiye" />,
    key: 'balance',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['balance'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <div style={{ textAlign: 'right', paddingRight: '5%' }}>{value ? formattedCurrency(value) : value}</div>;
    },
  },
  {
    name: '',
    key: 'action',
    isSort: false,
    render: (props) => {
      return (
        <div className={cx(styles['action'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: () => {
      return (
        <div className={cx(styles['action'])}>
          <span>
            <FormattedMessage id="molecules.column.seeDetails.title" defaultMessage="Detaya Git" />
          </span>
          <Icon name="icon-navigation-east-24-px" className={cx(styles['action-icon'])} />
        </div>
      );
    },
  },
];

const BankBrachList = ({ loading }) => {
  const {
    selectCompanies: { selectedVkn },
    selectGroups: { selectedBankAccountGroups },
    selectBank: { selectedBank },
  } = useAppSelector((state) => state);

  const [branchList, setBranchList] = React.useState([]);
  const [loading2, setLoading2] = React.useState(true);

  const router = useHistory();

  React.useEffect(() => {
    if (selectedVkn === null) return;
    if (!selectedBank?.bankCode) return;
    setLoading2(true);
    getBalanceSummaryDetails({ selectedVkns: selectedVkn, pageNumber: 1, pageSize: 9999, selectedBankAccountGroups })
      .then((res) => {
        const items = res.result.filter((item) => item.bankCode === selectedBank.bankCode);

        const newData = items.map((item) => {
          return {
            ...item,
            branchAndAccountNo: item.branchNo + ' / ' + item.bankAccountNo,
            companyName: item?.companyGroupName ? item?.companyGroupName : item.companyName,
          };
        });
        setBranchList(newData);
        setLoading2(false);
      })
      .catch((error) => {
        console.log('Error: ', error);
        setLoading2(false);
      });
  }, [selectedBank?.bankCode, selectedVkn, selectedBankAccountGroups]);

  return (
    <Widget row={1}>
      <Card
        loading={loading || loading2}
        loader={<Loader />}
        title={
          <FormattedMessage
            values={{
              bankName: <span>{selectedBank?.bankName}</span>,
            }}
            id={`organisms.categories.branchList.title`}
          />
        }>
        <Table
          columns={columns}
          data={branchList}
          className={styles.table}
          onClick={(values) => {
            router.push('/bank-activity', {
              selectedBankAccounts: [values.bankAccountNo],
              selectedCurrencies: [values.currency],
              selectedBanks: [values.bankCode],
            });
          }}
        />
      </Card>
    </Widget>
  );
};

export default React.memo(BankBrachList);
