import React, { useEffect } from 'react';
import authentication from 'utils/authentication';
import qs from 'qs';
export default function LoginWithToken({ history }) {
  useEffect(() => {
    if (!history.location.search) {
      window.location.href = '/';
    }
    const token = qs.parse(history.location.search.split('?')[1]);
    authentication.setToken(token.token);
    window.location.href = '/';
  }, []);
  return <></>;
}
