import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Authentication from 'utils/authentication';

import Page from 'containers/layouts/page';
import Content from 'containers/layouts/content';
import Main from 'containers/layouts/main';
import Loader from 'components/loader';

import Card from 'components/card';
import Widget from 'components/widget';
import Input from 'components/input';
import Button from 'components/button';

//import Loader from 'components/loader';

import Logo from 'components/logo';

//import SelectLanguage from 'containers/elements/@commons/select-language';
//import SelectTheme from 'containers/elements/@commons/select-theme';

import Text from 'components/text';

import styles from './styles.scss';

const ResetPassword = () => {
  const router = useHistory();
  const [password, setPassword] = useState('');

  const [passwordCheck, setPasswordCheck] = useState('');

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(null);

  const queries = new URLSearchParams(window.location.search);

  const code = queries.get('code');

  useEffect(() => {
    if (!code) window.location = '/';
  }, []);

  const onSubmit = () => {
    setError(null);
    if (password == '' || passwordCheck == '') {
      setError({ id: 'login.resetPassword.emptyState', defaultMessage: 'Alanlar boş bırakılamaz.' });

      return;
    }
    if (password !== passwordCheck) {
      setError({ id: 'login.resetPassword.notEqualState', defaultMessage: 'Şifreniz uyuşmuyor' });

      return;
    }
    setLoading(true);
    Authentication.resetPassword({
      code: code,
      password: password,
      confirmPassword: passwordCheck,
    })
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ ...err });
        setLoading(false);
        setError({ id: 'login.resetPassword.enterPasswordx', defaultMessage: err.response.data.message });
      });
  };

  const redirect = () => {
    router.replace('/');
  };

  if (success) {
    return (
      <Page>
        <Content className={styles.content}>
          <Main>
            <Widget className={styles.widget} row={2} center>
              <Card
                loader={<Loader />}
                loading={loading}
                className={styles.cardWrapper}
                contentClassNames={styles.card}
                leftItem={<div />}
                rightItem={<div />}>
                <Logo className={styles.logo} />

                <Text className={styles.cardTitle}>
                  <FormattedMessage id="login.resetPassword" defaultMessage="Şifremi Unuttum" />
                </Text>
                <Text className={styles.cardSubtitle}>
                  <FormattedMessage
                    id="login.resetPassword.Subtitle"
                    defaultMessage="Şifreniz başarıyla değiştirilmiştir."
                  />
                </Text>
                <div className={styles.buttonWrapperCenter}>
                  <Button onClick={redirect} className={styles.submitButton}>
                    <Text button className={styles['button-text']}>
                      <FormattedMessage
                        id="login.resetPassword.submitButton"
                        defaultMessage="Anasayfaya dönmek için tıklayınız."
                      />
                    </Text>
                  </Button>
                </div>
              </Card>
            </Widget>
          </Main>
        </Content>
      </Page>
    );
  }

  return (
    <Page>
      <Content className={styles.content}>
        <Main>
          <Widget className={styles.widget} row={3} center>
            <Card contentClassNames={styles.card} leftItem={<div />} rightItem={<div />}>
              <Logo className={styles.logo} />
              {error && (
                <Text className={styles.error}>
                  <FormattedMessage id={error.id} defaultMessage={error.defaultMessage} />
                </Text>
              )}
              <Text className={styles.cardTitle}>
                <FormattedMessage id="login.forgotPassword" defaultMessage="Şifremi Unuttum" />
              </Text>
              <Text className={styles.cardSubtitle}>
                <FormattedMessage
                  id="login.forgotPassword.Subtitle"
                  defaultMessage="Parolanızı sıfırlama talimatlarını içeren bir e‑posta göndereceğiz."
                />
              </Text>
              <FormattedMessage id="login.resetPassword.enterPassword" defaultMessage="Lütfen şifrenizi belirleyiniz.">
                {(msg) => {
                  return (
                    <Input
                      value={password}
                      placeholder={msg}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  );
                }}
              </FormattedMessage>
              <FormattedMessage
                id="login.resetPassword.reEnterPassword"
                defaultMessage="Lütfen belirlediğiniz şifreyi giriniz.">
                {(msg) => {
                  return (
                    <Input
                      value={passwordCheck}
                      placeholder={msg}
                      onChange={(e) => {
                        setPasswordCheck(e.target.value);
                      }}
                    />
                  );
                }}
              </FormattedMessage>
              <div className={styles.buttonWrapperCenter}>
                <Button onClick={onSubmit} className={styles.submitButton}>
                  <Text button className={styles['button-text']}>
                    <FormattedMessage id="login.resetPassword.changePassButton" defaultMessage="Şifreyi Yenile" />
                  </Text>
                </Button>
              </div>
            </Card>
          </Widget>
        </Main>
      </Content>
    </Page>
  );
};

export default ResetPassword;
