import React from 'react';

import cx from 'classnames';

import styles from './styles.scss';

const BankSymbol = ({ currency, symbol }) => {
  return (
    <div
      className={cx(`${currency}-bg`, styles['currency'], {
        [styles['symbol-smallFont']]: symbol?.length > 2,
        [styles['symbol-mediumFont']]: symbol?.length === 2,
      })}>
      {symbol}
    </div>
  );
};

export default BankSymbol;
