import React from 'react';
import cx from 'classnames';

import styles from './styles.scss';

const Accordion = ({
  content,
  expantedContent,
  className,
  contentClass,
  expantedContentClass,
  icon,
  expantedIcon,
  onClick,
  selectedVkn,
  selectedBankAccountGroups,
}) => {
  React.useEffect(() => {
    if (selectedVkn === null) return;
    setOpen(false);
  }, [selectedVkn, selectedBankAccountGroups]);

  const [open, setOpen] = React.useState(false);

  const openAccordion = (e) => {
    if (e) e.stopPropagation();
    setOpen(!open);
  };

  const onRowClick = () => {
    if (onClick) {
      onClick();
    }
    openAccordion();
  };

  return (
    <div className={cx(styles['accordion'], className)}>
      <div className={cx(styles['accordion-row'], contentClass)} onClick={onRowClick}>
        <div className={cx(styles['accordion-content'])}>{content}</div>
        <div className={styles['accordion-row-arrow']} onClick={openAccordion}>
          {open ? (
            expantedIcon ? (
              expantedIcon
            ) : (
              <i className="icon-icon-navigation-expand-less-24-px" />
            )
          ) : icon ? (
            icon
          ) : (
            <i className="icon-icon-navigation-expand-more-24-px" />
          )}
        </div>
      </div>
      <div
        className={cx(styles['accordion-expanted-content'], expantedContentClass, {
          [styles['accordion-expanted-content-opened']]: open,
        })}>
        {open && expantedContent}
      </div>
    </div>
  );
};

export default Accordion;
