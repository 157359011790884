import { createAsyncThunk } from '@reduxjs/toolkit';
import request from 'library/@e5p-request';

const { currencies } = request;

export const initializeCurrencies = createAsyncThunk('initializeCurrencies', async (params) => {
  const { selectedVkn, selectedBankAccountGroups } = params;

  const currencyList = await currencies(selectedVkn, selectedBankAccountGroups);

  return {
    currencyList,
  };
});

const selectCurrencyActions = {
  initializeCurrencies,
};

export default selectCurrencyActions;
