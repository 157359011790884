import React, { Fragment, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import Table from 'components/table';
import Icon from 'components/icon';
import Loader from 'components/loader';
import { Select, SelectItem } from 'components';

import request from 'library/@e5p-request';

import formattedCurrency from 'utils/formatted-currency';

import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const { getBalanceSummaryDetails } = request;
const pageSizesArray = [20, 50, 100, 250, 500, 1000, 99999];

const columns = [
  {
    name: <FormattedMessage id="organisms.categories.bank.title" defaultMessage="Banka Adı" />,
    key: 'bankName',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['bankName'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return value ? <span className={cx(styles['bankName-value'])}>{value}</span> : value;
    },
  },
  {
    name: <FormattedMessage id="molecules.column.companyName.title" defaultMessage="Şirket Adı" />,
    key: 'companyName',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['branchName'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <span className={cx(styles['branchName-value'])}>{value}</span>;
    },
  },
  {
    name: <FormattedMessage id="molecules.column.branchAndAccountNo.title" defaultMessage="Şube / Hesap No" />,
    key: 'branchAndAccountNo',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['branchNo'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <span className={cx(styles['branchNo-value'])}>{value}</span>;
    },
  },
  {
    name: <FormattedMessage id="molecules.column.bankAccount.title" defaultMessage="Hesap Adı" />,
    key: 'bankAccountTitle',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['bankAccount'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <span className={cx(styles['branchNo-value'])}>{value}</span>;
    },
  },
  {
    name: <FormattedMessage id="molecules.column.amount.title" defaultMessage="Tutar" />,
    key: 'balance',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['balance'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: (value) => {
      return <span className={cx(styles['balance-value'])}>{formattedCurrency(value ? value : 0)}</span>;
    },
  },
  {
    name: '',
    key: 'action',
    isSort: true,
    render: (props) => {
      return (
        <div className={cx(styles['action'])}>
          <span>{props}</span>
        </div>
      );
    },
    valueRender: () => {
      return (
        <div className={cx(styles['action'])}>
          <span>
            <FormattedMessage id="molecules.column.seeDetails.title" defaultMessage="Detaya Git" />
          </span>
          <Icon name="icon-navigation-east-24-px" className={cx(styles['action-icon'])} />
        </div>
      );
    },
  },
];

const Item = ({ currency, history }) => {
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [data, setData] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePageSize = (size) => {
    setPageNumber(1);
    setPageSize(size);
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedVkn === null) return;
    getBalanceSummaryDetails({ selectedVkns: selectedVkn, currency, selectedBankAccountGroups, pageNumber, pageSize })
      .then((res) => {
        const newData = res.result.map((item) => {
          return {
            ...item,
            branchAndAccountNo: item.branchNo + ' / ' + item.bankAccountNo,
            companyName: item?.companyGroupName ? item?.companyGroupName : item.companyName,
          };
        });
        const hasNoData = newData.filter((i) => i.hasNoData);
        const hasData = newData.filter((i) => !i.hasNoData);
        setData([...hasData, ...hasNoData]);
        setTotalCount(res.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
      });
  }, [selectedVkn, selectedBankAccountGroups, pageNumber, pageSize]);

  if (loading) return <Loader />;
  return (
    <Fragment>
      <Table
        columns={columns}
        data={data}
        onClick={(values) => {
          history.push({
            pathname: '/bank-activity',
            state: {
              selectedBanks: [values.bankCode],
              selectedBankAccounts: [values.bankAccountNo],
              selectedCurrencies: [values.currency],
            },
          });
        }}
      />
      <div className={cx(styles['table-body'], styles[`table-body-${theme}`])}>
        {Math.ceil(totalCount / pageSize) > 0 && (
          <div className={cx(styles['table-footer'], styles[`table-footer-${theme}`])}>
            <div className={cx(styles['table-footer-page-size'], styles[`table-footer-page-size-${theme}`])}>
              <FormattedMessage id="molecule.activitiesTable.listItem" defaultMessage="Sayfa Başına Öğe" />{' '}
              <Select
                placeholder={<b>{pageSize < 99999 ? pageSize : 'Max.'}</b>}
                selected={pageSize}
                className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}>
                {pageSizesArray.map((item) => (
                  <SelectItem
                    key={`filters-currency-item-${item}`}
                    className={styles['filters-select-item']}
                    onClick={() => handleChangePageSize(item)}>
                    {item < 99999 ? item : 'Max.'}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className={cx(styles['table-footer-page-info'])}>
              <span>
                {<FormattedMessage id="molecules.revenuExpensePanel.totalLanguageId" defaultMessage="Toplam" />} :{' '}
                {Math.ceil(totalCount / pageSize)}{' '}
                <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />{' '}
              </span>
            </div>
            <div className={styles['table-footer-page-changer']}>
              {pageNumber}. <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />
              <div className={styles['table-footer-page-changer-wrapper']}>
                <div
                  className={cx(styles['table-footer-page-changer-button'], {
                    [styles['table-footer-page-changer-button-disabled']]: pageNumber === 1,
                  })}
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}>
                  <Icon name="icon-navigation-chevron-left-24-px" />
                </div>
                <div
                  className={cx(styles['table-footer-page-changer-button'], {
                    [styles['table-footer-page-changer-button-disabled']]:
                      pageNumber === Math.ceil(totalCount / pageSize),
                  })}
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}>
                  <Icon name="icon-navigation-chevron-right-24-px" />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Item;
