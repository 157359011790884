import React from 'react';
import cx from 'classnames';

import styles from '../styles.scss';

const Select = ({
  theme,
  value,
  placeholder,
  removeItem,
  multiSelect,
  multiSelectText = 'seçim',
  removedDisabled,
  isShowOptions,
  className,
  iconWrapperClassname,
  transparent,
  hideDefaultClearButton = false,
}) => {
  return (
    <div
      className={cx(
        styles.select,
        styles[`select-${theme}`],
        {
          [styles['select-transparent']]: transparent,
          [styles[`select-transparent-${theme}`]]: transparent,
        },
        className,
      )}>
      <span
        className={cx(styles.placeholder, {
          [styles[`placeholder-hidden`]]: (!multiSelect && value) || (multiSelect && value.length > 0),
        })}>
        {placeholder}
      </span>
      {!multiSelect && value && <span className={styles.value}>{value}</span>}
      {multiSelect && value.length > 0 && (
        <div className={cx(styles.values, styles[`values-${theme}`])}>
          <div className={styles['values-item-2']}>
            {value.length === 1 && value[0]} {value.length > 1 && `${value.length} ${multiSelectText}`}
          </div>
        </div>
      )}
      {!hideDefaultClearButton && (
        <div
          className={cx(styles['icon-wrapper'], styles[`icon-wrapper-${theme}`], iconWrapperClassname)}
          onClick={removeItem}>
          {!removedDisabled && (
            <i
              className={
                (!multiSelect && value) || (multiSelect && value.length > 0)
                  ? 'icon-icon-navigation-close-24-px-1'
                  : 'icon-icon-navigation-expand-more-24-px'
              }
            />
          )}

          {removedDisabled && (
            <i
              className={
                !isShowOptions ? 'icon-icon-navigation-expand-more-24-px' : 'icon-icon-navigation-expand-less-24-px'
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Select;

/**
SATIR 18

{value.map((item, index) => {
  return (
    <div key={index.toString()} className={styles['values-item']}>
      <span className={styles['values-item-text']}>{item}</span>
      <i className="icon-icon-navigation-close-24-px-1" />
    </div>
  );
})}

 */
