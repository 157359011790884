import React from 'react';

import CurrencyList from 'containers/elements/@commons/currency-list';
import SelectCompany from 'containers/elements/@commons/select-company';

import styles from './styles.scss';

const ContentHeader = () => {
  return (
    <div className={styles.wrapper}>
      <SelectCompany />
      <CurrencyList />
    </div>
  );
};

export default ContentHeader;
