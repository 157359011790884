import Services from 'library/@e5p-services';

import { storageKeys } from './config/constants';
import config from './config';

import * as Queries from './queries';

import fileDownloader from './utils/fileDownloader';
import companyFormatter from './utils/companyFormatter';

import totalBalanceWithBanksFormatter from './utils/totalBalanceWithBanksFormatter';
import { local } from 'library/@e5p-request/helpers/index';
// import balancesFormatByCurrency from './utils/balancesFormatByCurrency';

const userToken = local.getItem(storageKeys.token);

/* Create axios and client instance from @e5p service module */
export const { axios, staticAxios, client, setHeaders } = new Services({
  baseURL: config?.api['REST-URL'] || null,
  staticURL: config?.api['REST-URL-STATIC'] || null,
  uri: config?.api['GRAPHQL-URI'] || null,
  headers: {
    Authorization: userToken ? `Bearer ${userToken}` : undefined,
  },
});

/** End */
export default {
  setHeaders,
  login: (params) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post('/account/login', params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  resendCode: (params) => axios.post(`/Account/ResendCode?ticket=${params.ticket}`, params),
  verifyCode: (params) => axios.post(`/Account/VerifyCode?code=${params.code}&ticket=${params.ticket}`, params),

  getBankAccBalancesReport: (data) => {
    const language = local.getItem('@ep-language') === 'tr' ? 'tr-TR' : 'en-US';
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `/Report/GetBankAccountBalances`,
          { ...data, language },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        resolve(response);
      } catch (error) {
        const status = error.response?.status;
        if (status === 302) {
          const nameArray = error.response.data.downloadLink.split('/');
          const fileName = nameArray[nameArray.length - 1];
          axios
            .get(error.response.data.downloadLink, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
              responseType: 'blob',
            })
            .then((res) => {
              fileDownloader(res.data, fileName, 'Ehesapp - Banka Bakiyeleri Raporu');
              resolve({ ...error.response.data });
            })
            .catch((err) => {
              console.log('err-getBankAccBalancesReport', err);
            });
        } else {
          reject(error);
        }
      }
    });
  },

  getBankBalancesReport: (data) => {
    const language = local.getItem('@ep-language') === 'tr' ? 'tr-TR' : 'en-US';
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `/Report/GetBankBalances`,
          { ...data, language },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        resolve(response);
      } catch (error) {
        const status = error.response?.status;
        if (status === 302) {
          const nameArray = error.response.data.downloadLink.split('/');
          const fileName = nameArray[nameArray.length - 1];
          axios
            .get(error.response.data.downloadLink, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
              responseType: 'blob',
            })
            .then((res) => {
              fileDownloader(res.data, fileName, 'Ehesapp - Banka Bakiyeleri Raporu');
              resolve({ ...error.response.data });
            })
            .catch((err) => {
              console.log('err-getBankBalancesReport', err);
            });
        } else {
          reject(error);
        }
      }
    });
  },

  getBankReceipt: (bankCode, accountNo, transactionId) => {
    const language = local.getItem('@ep-language') === 'tr' ? 'tr-TR' : 'en-US';
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `/BankAccountActivity/GetReceipt`,
          { bankCode, accountNo, transactionId, language },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        resolve(response);
      } catch (error) {
        const status = error.response?.status;
        if (status === 302) {
          const nameArray = error.response.data.downloadLink.split('/');
          const fileName = nameArray[nameArray.length - 1];
          axios
            .get(error.response.data.downloadLink, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
              responseType: 'blob',
            })
            .then((res) => {
              fileDownloader(res.data, fileName, 'Ehesapp-Dekont');
              resolve({ ...error.response.data });
            })
            .catch((err) => {
              console.log('err-getBankReceipt', err);
            });
        } else {
          reject(error);
        }
      }
    });
  },

  getBankReceipts: (data) => {
    const language = local.getItem('@ep-language') === 'tr' ? 'tr-TR' : 'en-US';
    const datasWithLanguage = data.map((d) => {
      return { ...d, language };
    });
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`/BankAccountActivity/GetReceipts`, datasWithLanguage, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });
        resolve(response);
      } catch (error) {
        const status = error.response?.status;
        if (status === 302) {
          const nameArray = error.response.data.downloadLink.split('/');
          const fileName = nameArray[nameArray.length - 1];
          axios
            .get(error.response.data.downloadLink, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
              responseType: 'blob',
            })
            .then((res) => {
              fileDownloader(res.data, fileName, 'Ehesapp-Dekont');
              resolve({ ...error.response.data });
            })
            .catch((err) => {
              console.log('err-getBankReceipts', err);
            });
        } else {
          reject(error);
        }
      }
    });
  },
  userLogout: () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`/Account/Logout`, {
          headers: {},
        });
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  getBankReceiptHTML: (params) => {
    const language = local.getItem('@ep-language') === 'tr' ? 'tr-TR' : 'en-US';
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `/BankAccountActivity/GetReceipt`,
          { ...params, language },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        resolve(response);
      } catch (error) {
        console.log({ error });
        reject(error);
      }
    });
  },

  sendReceiptToMail: (params) => {
    const language = local.getItem('@ep-language') === 'tr' ? 'tr-TR' : 'en-US';
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `/BankAccountActivity/SendReceipt`,
          { ...params, language },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        resolve(response);
      } catch (error) {
        console.log({ error });
        reject(error);
      }
    });
  },
  getExportHistory: (data) => {
    const language = local.getItem('@ep-language') === 'tr' ? 'tr-TR' : 'en-US';
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `/BankAccountActivity/ExportHistory`,
          { ...data, language },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );
        resolve(response);
      } catch (error) {
        const status = error.response?.status;
        if (status === 302) {
          const nameArray = error.response.data.downloadLink.split('/');
          const fileName = nameArray[nameArray.length - 1];
          axios
            .get(`${error.response.data.downloadLink}?getAllData=true`, {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
              responseType: 'blob',
            })
            .then((res) => {
              fileDownloader(res.data, fileName, 'Ehesapp- Hesap Hareketleri');
              resolve({ ...error.response.data });
            })
            .catch((err) => {
              console.log('err-getExportHistory', err);
            });
        } else {
          reject(error);
        }
      }
    });
  },
  connectToPlaid: (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(
          `/CompanyAccount/OAuth?oAuthType=${data.oAuthType}&vkn=${data.vkn}&uid=${data.uid}&onlyRefresh=${data.onlyRefreshBool}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );

        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },

  // eslint-disable-next-line prettier/prettier
  register: () => {},
  forgotPassword: (params) => axios.post(`/Account/ForgotPassword`, params),
  checkSso: () => axios.post(`/Account/CheckSSO`),
  resetPassword: (params) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`/Account/ResetPassword`, params);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  banks: (currency = 'TRY', showTotalItem = false) => client.query(Queries.banks(currency, showTotalItem)),

  currencies: (selectedVkns, selectedBankAccountGroups) =>
    client
      .query(Queries.currencies(selectedVkns, selectedBankAccountGroups))
      .then((res) => res.data.currencyQuery.currencies),
  currencyRates: (baseCurrency, targetCurrencies) =>
    client
      .query(Queries.currencies(baseCurrency, targetCurrencies))
      .then((res) => res.data.currencyQuery.currencyRates),

  getCurrenciesOptions: (selectedVkns, selectedBankAccountGroups) =>
    client.query(Queries.currencies(selectedVkns, selectedBankAccountGroups)).then((res) => {
      return res.data.currencyQuery.currencies.reduce((total, item) => {
        total.push({
          label: item.summary,
          value: item.summary,
        });
        return total;
      }, []);
    }),

  getBanks: (selectedVkns = [], currency = 'TRY', showTotalItem = false, selectedBankAccountGroups, date = '') =>
    client
      .query(Queries.banks(selectedVkns, currency, showTotalItem, selectedBankAccountGroups, date))
      .then((res) => res.data.bankQuery.banks),

  getBanksOptions: (selectedVkns = [], currency = 'TRY', showTotalItem = false, selectedBankAccountGroups = []) => {
    return client.query(Queries.banks(selectedVkns, currency, showTotalItem, selectedBankAccountGroups)).then((res) => {
      return res.data.bankQuery.banks.reduce((total, item) => {
        total.push({
          label: item.bankName,
          value: item.bankCode,
        });

        total = total.sort((a, b) => a.label.localeCompare(b.label));

        return total;
      }, []);
    });
  },

  getCurrencies: (selectedVkns, selectedBankAccountGroups) =>
    client
      .query(Queries.currencies(selectedVkns, selectedBankAccountGroups))
      .then((res) => res.data.currencyQuery.currencies),

  getCurrencyRates: (baseCurrency, targetCurrencies) =>
    client.query(Queries.currencyRates(baseCurrency, targetCurrencies)).then((res) => {
      return res.data.currencyQuery.currencyRates;
    }),

  getTotalBalanceByCurrency: (selectedVkns = [], currency = 'TRY') => {
    return client
      .query(Queries.banks(selectedVkns, currency, true))
      .then((res) => res.data.bankQuery.banks[0].totalBalance);
  },

  getBankBalancesByCurrency: (selectedVkns = []) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await client.query(Queries.currencies(selectedVkns));
        const { currencies } = response.data.currencyQuery;

        const allPromises = currencies.map((item) => {
          return client.query(Queries.banks(selectedVkns, item.summary, false)).then((res) => {
            const obj = {
              currency: item.summary,
              currencyName: item.name,
              currencySymbol: item.symbol,
              banks: res.data.bankQuery.banks,
            };
            return obj;
          });
        });

        const result = await Promise.all(allPromises);
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  },

  getBudgetSummaryDetails: (
    selectedVkns = [],
    dateRange = Queries.BUDGET_QUERY_RANGE_ENUM.TODAY,
    selectedBankAccountGroups,
  ) =>
    client
      .query(Queries.budgetSummaryDetailsQuery(selectedVkns, dateRange, selectedBankAccountGroups))
      .then((res) => res.data.budgetQuery.summaryDetails),

  getBudgetDailyBalances: (selectedVkns = [], selectedBankAccountGroups) =>
    client
      .query(Queries.budgetDailyBalancesQuery(selectedVkns, selectedBankAccountGroups))
      .then((res) => res.data.budgetQuery.dailyBalances),

  getBankAccountsByFilter: (params) => {
    return client.query(Queries.accounts(params)).then((res) => res.data.bankAccountQuery.accounts);
  },

  getCompaniesByGroup: () => {
    return client
      .query(Queries.groups())
      .then((res) => {
        const response = res.data.companyQuery;
        return companyFormatter(response);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  getCompanies: () => {
    return client
      .query(Queries.companies())
      .then((res) => {
        const response = res.data.companyQuery;
        return response?.companies;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  getCompanyAccount: ({ selectedVkns = [], selectedBanks = [], pageNumber = 1, pageSize = 20, status }) => {
    return client
      .query(Queries.companyAccount({ selectedVkns, selectedBanks, pageNumber, pageSize, status }))
      .then((res) => {
        const response = res.data.companyAccountQuery;
        return response?.companyAccountPaging;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },

  getAccountActivities: (data) => {
    const {
      selectedVkns,
      selectedBankAccounts,
      startDate,
      endDate,
      pageNumber,
      pageSize,
      selectedBanks,
      description,
    } = data;
    return client
      .query(
        Queries.accountActivities(
          selectedVkns,
          selectedBankAccounts,
          startDate,
          endDate,
          pageNumber,
          pageSize,
          selectedBanks,
          description,
        ),
      )
      .then((res) => {
        return res.data.bankAccountActivityQuery.activityHistory;
      })
      .catch((err) => {
        return err;
      });
  },

  getAccountActivitiesDetail: (data) => {
    const {
      selectedVkns,
      selectedBankAccounts,
      endDate,
      startDate,
      description,
      minAmount,
      maxAmount,
      selectedBanks,
      selectedBranches,
      pageNumber,
      pageSize,
      useAbsoluteValues,
      incomeActivities,
      outgoingActivities,
    } = data;
    return client
      .query(
        Queries.accountActivitiesDetail({
          selectedVkns,
          selectedBankAccounts,
          endDate,
          startDate,
          description,
          minAmount,
          maxAmount,
          pageNumber,
          pageSize,
          selectedBanks,
          selectedBranches,
          useAbsoluteValues,
          incomeActivities,
          outgoingActivities,
        }),
      )
      .then((res) => {
        return res.data.bankAccountActivityQuery.activityHistory;
      })
      .catch((err) => {
        return { error: 500, ...err };
      });
  },

  getNotifications: (params) => {
    return client.query(Queries.notification(params)).then((res) => res.data.notificationQuery.history);
  },

  getBankAccountGroups: (selectedVkns = []) => {
    return client.query(Queries.bankAccountGroups(selectedVkns)).then((res) => res.data.bankAccountGroupQuery.list);
  },

  balanceSummary: (selectedVkns) =>
    client
      .query(Queries.balanceSummary(selectedVkns))
      .then((res) => res.data.bankAccountQuery.balanceSummary.balanceSummaries),
  getBalanceSummary: (selectedVkns, selectedBankAccountGroups, selectedBanks = []) =>
    client
      .query(Queries.balanceSummary(selectedVkns, selectedBanks, selectedBankAccountGroups))
      .then((res) => res.data.bankAccountQuery.balanceSummary.balanceSummaries),
  getBalanceSummaryDetails: ({ selectedVkns, currency, selectedBankAccountGroups, pageNumber, pageSize }) =>
    client
      .query(Queries.balanceSummaryDetails({ selectedVkns, currency, selectedBankAccountGroups, pageNumber, pageSize }))
      .then((res) => res.data.bankAccountQuery.balanceSummaryDetailsV2),
  getBalanceList: ({ selectedVkns, selectedBankAccountGroups, selectedBanks = [] }) =>
    client
      .query(Queries.balanceSummary(selectedVkns, selectedBanks, selectedBankAccountGroups))
      .then((res) => res.data.bankAccountQuery.balanceSummary.balanceSummaries),
  getBankBalances: ({ selectedVkn, currency, selectedBankAccountGroups, accountType, pageNumber, pageSize }) => {
    return client
      .query(
        Queries.balanceSummaryDetails({
          selectedVkns: selectedVkn,
          currency,
          selectedBankAccountGroups,
          accountType,
          pageNumber,
          pageSize,
        }),
      )
      .then((res) => totalBalanceWithBanksFormatter(res.data.bankAccountQuery.balanceSummaryDetailsV2));
  },
  getBankBalancesByCurrency2: (selectedVkns = [], selectedBankAccountGroups, accountType = 0) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await client
          .query(Queries.balanceSummaryForTangible(selectedVkns, selectedBankAccountGroups, accountType))
          .then((res) => res.data.bankAccountQuery.balanceSummary.balanceSummaries);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  },
  getAccountInformations: ({
    selectedVkns,
    selectedBankAccountGroups,
    onlyHasTransaction = false,
    startDate,
    endDate,
  }) => {
    return client
      .query(
        Queries.accountInformations(
          selectedVkns,
          '',
          selectedBankAccountGroups,
          onlyHasTransaction,
          startDate,
          endDate,
        ),
      )
      .then((res) => res.data.bankAccountQuery.accountInformations);
  },
  getBankDetail: (selectedVkns, selectedBanks, selectedBankAccountGroups) => {
    return client
      .query(Queries.balanceSummary(selectedVkns, selectedBanks, selectedBankAccountGroups))
      .then((res) => res.data.bankAccountQuery.balanceSummary);
  },
  getAccountsByPlaidUid: (selectedBank, selectedVkn, uid) => {
    return client
      .query(Queries.bankAccounts(selectedBank, selectedVkn, uid))
      .then((res) => res.data.bankAccountQuery.bankAccountByUid);
  },
};
