import cx from 'classnames';
import styles from './styles.scss';
import { Select } from 'components';
import request from 'library/@e5p-request';
import {
  onFilterApply,
  setBankAccountGroupList,
  clearSelectBankAccountGroup,
} from 'src/redux/reducers/select-groups/select-groups-reducer';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import React, { useRef, useState, useEffect, memo } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { BanAccountSelectLabel, BankAccountSelectItem, GroupFilterCard } from './components';

const { getBankAccountGroups } = request;

const SelectGroup = () => {
  // other hooks
  const selectContainerRef = useRef();

  // redux hooks
  const dispatch = useAppDispatch();
  const {
    selectGroups: { selectedGroup, selectedBankAccountGroups, selectedBankAccountGroupList },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  // useState hooks
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [tempGroup, setTempGroup] = useState([]);
  const [searchTextbox, setSearchTextbox] = useState('');
  const [isShowOptions, setShowOptions] = useState(false);

  // functions
  const getGroups = () => {
    if (selectedVkn === null) return;

    setLoading(true);
    getBankAccountGroups(selectedVkn)
      .then((res) => {
        setLoading(false);
        const formattedData = [...res];

        formattedData.unshift({
          name: 'Tüm Gruplar',
          __typename: 'BankAccountGroupType',
        });

        setGroupList(formattedData);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const clearSelectedItems = () => {
    setTempGroup([
      {
        name: 'Tüm Gruplar',
        __typename: 'BankAccountGroupType',
      },
    ]);
    dispatch(clearSelectBankAccountGroup());
  };

  const handleClose = () => {
    if (selectContainerRef.current && isOpen) {
      selectContainerRef.current.style.zIndex = 0;
      setIsOpen(false);
      setTempGroup(selectedBankAccountGroupList);
    }
  };

  const handleClick = () => {
    if (selectContainerRef.current && isOpen) {
      handleClose();
    }

    if (selectContainerRef.current && !isOpen) {
      selectContainerRef.current.style.zIndex = 9999;
      setIsOpen(true);
    }
  };

  const handleGroup = (group) => {
    if (!group.guid) return setTempGroup([group]);

    const result = tempGroup.find((item) => item.guid === group.guid);

    if (!result) {
      const tempArray = tempGroup.filter((item) => item.guid);
      return setTempGroup([...tempArray, group]);
    }

    const withoutAllArray = tempGroup.filter((item) => item.guid);
    const tempArray = withoutAllArray.filter((item) => item.guid !== group.guid);
    return setTempGroup(tempArray);
  };

  const filterApply = () => dispatch(onFilterApply(tempGroup));

  useOnClickOutside(selectContainerRef, () => handleClose());

  // useEffect hooks

  /** WHEN OUTSIDE CLICK AUTO CLOSED */
  useEffect(() => {
    if (isShowOptions) {
      document.addEventListener('click', () => {
        setShowOptions(null);
      });
      document.removeEventListener('click', () => {});
    }
  }, [isShowOptions]);
  /** FINISH WHEN OUTSIDE CLICK AUTO CLOSED */

  useEffect(() => {
    if (selectedVkn === null) return;

    getGroups();
  }, [selectedVkn]);

  useEffect(() => {
    const isReset = groupList.length === 0;
    dispatch(setBankAccountGroupList({ data: groupList, isReset }));
  }, [groupList]);

  useEffect(() => {
    setTempGroup(selectedBankAccountGroupList);
  }, [selectedBankAccountGroupList]);

  // returns

  if (loading) {
    return null;
  }

  if (groupList.length === 0) {
    return <></>;
  }

  return (
    <div ref={selectContainerRef} onClick={handleClick} className={cx(styles.wrapper, styles[`wrapper-${theme}`])}>
      <Select
        portalBlur
        multiple
        placeholder={<BanAccountSelectLabel />}
        placeholderClassName={styles['sidebar-select-bank-account-group-placeholder']}
        selected={selectedBankAccountGroups}
        className={cx(
          styles['sidebar-select-bank-account-group'],
          styles[`sidebar-select-bank-account-group-${theme}`],
        )}
        selectClassName={styles['sidebar-select-bank-account-group-container']}
        clearSelectedItems={clearSelectedItems}>
        <GroupFilterCard
          filterApplyButton={() => filterApply()}
          filterGroupText={(text) => setSearchTextbox(text)}
          searchText={searchTextbox}
        />
        <BankAccountSelectItem
          selectedBankAccountGroups={selectedBankAccountGroups}
          onSelect={(val) => handleGroup(val)}
          items={selectedGroup}
          searchText={searchTextbox}
          tempGroups={tempGroup}
        />
      </Select>
    </div>
  );
};

export default memo(SelectGroup);
