import { gql } from '@apollo/client';

const companiesQuery = () => {
  return {
    query: gql`
      query {
        companyQuery {
          companies {
            logo
            name
            showInMenu
            vkn
            defaultCompany
          }
        }
      }
    `,
    variables: {},
  };
};

export default companiesQuery;
