import React from 'react';
import { FormattedMessage } from 'react-intl';
import request from 'library/@e5p-request';

import Card from 'components/card';
import BankButton from 'components/bank-button';
import Loader from 'components/loader';

import { useAppDispatch, useAppSelector } from 'src/redux/store';
import {
  setSelectedBank,
  setBankCode,
  setSelectedBankLoading,
} from 'src/redux/reducers/select-bank/select-bank-reducer';

import styles from './styles.scss';

const { getBanks, getBankDetail } = request;

const BankList = ({ changeTempLoading }) => {
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    selectCurrency: { defaultCurrencyItem },
    selectBank: { bankCode, selectedBank },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const [banks, setBanks] = React.useState([]);
  const [tempBank, setTempBank] = React.useState(null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (banks.length > 0) {
      dispatch(setBankCode(banks[0].bankCode));
    }
  }, [banks]);

  React.useEffect(() => {
    if (bankCode && banks.length > 0) {
      let index = banks.findIndex((i) => i.bankCode === parseInt(bankCode));
      index = index > -1 ? index : 0;

      setTempBank(banks[index]);
    }
  }, [bankCode, banks]);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    if (!selectedVkn) return;
    setLoading(true);
    changeTempLoading(true);
    getBanks(selectedVkn, defaultCurrencyItem?.summary, false, selectedBankAccountGroups)
      .then((res) => {
        setBanks(res);
        setLoading(false);
        changeTempLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        changeTempLoading(false);
      });
  }, [selectedVkn, selectedBankAccountGroups, defaultCurrencyItem?.summary]);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    if (tempBank && selectedVkn) {
      getBankDetail(selectedVkn, [tempBank.bankCode], selectedBankAccountGroups).then((res) => {
        const response = {
          bankCode: tempBank.bankCode,
          bankName: tempBank.bankName,
          iconLogo: tempBank.iconLogo,
          totalAccount: tempBank.totalAccount,
          totalBalance: tempBank.totalBalance,
          currencies: res.balanceSummaries.map((item) => ({
            currency: item.currency,
            currencySymbol: item.currencySymbol,
          })),
          lastBankTransactionDate: res.lastBankTransactionDate,
          reportMessage: res.reportMessage,
        };
        dispatch(setSelectedBank(response));
      });
    }
  }, [tempBank, selectedBankAccountGroups]);

  return (
    <Card
      title={<FormattedMessage id="sidebar.banks" defaultMessage="Bankalar" />}
      loading={loading}
      loader={<Loader />}
      info="info.banks.banksArea">
      <div className={styles.wrapper}>
        <div className={styles['list-wrapper']}>
          {selectedBank &&
            banks.map((bank, index) => {
              const isActive = selectedBank.bankCode === bank.bankCode;
              return (
                <BankButton
                  key={index.toString()}
                  logo={bank.iconLogo}
                  onClick={() => {
                    setTempBank(bank);
                    dispatch(setSelectedBankLoading(true));
                  }}
                  active={isActive}
                  bankName={bank.bankName}
                />
              );
            })}
        </div>
      </div>
    </Card>
  );
};

export default React.memo(BankList);
