import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Icon = ({ name, className, style, ...props }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <span className={cx(`icon-${name}`, className, styles.icon, styles[`icon-${theme}`])} style={style} {...props} />
  );
};

export default Icon;
