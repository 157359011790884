import { gql } from '@apollo/client';

const accounts = ({
  selectedCompanies = [],
  selectedBankAccountGroups = [],
  selectedBanks = [],
  selectedBankAccounts = [],
  selectedCurrencies = [],
  onlyHasTransaction = true,
  incomeActivities = true,
  outgoingActivities = true,
  startDate = '2015-06-14T23:58:19.888954',
  endDate = '2021-06-15T07:00:00.888954',
  pageNumber = 1,
  pageSize = 10,
  description,
  accountTitle,
  minAmount,
  maxAmount,
  useAbsoluteValues = false,
}) => {
  return {
    query: gql`
      query(
        $selectedCompanies: [String]
        $selectedBankAccountGroups: [Guid]
        $selectedBanks: [Int]
        $selectedBankAccounts: [String]
        $selectedCurrencies: [String]
        $onlyHasTransaction: Boolean
        $incomeActivities: Boolean
        $outgoingActivities: Boolean
        $useAbsoluteValues: Boolean
        $startDate: DateTime
        $endDate: DateTime
        $pageNumber: Int
        $pageSize: Int
        $description: String
        $accountTitle: String
        $minAmount: Decimal
        $maxAmount: Decimal
      ) {
        bankAccountQuery {
          accounts(
            parameters: {
              selectedCompanies: $selectedCompanies
              selectedBanks: $selectedBanks
              selectedBankAccountGroups: $selectedBankAccountGroups
              selectedBankAccounts: $selectedBankAccounts
              selectedCurrencies: $selectedCurrencies
              onlyHasTransaction: $onlyHasTransaction
              incomeActivities: $incomeActivities
              outgoingActivities: $outgoingActivities
              useAbsoluteValues: $useAbsoluteValues
              startDate: $startDate
              endDate: $endDate
              pageNumber: $pageNumber
              pageSize: $pageSize
              description: $description
              title: $accountTitle
              minAmount: $minAmount
              maxAmount: $maxAmount
            }
          ) {
            result {
              openingBalance
              accountNo
              balance
              bankCode
              bankName
              branchCode
              currency
              lastTransactionDate
              hasNoData
              transactionCount
              maturityEndDate
              blockedAmount
              title
            }
            totalBalance
            totalCount
          }
        }
      }
    `,
    variables: {
      selectedCompanies,
      selectedBankAccountGroups,
      selectedBanks,
      selectedBankAccounts,
      selectedCurrencies,
      onlyHasTransaction,
      incomeActivities,
      outgoingActivities,
      useAbsoluteValues,
      startDate,
      endDate,
      pageNumber,
      pageSize,
      description,
      accountTitle,
      minAmount,
      maxAmount,
    },
  };
};

export default accounts;
