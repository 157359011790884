import React from 'react';

import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';

import DailySummaryTable from 'containers/elements/dailySummary/daily-summary-table';

import Widget from 'components/widget';

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="sidebar.dailySummary" defaultMessage="Günlük Rapor" />,
    path: '/daily-summary',
  },
];

const DailySummary = () => {
  const intl = useIntl();

  return (
    <>
      <Widget>
        <Breadcrumb
          title={<FormattedMessage id="sidebar.dailySummary" defaultMessage="Günlük Rapor" />}
          breadcrumbs={breadcrumbs}
        />
      </Widget>

      <DailySummaryTable lang={intl.locale} />
    </>
  );
};

export default injectIntl(DailySummary);
