import { createSlice } from '@reduxjs/toolkit';
import { local } from 'library/@e5p-request/helpers/index';
import { selectCompaniesActions } from 'src/redux/actions';
import { getDistincVkn } from 'src/redux/actions/select-companies/helpers';

// let localCompanies = local.getItemIfNotExpired('getCompaniesByGroup');
let localSelectedCompanies = local.getItemIfNotExpired('selectedCompanies');
let localFormattedCompanies = local.getItemIfNotExpired('formattedCompanies');
let localSelectedVkn = local.getItemIfNotExpired('selectedVkn');

const initialState = {
  loading: true,
  selectedCompanies: localSelectedCompanies?.data || [],
  formattedCompanies: localFormattedCompanies?.data || [],
  error: null,
  selectedVkn: localSelectedVkn?.data ?? JSON.parse(localStorage.getItem('defaultCompanies')),
  selectedCompaniesInitialized: false,
  defaultCompanies: [],
  defaultVkns: null,
  isPopUp: false,
  fade: 1,
};

export const selectCompanyReducer = createSlice({
  extraReducers: (builder) => {
    builder.addCase(selectCompaniesActions.initialize.fulfilled, (state, action) => {
      const { companies, error, formattedCompanies, loading, selectedCompanies, selectedVkn } = action.payload;

      state.companies = companies;
      state.error = error;
      state.selectedCompanies = selectedCompanies;
      state.formattedCompanies = formattedCompanies;
      state.loading = loading;
      state.selectedVkn = selectedVkn ?? JSON.parse(localStorage.getItem('defaultCompanies'));
      state.selectedCompaniesInitialized = true;
    });
  },
  initialState,
  name: 'selectCompanies',
  reducers: {
    onSelectCompany: (state, action) => {
      const company = action.payload;

      if (!company.isAll) {
        const filteredSelectedCompanies = state.selectedCompanies.filter((selectedCompany) => !selectedCompany.isAll);

        state.selectedCompanies = filteredSelectedCompanies;

        const vkn = getDistincVkn(state.selectedCompanies);

        state.selectedVkn = vkn;
      } else {
        state.selectedCompanies = [company];

        state.selectedVkn = [];

        return;
      }

      const item = state.selectedCompanies.find((selectedCompany) => selectedCompany.id === company.id);

      if (item) {
        const filteredSelectedCompanies = state.selectedCompanies.filter(
          (selectedCompany) => selectedCompany.id !== item.id,
        );

        state.selectedCompanies = filteredSelectedCompanies;

        if (state.selectedCompanies.length === 0) {
          state.selectedCompanies = [state.formattedCompanies[0]];

          state.selectedVkn = [];

          return;
        }

        const vkn = getDistincVkn(state.selectedCompanies);

        state.selectedVkn = vkn;
      } else {
        state.selectedCompanies.push(company);

        const vkn = getDistincVkn(state.selectedCompanies);

        state.selectedVkn = vkn;
      }
    },
    onFilterCompany: (state, action) => {
      const companies = action.payload;
      state.selectedCompanies = companies;
      if (!companies[0]?.isAll) {
        state.selectedVkn = getDistincVkn(companies);
      } else {
        state.selectedVkn = [];
      }
    },
    onStartCompany: (state, action) => {
      state.selectedVkn = action.payload;
    },
    getDefaultCompanies: (state, action) => {
      state.loading = true;
      state.defaultCompanies = action.payload.companies;

      let tempCompanies = action.payload.companies;
      tempCompanies = tempCompanies.filter(({ defaultCompany }) => defaultCompany);
      tempCompanies = tempCompanies.map(({ vkn }) => vkn);
      state.defaultVkns = tempCompanies;
      localStorage.setItem('defaultCompanies', JSON.stringify(tempCompanies));
      // if (!state.selectedVkn || (state.selectedVkn && state.selectedVkn.length === 0)) {
      //   state.selectedVkn = tempCompanies;
      // }
      state.isPopUp = tempCompanies.length === 0 ? true : false;
      state.loading = false;
    },
    fadeOut: (state, action) => {
      state.fade = action.payload;
    },
  },
});

export const {
  onSelectCompany,
  onFilterCompany,
  onStartCompany,
  getDefaultCompanies,
  fadeOut,
} = selectCompanyReducer.actions;

export default selectCompanyReducer.reducer;
