import { gql } from '@apollo/client';

const companyAccountQuery = ({
  selectedVkns = [],
  selectedBanks = [],
  pageNumber = 1,
  pageSize = 20,
  status = true,
}) => {
  return {
    query: gql`
      query($selectedVkns: [String], $selectedBanks: [Int], $pageNumber: Int, $pageSize: Int, $status: Boolean) {
        companyAccountQuery {
          companyAccountPaging(
            selectedVkns: $selectedVkns
            selectedBanks: $selectedBanks
            pageNumber: $pageNumber
            pageSize: $pageSize
            status: $status
          ) {
            totalCount
            result {
              onlyRefreshCount
              onlyRefresh
              bankaAdi
              bankDataSource
              sirketKodu
              sirketUnvan
              uid
              vkn
              sonIslemDurum
              sonIslemMesaj
            }
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBanks,
      pageNumber,
      pageSize,
      status,
    },
  };
};

export default companyAccountQuery;
