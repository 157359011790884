import React, { useMemo } from 'react';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import Card from 'components/card';
import Icon from 'components/icon';
import CurrencyLogo from 'components/currency-logo';
import Loader from 'components/loader';

import SmallBankButton from 'components/small-bank-button';

import formattedCurrency from 'utils/formatted-currency';
import { useAppSelector } from 'src/redux/store';
import { Tooltip } from 'react-tippy';
import styles from './styles.scss';

const BalanceListByCurrency = ({
  loading = false,
  banks,
  totalBalance,
  bankCount,
  accountCount,
  currency,
  currencySymbol,
  history,
  blockedAmount,
  totalBalanceForDepositAccounts = 0,
  showMaturity,
}) => {
  const intl = useIntl();
  const lang = intl.locale;

  const { theme } = useAppSelector((state) => state.theme);

  const [selectedBanks, setSelectedBanks] = React.useState([]);
  const [selectedBanksTotalBalance, setSelectedBanksTotalBalance] = React.useState(0);

  React.useEffect(() => {
    const newTotalBalance = selectedBanks
      .reduce((acc, curr) => {
        return acc + curr?.totalBalance;
      }, 0)
      .toFixed(2);

    setSelectedBanksTotalBalance(newTotalBalance);
  }, [selectedBanks]);

  const handleSelect = (bank) => {
    const cloneBanks = [...selectedBanks];
    const index = selectedBanks.findIndex((item) => item.bankCode === bank.bankCode);
    if (index < 0) {
      cloneBanks.push(bank);
      setSelectedBanks(cloneBanks);
    } else {
      cloneBanks.splice(index, 1);
      setSelectedBanks(cloneBanks);
    }
  };

  const renderBanks = useMemo(() => {
    return banks.map((bank, index) => {
      const active = selectedBanks.findIndex((item) => {
        return item.bankCode === bank.bankCode;
      });
      return (
        <SmallBankButton
          key={index.toString()}
          logo={bank.iconLogo}
          name={bank.bankName}
          active={active > -1}
          onClick={handleSelect.bind(this, bank)}
        />
      );
    });
  }, [banks, selectedBanks]);

  return (
    <Card
      loading={loading}
      loader={<Loader />}
      className={cx(styles['bank-account-card'])}
      contentClassNames={cx(styles['bank-account-card-content'])}
      leftItem={<CurrencyLogo summary={currency} name={currency} symbol={currencySymbol} />}
      rightItem={
        <div className={cx(styles['bank-account-card-right-item-container'])}>
          <Tooltip
            title={
              showMaturity
                ? intl.messages['info.myAssets.currencyBox']
                : intl.messages['info.myAssetsMaturity.currencyBox']
            }
            position="bottom"
            trigger="mouseenter">
            <Icon name={'icon-info-24-px'} />
          </Tooltip>
          <div
            className={cx(styles['bank-account-card-arrow'])}
            onClick={() => {
              const selectedBankList = selectedBanks.length > 0 ? selectedBanks : banks;
              const bankCodes = selectedBankList.map((bank) => bank.bankCode);
              history.push({
                pathname: '/bank-activity',
                state: {
                  selectedBanks: bankCodes,
                  selectedCurrencies: [currency],
                },
              });
            }}>
            <Icon className="icon-icon-navigation-east-24-px"></Icon>
          </div>
        </div>
      }>
      <div className={cx(styles['bank-account-card-balance'], styles[`bank-account-card-balance-${theme}`])}>
        {lang === 'tr'
          ? `${formattedCurrency(
              selectedBanks.length > 0 ? selectedBanksTotalBalance : totalBalance,
            )} ${currencySymbol}`
          : `${currencySymbol} ${formattedCurrency(
              selectedBanks.length > 0 ? selectedBanksTotalBalance : totalBalance,
            )}`}
      </div>
      <div className={cx(styles['bank-account-card-info'])}>
        {blockedAmount !== 0 && (
          <>
            {' '}
            <FormattedMessage id="general.totalBlockedAmount" defaultMessage="Toplam Bloke Bakiye" /> :{' '}
            {`${formattedCurrency(blockedAmount)} ${currencySymbol}`}
          </>
        )}
      </div>
      {showMaturity && (
        <div className={cx(styles['bank-account-card-info'])}>
          {totalBalanceForDepositAccounts !== 0 && (
            <>
              {' '}
              <FormattedMessage
                id="general.totalmaturityAmount"
                defaultMessage="Toplam Vadeli Hesap Bakiyesi "
              /> : {`${formattedCurrency(totalBalanceForDepositAccounts)} ${currencySymbol}`}
            </>
          )}
        </div>
      )}

      <div className={cx(styles['bank-account-card-count'])}>
        {bankCount} <FormattedMessage id="general.bank" defaultMessage="Banka" /> - {accountCount}{' '}
        <FormattedMessage id="general.account" defaultMessage="Hesap" />
      </div>

      <div>
        <div className={cx(styles['bank-account-card-list-title'])}>
          <FormattedMessage id="sidebar.banks" defaultMessage="Bankalar" />
        </div>
        <div className={cx(styles['bank-account-card-list'])}>{renderBanks}</div>
      </div>
    </Card>
  );
};

export default BalanceListByCurrency;
