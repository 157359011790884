import React from 'react';
import cx from 'classnames';

import styles from './styles.scss';

const CurrencyLogo = ({ summary = 'TRY', name = 'Türk Lirası', symbol = '₺' }) => {
  return (
    <div className={cx(styles['currency-logo'])}>
      <div
        className={cx(styles['currency-logo-symbol'], `${summary}-bg`, {
          [styles['currency-logo-symbol-smallFont']]: symbol?.length > 2,
          [styles['currency-logo-symbol-mediumFont']]: symbol?.length === 2,
        })}>
        <span>{symbol}</span>
      </div>
      <div className={cx(styles['currency-logo-text'], `${summary}`)}>{name}</div>
    </div>
  );
};

export default CurrencyLogo;
