import cx from 'classnames';
import styles from './styles.scss';
import { useAppSelector } from 'src/redux/store';
import React, { useState, useEffect, Fragment } from 'react';

const BankAccountSelectItem = ({ items, onSelect, tempGroups, searchText }) => {
  // redux hooks
  const { theme } = useAppSelector((state) => state.theme);

  // useState hooks
  const [showMore, setShowMore] = useState([]);

  /** WHEN OUTSIDE CLICK AUTO CLOSED */
  useEffect(() => {
    if (showMore.length > 0) {
      document.addEventListener('click', () => {
        setShowMore([]);
      });
      document.removeEventListener('click', () => {});
    }
  }, [showMore]);
  /** FINISH WHEN OUTSIDE CLICK AUTO CLOSED */

  return (
    <>
      {items
        .filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))
        .map((item, index) => {
          const selectedItem = tempGroups.find((group) => group.guid === item.guid);
          const isAll = !tempGroups.length || !tempGroups[0].guid;

          return (
            <Fragment key={index.toString()}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect({ ...item });
                }}
                className={cx(styles['option-item'], styles[`option-item-${theme}`], {})}>
                <abbr title={item.name}>
                  {item.guid ? (
                    <div
                      className={cx(styles['option-item-name'], {
                        [styles['option-item-name-selected']]: selectedItem,
                      })}>
                      <span>{item.name}</span>
                    </div>
                  ) : (
                    <div
                      className={cx(styles['option-item-name'], styles['option-item-all'], {
                        [styles['option-item-name-selected']]: isAll,
                      })}>
                      <span>{item.name}</span>
                    </div>
                  )}
                </abbr>
              </div>
            </Fragment>
          );
        })}
    </>
  );
};

export default BankAccountSelectItem;
