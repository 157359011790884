import React from 'react';
import { useAppSelector } from 'src/redux/store';

import { FormattedMessage } from 'react-intl';
import { Select, SelectItem } from 'components';

import cx from 'classnames';
import styles from './styles.scss';

const SelectCurrenciesMulti = ({ title, selectedCurrencies, onChange, banks }) => {
  const {
    theme: { theme },
  } = useAppSelector((state) => state);

  const [defaultCurrencies, setDefaultCurrencies] = React.useState([]);

  React.useEffect(() => {
    if (selectedCurrencies.length === 0) {
      setDefaultCurrencies([]);
    }
  }, [selectedCurrencies]);

  const onChangeOption = (currency) => {
    const isCurrencySelected = defaultCurrencies.find((dc) => dc.bankName === currency.bankName);

    if (isCurrencySelected) {
      const defaultCurrencyList = defaultCurrencies.filter((dc) => dc.bankName !== currency.bankName);
      setDefaultCurrencies(defaultCurrencyList);
      onChange(defaultCurrencyList);
      return;
    }

    const defaultCurrencyList = [...defaultCurrencies, currency];
    setDefaultCurrencies(defaultCurrencyList);
    onChange(defaultCurrencyList);
  };

  const clearSelectedItems = () => {
    setDefaultCurrencies([]);
    onChange([]);
  };

  const renderPlaceholder = () => {
    if (defaultCurrencies.length > 1) {
      return (
        <>
          {defaultCurrencies.length}{' '}
          <FormattedMessage id="organisms.activities.filter.currencySelectionLabel.selected" defaultMessage="seçim" />
        </>
      );
    }

    if (defaultCurrencies.length === 1) {
      return (
        <div className={cx(styles['select-item-selected'], styles[`select-item-selected-${theme}`])}>
          {defaultCurrencies[0].bankName}
        </div>
      );
    }
    return <FormattedMessage id={`molecule.dropdown.${title}`} defaultMessage={`${title} Seçiniz`} />;
  };

  return (
    <Select
      multiple
      placeholder={renderPlaceholder()}
      selected={defaultCurrencies}
      className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}
      selectClassName={styles['filters-select-currency-container']}
      clearSelectedItems={clearSelectedItems}>
      {banks.map((currency, index) => (
        <SelectItem
          key={`filters-currency-item-${index}`}
          className={
            defaultCurrencies.find((dc) => dc === currency)
              ? cx(styles['filters-select-item'], styles['filters-select-item-active'])
              : styles['filters-select-item']
          }
          value={currency.bankName}
          isActive={defaultCurrencies.find((dc) => dc.bankName === currency.bankName)}
          onClick={() => onChangeOption(currency)}>
          {currency.bankName}
        </SelectItem>
      ))}
    </Select>
  );
};

export default SelectCurrenciesMulti;
