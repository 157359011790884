import React from 'react';
import cx from 'classnames';

import Switch from 'components/switch';

import styles from './styles.scss';
import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { toggleTheme } from 'src/redux/reducers/theme/theme-reducer';

const ThemeButton = () => {
  const dispatch = useAppDispatch();

  const { theme } = useAppSelector((state) => state.theme);

  const [checked, setChecked] = React.useState(theme === 'dark' ? true : false);

  const onChecked = (e) => {
    e.stopPropagation();
    dispatch(toggleTheme());
  };

  React.useEffect(() => {
    setChecked(theme === 'dark' ? true : false);
  }, [theme]);

  return (
    <Switch
      round
      onChecked={onChecked}
      checked={checked}
      containerStyle={cx(styles.switch)}
      className={cx(styles[`switch-${theme}`], {
        'icon-icon-action-dark-mode-24-px': checked,
        'icon-icon-action-wb-sunny-24-px': !checked,
        [styles[`icon-${theme}`]]: true,
      })}
    />
  );
};

export default ThemeButton;
