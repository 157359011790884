import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FormattedMessage, injectIntl } from 'react-intl';
import CompanyaccountsTable from 'containers/elements/company-accounts/company-accounts-table';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';
import Widget from 'components/widget';

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="sidebar.companyAccounts" defaultMessage="Şirket Hesapları" />,
    path: '/daily-summary',
  },
];

const CompanyAccounts = () => {
  const router = useHistory();

  useEffect(() => {
    const userBankDataSource = localStorage.getItem('userBankDataSource');

    if (userBankDataSource != 'true') {
      router.replace('/dashboard');
    }
  }, []);

  return (
    <>
      <Widget>
        <Breadcrumb
          title={<FormattedMessage id="sidebar.companyAccounts" defaultMessage="Şirket Hesapları" />}
          breadcrumbs={breadcrumbs}
        />
      </Widget>

      <CompanyaccountsTable />
    </>
  );
};

export default injectIntl(CompanyAccounts);
