import { useState } from 'react';

export const useModal = (initial = false) => {
  const [isShowing, setIsShowing] = useState(initial);

  function toggle() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
  };
};
