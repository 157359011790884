import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import request from 'library/@e5p-request';

import { useAppSelector } from 'src/redux/store';

import Widget from 'components/widget';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';
import BalanceListByCurrency from 'containers/elements/tangible-assets/balance-list-by-currency';
import { Card, Loader } from 'components/index';

const { getBankBalancesByCurrency2 } = request;

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="sidebar.myAssets" defaultMessage="Varlıklarım" />,
    path: '/tangible-assets',
  },
];

const TangibleAssets = ({ ...props }) => {
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
  } = useAppSelector((state) => state);

  const [balances, setBalances] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    setLoading(true);
    getBankBalancesByCurrency2(selectedVkn, selectedBankAccountGroups)
      .then((res) => {
        setBalances(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [selectedVkn, selectedBankAccountGroups]);

  return (
    <>
      <Widget>
        <Breadcrumb
          title={<FormattedMessage id="sidebar.myAssets" defaultMessage="Varlıklarım" />}
          breadcrumbs={breadcrumbs}
        />
      </Widget>
      {loading && (
        <Card>
          <Loader />
        </Card>
      )}
      <Widget row={window.screen.width === 768 ? 1 : 2}>
        {balances.length > 0 &&
          balances.map((balance, index) => {
            return (
              <BalanceListByCurrency
                loading={loading}
                key={index.toString()}
                {...balance}
                history={props.history}
                showMaturity
              />
            );
          })}
      </Widget>
    </>
  );
};

export default injectIntl(TangibleAssets);
