import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const useImageError = () => {
  const [el, setEl] = React.useState(null); // contains image reference
  const [error, setError] = React.useState(false); // contains error flag

  const _handleError = () => {
    setError(true);
  }; // set error
  const retry = () => {
    setError(false);
  }; // set error false to img can re-render

  React.useEffect(() => {
    // use of error event of the image tag
    el?.addEventListener('error', _handleError);

    return () => {
      el?.removeEventListener('error', _handleError);
    };
  }, [el]);

  return [
    setEl, // set the image ref
    error, // error flag
    retry, // a func, which can be used to re-render image
    el, // img ref(for special case which requires ref)
  ];
};

const BankButton = ({ logo, onClick, active = false, bankName }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [setImg, hasError] = useImageError();

  if (hasError) {
    return (
      <div className={cx(styles.button, styles[`button-${theme}`])} onClick={onClick}>
        <div
          className={cx(styles.img, styles[`img-${theme}`], {
            [styles[`img-active`]]: active,
            [styles[`img-error`]]: hasError,
          })}>
          <div>{bankName}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cx(styles.button, styles[`button-${theme}`])} onClick={onClick}>
      <img
        ref={setImg}
        src={logo}
        className={cx(styles.img, styles[`img-${theme}`], {
          [styles[`img-active`]]: active,
        })}
      />
      <span className={cx(styles.bankName, styles[`bankName-${theme}`])}>{bankName}</span>
    </div>
  );
};

export default BankButton;
