import React from 'react';
import cx from 'classnames';

import LogoSrc from 'assets/images/logo.png';

import styles from './styles.scss';

const Logo = ({ className }) => {
  return (
    <div className={cx(styles.logo, className)}>
      <img src={LogoSrc} alt="logo" />
    </div>
  );
};

export default Logo;
