const totalBalanceWithBanksFormatter = (data) => {
  const formattedData = data.result.reduce((total, item) => {
    const findBankInResult = total.find((bank) => bank.bankCode === item.bankCode);
    const bankIndex = total.findIndex((bank) => bank.bankCode === item.bankCode);

    if (!findBankInResult) {
      // Eger İLK veri Girişi ise uygun model eklenir
      total.push({
        bankCode: item.bankCode,
        bankLogo: item.bankLogo,
        bankName: item.bankName,
        balances: [
          {
            accountCount: 1,
            accountCountHasData: item.hasNoData ? 0 : 1,
            totalBalance: item.balance,
            currency: item.currency,
            currencySymbol: item.currencySymbol,
            currencyName: item.currencyName,
            details: [
              {
                balance: item.balance,
                companyName: item?.companyGroupName ? item?.companyGroupName : item.companyName,
                branchAndAccountNo: `${item.branchNo} / ${item.bankAccountNo}`,
                bankAccountNo: item.bankAccountNo,
                branchNo: item.branchNo,
                maturityEndDate: item.maturityEndDate,
                hasNoData: item.hasNoData,
                bankAccountTitle: item.bankAccountTitle,
              },
            ],
          },
        ],
      });
    } else {
      const findCurrencyInBankBalances = findBankInResult.balances.find(
        (findItem) => item.currency === findItem.currency,
      );
      const currencyIndex = findBankInResult.balances.findIndex((currency) => item.currency === currency.currency);

      if (!findCurrencyInBankBalances) {
        // Eger İtemın parabirimi ile ilk gelişi ise model set edilir
        const newBalancesArray = [...findBankInResult.balances];
        newBalancesArray.push({
          accountCount: 1,
          accountCountHasData: item.hasNoData ? 0 : 1,
          totalBalance: item.balance,
          currency: item.currency,
          currencySymbol: item.currencySymbol,
          currencyName: item.currencyName,
          details: [
            {
              balance: item.balance,
              companyName: item?.companyGroupName ? item?.companyGroupName : item.companyName,
              branchAndAccountNo: `${item.branchNo} / ${item.bankAccountNo}`,
              hasNoData: item.hasNoData,
              bankAccountNo: item.bankAccountNo,
              branchNo: item.branchNo,
              maturityEndDate: item.maturityEndDate,
              bankAccountTitle: item.bankAccountTitle,
            },
          ],
        });
        total[bankIndex].balances = newBalancesArray;
      } else {
        const newCurrencyObj = {
          ...findCurrencyInBankBalances,
          totalBalance: (findCurrencyInBankBalances.totalBalance += item.balance),
          details: [
            ...findCurrencyInBankBalances.details,
            {
              balance: item.balance,
              companyName: item?.companyGroupName ? item?.companyGroupName : item.companyName,
              branchAndAccountNo: `${item.branchNo} / ${item.bankAccountNo}`,
              hasNoData: item.hasNoData,
              bankAccountNo: item.bankAccountNo,
              branchNo: item.branchNo,
              maturityEndDate: item.maturityEndDate,
              bankAccountTitle: item.bankAccountTitle,
            },
          ],
          accountCount: findCurrencyInBankBalances.accountCount + 1,
          accountCountHasData: item.hasNoData
            ? findCurrencyInBankBalances.accountCountHasData
            : findCurrencyInBankBalances.accountCountHasData + 1,
        };

        findBankInResult.balances[currencyIndex] = newCurrencyObj;
      }
    }

    return total;
  }, []);

  return formattedData;
};

export default totalBalanceWithBanksFormatter;
