import React from 'react';
import cx from 'classnames';

import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

export default function SelectItem({ children, isActive = false, className, onClick, value, ...restProps }) {
  const { theme } = useAppSelector((state) => state.theme);

  const handleClick = (event) => {
    onClick(value, event);
  };

  return (
    <li
      className={cx(styles['select-item'], styles[`select-item-${theme}`], className, {
        [styles['select-item-active']]: isActive,
      })}
      onClick={handleClick}
      {...restProps}>
      <span className={cx(styles['select-item-text'], styles[`select-item-text-${theme}`])}>{children}</span>
    </li>
  );
}
