import { gql } from '@apollo/client';

const currencies = (selectedVkns = [], selectedBankAccountGroups = []) => {
  return {
    query: gql`
      query($selectedVkns: [String], $selectedBankAccountGroups: [Guid]) {
        currencyQuery {
          currencies(selectedVkns: $selectedVkns, selectedBankAccountGroups: $selectedBankAccountGroups) {
            color
            id
            name
            summary
            symbol
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccountGroups,
    },
  };
};

export default currencies;

/*

const response = {
  "data": {
    "currencyQuery": {
      "currencies": [
        {
          "color": "#fbc34e",
          "id": 100,
          "name": "Türk Lirası",
          "summary": "TRY",
          "symbol": "₺"
        },
        {
          "color": "#5ab466",
          "id": 101,
          "name": "Amerikan Doları",
          "summary": "USD",
          "symbol": "$"
        },
        {
          "color": "#3d88c8",
          "id": 102,
          "name": "Euro",
          "summary": "EUR",
          "symbol": "€"
        }
      ]
    }
  }
}

*/
