import React from 'react';
import cx from 'classnames';

import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const BankCardItem = ({
  currency = 'USD',
  symbol = '$',
  currencyName = 'Amerikan Doları',
  total = '445,140.92',
  accountCount = '9',
  accountCountHasData,
}) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={styles.wrapper}>
      <div className={styles.currency}>
        <div
          className={cx(`${currency}-bg`, styles['currency-symbol'], {
            [styles['currency-symbol-smallFont']]: symbol?.length > 2,
          })}>
          {symbol}
        </div>
        <div className={cx(currency, styles['currency-name'])}>{currencyName}</div>
      </div>
      <div className={cx(styles['total'], styles[`total-${theme}`])}>{total}</div>
      <div className={cx(styles['account-count'], styles[`account-count-${theme}`])}>
        {accountCount} / {accountCountHasData} <FormattedMessage id="general.account" defaultMessage="Hesap" />
        {/* {accountCount} / {accountCountHasData} <FormattedMessage id="general.account" defaultMessage="Hesap" /> */}
      </div>
    </div>
  );
};

export default BankCardItem;
