import React from 'react';
import styles from './styles.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      showDetailedErrors: false,
      error: '',
      errorInfo: '',
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(errorInfo);
    this.setState({ error, errorInfo });
  }

  handleDetailedErrors() {
    this.setState({ showDetailedErrors: !this.state.showDetailedErrors });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles['errorBoundary']}>
          <h3>Something went wrong.</h3>

          <a href="/" className={styles['errorBoundary-link']}>
            Go to Dashboard
          </a>

          <button
            className={styles['errorBoundary-button']}
            onClick={() => {
              this.handleDetailedErrors();
            }}>
            {!this.state.showDetailedErrors ? 'Show detailed errors' : 'Hide detailed errors'}
          </button>

          {this.state.showDetailedErrors && (
            <>
              <h4 className={styles['errorBoundary-error']}>{this.state.error?.message}</h4>
              <p>{this.state.errorInfo?.componentStack}</p>
            </>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
