import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/store';
import { FormattedMessage } from 'react-intl';

import mutations from 'library/mutation';
import { Loader, Button } from 'components';
import Checkbox from 'components/checkbox';
import Text from 'components/text';

import cx from 'classnames';
import styles from './styles.scss';

const { saveDefaultCompanies } = mutations;

const SelectCompanyDefaultPopup = ({ onSetModalLoading, changeErrorMessage }) => {
  const {
    selectCompanies: { loading, defaultCompanies },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [tempLoading, setTempLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [currentCompanies, setCurrentCompanies] = React.useState([]);
  const handleStatus = ({ data }) => {
    let tempArray = currentCompanies.map((company) => {
      return company.vkn === data.vkn ? { ...data, defaultCompany: !data.defaultCompany } : company;
    });
    setCurrentCompanies(tempArray);
  };

  const handleSave = async () => {
    try {
      await onSetModalLoading(true);
      await setTempLoading(true);
      const data = await currentCompanies.map(({ vkn, defaultCompany }) => {
        return { vkn, defaultCompany };
      });
      const responseMutation = await saveDefaultCompanies({ data });
      await setSaveMessage(responseMutation?.userMutation?.updateDefaultCompany?.errors || null);
      await changeErrorMessage(responseMutation?.userMutation?.updateDefaultCompany?.errors);
      await setIsSuccess(responseMutation?.userMutation?.updateDefaultCompany?.isSuccess || false);
      await setTempLoading(false);
      if (responseMutation.userMutation.updateDefaultCompany.errors == null) {
        await setTimeout(async () => {
          window.location.reload(true);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      await setTempLoading(false);
      await onSetModalLoading(false);
    }
  };

  useEffect(() => {
    setCurrentCompanies(defaultCompanies);
  }, [defaultCompanies]);

  if (loading || tempLoading) {
    return <Loader />;
  }

  return (
    <div className={styles['card-container ']}>
      <table className={cx(styles['table'], styles[`table-${theme}`])}>
        <thead className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
          <tr>
            <th>
              <Text className={cx(styles['table-cell-text'])}>
                <FormattedMessage id="defaultSelectedCompany.companyName" defaultMessage="Şirket Adı" />
              </Text>
            </th>
            <th>
              <Text className={cx(styles['table-cell-text'])}>
                <FormattedMessage id="defaultSelectedCompany.defaultCompany" defaultMessage="Varsayılan Şirket" />
              </Text>
            </th>
          </tr>
        </thead>
        <tbody className={cx(styles['table-body'], styles[`table-body-${theme}`])}>
          {currentCompanies.map((data, index) => {
            return (
              <tr key={`defaultCompany-${index}`} className={cx(styles[`table-body-${theme}-row`])}>
                <td className={styles['table-cell']}>
                  <Text className={cx(styles['table-cell-text'])}>{data.name}</Text>
                </td>
                <td>
                  <Checkbox onClick={() => handleStatus({ data })} checked={data.defaultCompany} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={cx(styles['button-container-save'])}>
        <span className={cx(styles[`info-${theme}`])}>
          {isSuccess ? (
            <FormattedMessage id="defaultSelectedCompany.saveMessage" defaultMessage="Kaydedildi" />
          ) : (
            saveMessage
          )}
        </span>
        <Button className={cx(styles['button-group-save'])} onClick={async () => await handleSave()}>
          <FormattedMessage id="defaultSelectedCompany.save" defaultMessage="Kaydet" />
        </Button>
      </div>
    </div>
  );
};
export default SelectCompanyDefaultPopup;
