import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import request from 'library/@e5p-request';

import Card from 'components/card';
import Loader from 'components/loader';
import BankButton from 'components/small-bank-button';
import HalfCircleDonatChart from 'components/half-circle-donat-chart';

import formattedCurrency from 'utils/formatted-currency';

import { useAppDispatch, useAppSelector } from 'src/redux/store';
import {
  setSelectedGroupTotalBalance,
  setSelectedGroupLoading,
} from 'src/redux/reducers/select-groups/select-groups-reducer';

import styles from './styles.scss';

const { getBanks } = request;

const BankAccountRatio = ({ intl }) => {
  const dispatch = useAppDispatch();
  const {
    selectCurrency: { defaultCurrencyItem },
    selectGroups: { selectedGroup, selectedBankAccountGroups, selectedGroupLoading, selectedGroupTotalBalance },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const lang = intl.locale;
  const [banks, setBanks] = React.useState([]);
  const [activeBanks, setActiveBanks] = React.useState([]);

  const [totalBalance, setTotalBalance] = React.useState(0);

  const [ratio, setRatio] = React.useState(0);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    if (!defaultCurrencyItem?.summary || !selectedVkn) return;
    dispatch(setSelectedGroupLoading(true));
    getBanks(selectedVkn, defaultCurrencyItem.summary, true, selectedBankAccountGroups)
      .then((res) => {
        setActiveBanks([]);

        const banksData = res.filter((i) => i.bankCode !== 0);
        setBanks(banksData);

        setTotalBalance(res[0].totalBalance);

        dispatch(setSelectedGroupLoading(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setSelectedGroupLoading(false));
      });
  }, [selectedVkn, defaultCurrencyItem?.summary, selectedGroup, selectedBankAccountGroups]);

  React.useEffect(() => {
    if (activeBanks.length > 0) {
      calculateRatio(activeBanks);
    } else if (banks.length > 0) {
      calculateRatio(banks);
    } else {
      calculateRatio([]);
    }
  }, [activeBanks, banks, totalBalance]);

  const calculateRatio = (bankList) => {
    const selectedBankBalance = Number(
      bankList
        .reduce((acc, curr) => {
          return acc + curr?.totalBalance;
        }, 0)
        .toFixed(2),
    );

    if (selectedBankBalance <= 0 || totalBalance <= 0) {
      setRatio(0);
    } else if (selectedBankBalance === totalBalance) {
      setRatio(100);
    } else {
      setRatio(((selectedBankBalance * 100) / totalBalance).toFixed(2).replace('.', ','));
    }

    dispatch(setSelectedGroupTotalBalance(selectedBankBalance));
  };

  const handleBankButtonOnClick = (bank) => {
    const cloneBanks = [...activeBanks];
    const index = activeBanks.findIndex((item) => item.bankCode === bank.bankCode);

    if (index < 0) {
      cloneBanks.push(bank);
      setActiveBanks(cloneBanks);
    } else {
      if (activeBanks.length > 0) {
        cloneBanks.splice(index, 1);
      }
      setActiveBanks(cloneBanks);
    }
  };
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const dymicHeightValue = banks.length > 0 ? (isIE ? banks.length * 25 + 'px' : 'auto') : 'auto';

  const renderBanks = banks.sort((a, b) => {
    return b.totalBalance - a.totalBalance;
  });

  return (
    <Card
      title={<FormattedMessage id={`dashboard.card.title.bankAccDistribution`} />}
      loading={selectedGroupLoading}
      loader={<Loader />}
      info={'info.dashboard.balanceChart'}>
      <div className={styles.wrapper} style={{ minHeight: dymicHeightValue }}>
        <div className={styles['bank-list-wrapper']}>
          {renderBanks.length > 0 &&
            renderBanks.map((bank, index) => {
              const active = activeBanks.findIndex((item) => {
                if (item) {
                  return item.bankCode === bank.bankCode;
                }
              });

              const rate =
                lang === 'tr'
                  ? bank.totalBalance > 0
                    ? `%${((bank.totalBalance * 100) / totalBalance).toFixed(2).replace('.', ',')}`
                    : '%0'
                  : bank.totalBalance > 0
                  ? `${((bank.totalBalance * 100) / totalBalance).toFixed(2).replace('.', ',')}%`
                  : '0%';

              return (
                <BankButton
                  rate={rate}
                  key={index.toString()}
                  logo={bank.iconLogo}
                  name={bank.bankName}
                  active={active > -1}
                  onClick={() => handleBankButtonOnClick(bank)}
                />
              );
            })}
        </div>
        <HalfCircleDonatChart ratio={ratio}>
          <div className={styles['wrapper-total-balance']}>
            <span className={styles['wrapper-total-balance-ratio']}>{lang === 'tr' ? `%${ratio}` : `${ratio}%`}</span>
            <span className={(styles['wrapper-total-balance-total'], styles[`wrapper-total-balance-total-${theme}`])}>
              {lang === 'tr'
                ? `${formattedCurrency(selectedGroupTotalBalance)} ${
                    (banks.length === 1 && banks[0].currencySymbol) || (banks.length > 1 && banks[1].currencySymbol)
                  }`
                : `${
                    (banks.length === 1 && banks[0].currencySymbol) || (banks.length > 1 && banks[1].currencySymbol)
                  } ${formattedCurrency(selectedGroupTotalBalance)}`}
            </span>
          </div>
        </HalfCircleDonatChart>
      </div>
    </Card>
  );
};

export default injectIntl(BankAccountRatio);
