import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Tooltip } from 'react-tippy';

import Text from 'components/text';
import Icon from 'components/icon';
import { Select, SelectItem } from 'components';

import formattedCurrency from 'utils/formatted-currency';

import ActivitiesDetailTable from 'containers/elements/banks-activities/activities-detail-table';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';
import moment from 'moment-timezone';
import { local } from 'library/@e5p-request/helpers/index';
import { Checkbox } from 'components/index';
import { formattedLongText } from 'utils/format-long-text';

const mainTableColumns = [
  {
    name: <FormattedMessage id="general.bank" defaultMessage="Banka" />,
    key: 'bankName',
    isSort: false,
  },
  {
    name: <FormattedMessage id="molecules.column.branchAndAccountNo.title" defaultMessage="Şube / Hesap No" />,
    key: 'branchAndAccountNo',
    isSort: false,
  },
  {
    name: <FormattedMessage id="general.currencyType" defaultMessage="Döviz Cinsi" />,
    key: 'branchName',
    isSort: false,
  },
  {
    name: <FormattedMessage id="general.accountTitle" defaultMessage="Hesap Adı" />,
    key: 'title',
    isSort: false,
  },
  {
    name: <FormattedMessage id="general.maturityEndDate" defaultMessage="Vade bitişi" />,
    key: 'maturityEndDate',
    isSort: false,
  },
  {
    name: <FormattedMessage id="general.openingBalance" defaultMessage="Açılış Bakiyesi" />,
    key: 'openingBalance',
    isSort: false,
  },
  {
    name: <FormattedMessage id="general.blockedAmount" defaultMessage="Bloke Bakiye" />,
    key: 'blockedAmount',
    isSort: false,
  },
  {
    name: <FormattedMessage id="general.totalBalance2" defaultMessage="Son Bakiye" />,
    key: 'totalBalance',
    isSort: false,
  },
];

const ActivitiesTable = ({
  data,
  totalCount,
  defaultParams,
  onChange,
  selectedReceipts,
  handleSelectSingleReceipt,
  handleSelectMultiReceipts,
  handleChangePageSize,
  showGoDetailButton = false,
  goDetailDateRange = null,
  handleSelectSingleAccount = () => {},
  handleSelectMultiAccount = () => {},
  selectedAccounts = [],
}) => {
  const { theme } = useAppSelector((state) => state.theme);

  const pageSizesArray = [20, 50, 100, 250, 500, 1000, 99999];
  const [showAccordion, setShowAccordion] = useState('');

  const renderFormattedDate = (d) => {
    const tz = moment.tz.guess();

    if (tz.indexOf('America') !== -1) {
      return moment(d).format('MM/DD/YYYY');
    }

    return moment(d).format('DD/MM/YYYY');
  };

  const renderFormattedTime = (d) => {
    if (d === null) {
      return;
    }

    const langFromStorage = localStorage.getItem('@ep-language');
    if (langFromStorage === 'en') {
      return moment(d).format('MM-DD-YYYY h:mm:ss A');
    }
    return moment(d).format('DD.MM.YYYY H:mm:ss');
  };

  const handleShowAccordion = (key) => {
    setShowAccordion(showAccordion === key ? null : key);
  };

  const prevPage = () => {
    if (defaultParams.pageNumber === 1) return;
    onChange({
      ...defaultParams,
      pageNumber: defaultParams.pageNumber - 1,
    });
  };

  const nextPage = () => {
    if (defaultParams.pageNumber === Math.ceil(totalCount / defaultParams.pageSize)) return;
    onChange({
      ...defaultParams,
      pageNumber: defaultParams.pageNumber + 1,
    });
  };

  const selectControlAccounts = (accounts) => {
    const accInSelectedsLenght = accounts.reduce((total, item) => {
      if (selectedAccounts.findIndex((acc) => acc === item.accountNo) !== -1) {
        total += 1;
      }
      return total;
    }, 0);

    return accInSelectedsLenght === accounts.length;
  };

  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        <div className={styles['table']}>
          <div className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
            <div
              style={{ width: '50px' }}
              className={cx(styles['00'], styles[`table-header-cell`], styles[`table-header-cell-${theme}`])}>
              <Checkbox
                onClick={() => {
                  handleSelectMultiAccount(data, selectControlAccounts(data));
                }}
                checked={selectControlAccounts(data)}
              />
            </div>
            {mainTableColumns.map((column, key) => (
              <div
                key={`header-cell-${column - key}-${key}`}
                className={cx(styles[column.key], styles[`table-header-cell`], styles[`table-header-cell-${theme}`])}
                style={
                  column.key === 'totalBalance' || column.key === 'blockedAmount' ? { justifyContent: 'flex-end' } : {}
                }>
                <Text className={styles['table-header-cell-text']}>
                  {column.name} {column.isSort && <Icon name="icon-navigation-expand-more-24-px"></Icon>}{' '}
                </Text>
              </div>
            ))}
            {showGoDetailButton && (
              <div
                style={{ justifyContent: 'flex-end', width: '100px' }}
                key={`header-cell-99`}
                className={cx(styles['99'], styles[`table-header-cell`], styles[`table-header-cell-${theme}`])}></div>
            )}
          </div>
          <div className={cx(styles['table-body'], styles[`table-body-${theme}`])}>
            {data.map((column, key) => {
              return (
                <React.Fragment key={`body-row-${column - key}-${key}`}>
                  <div
                    className={cx(styles['row-wrapper'], styles[`row-wrapper-${theme}`], {
                      [styles[`table-body-${theme}-row`]]: key % 2 === 1,
                    })}>
                    <div
                      onClick={() => {
                        handleShowAccordion(`${column - key}-${key}`);
                      }}
                      className={cx(styles[column.key], styles[`table-body-row`], styles[`table-body-row-${theme}`])}>
                      <div
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`table-body-cell-checkbox`],
                          styles[`table-body-cell-${theme}`],
                        )}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectSingleAccount(column);
                          }}>
                          <Checkbox checked={selectedAccounts.findIndex((acc) => acc === column.accountNo) !== -1} />
                        </div>
                      </div>
                      <div
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`bankName`],
                          styles[`table-body-cell-${theme}`],
                          styles[
                            `table-body-cell-${
                              showAccordion === `${column - key}-${key}` ? `opened-${theme}` : `closed-${theme}`
                            }`
                          ],
                        )}>
                        {column.bankName}
                        <div
                          className={cx(
                            styles[`table-body-lastTransactionDate`],
                            styles[`table-body-lastTransactionDate-${theme}`],
                          )}>
                          {column.hasNoData ? (
                            <FormattedMessage
                              id="organisms.activities.table.body.footer.noData"
                              defaultMessage="Henüz işlem gerçekleşmemiştir"
                            />
                          ) : (
                            renderFormattedTime(column.lastTransactionDate)
                          )}
                        </div>
                      </div>
                      <div
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`branchAndAccountNo`],
                          styles[`table-body-cell-${theme}`],
                        )}>
                        {column.branchAndAccountNo}
                      </div>
                      <div
                        className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`])}
                        style={{ textAlign: 'center' }}>
                        {column.currency}
                      </div>

                      <div
                        className={cx(styles[`table-body-cell`], styles[`title`], styles[`table-body-cell-${theme}`])}>
                        <Tooltip
                          title={column.title?.length > 25 ? column.title : ''}
                          position="top"
                          trigger="mouseenter">
                          {formattedLongText(column.title, 25)}
                        </Tooltip>
                      </div>

                      <div className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`])}>
                        {column.maturityEndDate ? renderFormattedDate(column.maturityEndDate) : ''}
                      </div>
                      <div
                        className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`])}
                        style={{ paddingRight: '40px', textAlign: 'right' }}>
                        {column.openingBalance === 0 ? '-' : formattedCurrency(column.openingBalance)}
                      </div>
                      <div
                        className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`])}
                        style={{ paddingRight: '40px', textAlign: 'right' }}>
                        {formattedCurrency(column.blockedAmount)}
                      </div>
                      <div
                        className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`])}
                        style={{ textAlign: 'right' }}>
                        {formattedCurrency(column.balance)}
                      </div>
                      {showGoDetailButton && (
                        <div
                          className={cx(styles['action'])}
                          onMouseDown={(e) => {
                            e.preventDefault();
                            local.setItem(
                              'savedFilters',
                              JSON.stringify({
                                selectedBankAccounts: [column.accountNo],
                                selectedCurrencies: [column.currency],
                                selectedBanks: [column.bankCode],
                                startDate: goDetailDateRange?.startDate,
                                endDate: goDetailDateRange?.endDate,
                              }),
                            );
                          }}>
                          <a href="/bank-activity" target={'_blank'} className={styles.goDetailLink}>
                            <span>
                              <FormattedMessage id="molecules.column.seeDetails.title" defaultMessage="Detaya Git" />
                            </span>
                            <Icon name="icon-navigation-east-24-px" className={cx(styles['action-icon'])} />
                          </a>
                        </div>
                      )}
                    </div>
                    {showAccordion === `${column - key}-${key}` && (
                      <ActivitiesDetailTable
                        column={column}
                        accountNo={column.accountNo}
                        startDate={defaultParams.startDate}
                        endDate={defaultParams.endDate}
                        description={defaultParams.description}
                        minAmount={defaultParams.minAmount}
                        maxAmount={defaultParams.maxAmount}
                        useAbsoluteValues={defaultParams.useAbsoluteValues}
                        incomeActivities={defaultParams.incomeActivities}
                        outgoingActivities={defaultParams.outgoingActivities}
                        bankCode={column.bankCode}
                        selectedBranches={column.branchCode}
                        selectedReceipts={selectedReceipts}
                        onSelectSingleReceipt={handleSelectSingleReceipt}
                        onSelectMultiReceipt={handleSelectMultiReceipts}
                        pageSizesArray={pageSizesArray}
                      />
                    )}
                  </div>
                  {/* <div
                    className={cx(styles['row-wrapper'], styles[`row-wrapper-${theme}`], styles[`table-body-footer`], {
                      [styles[`table-body-${theme}-row`]]: key % 2 === 1,
                    })}>
                    <div className={cx(styles[`table-body-footer-cell`], styles[`table-body-footer-cell-${theme}`])}>
                      <FormattedMessage
                        id="organisms.activities.table.body.footer.transactionCount"
                        defaultMessage="Hareket Sayısı"
                      />
                      {`: ${column.transactionCount}`}
                      {column.lastTransactionDate && (
                        <>
                          {' - '}
                          <FormattedMessage
                            id="organisms.activities.table.body.footer.lastTransactionDate"
                            defaultMessage="Son Hareket Tarihi"
                          />
                          {`: ${renderFormattedTime(column.lastTransactionDate)} `}
                          <span className={styles['table-body-footer-cell-lastTransactionDate']}>
                            ({moment(column.lastTransactionDate).fromNow()})
                          </span>
                        </>
                      )}
                    </div>
                  </div> */}
                </React.Fragment>
              );
            })}
          </div>
          {Math.ceil(totalCount / defaultParams.pageSize) > 0 && (
            <div className={cx(styles['table-footer'], styles[`table-footer-${theme}`])}>
              <div className={cx(styles['table-footer-page-size'], styles[`table-footer-page-size-${theme}`])}>
                <FormattedMessage id="molecule.activitiesTable.listItem" defaultMessage="Sayfa Başına Öğe" />{' '}
                <Select
                  placeholder={<b>{defaultParams.pageSize < 99999 ? defaultParams.pageSize : 'Max.'}</b>}
                  selected={defaultParams.pageSize}
                  className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}>
                  {pageSizesArray.map((item) => (
                    <SelectItem
                      key={`filters-currency-item-${item}`}
                      className={styles['filters-select-item']}
                      onClick={() => handleChangePageSize(item)}>
                      {item < 99999 ? item : 'Max.'}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className={cx(styles['table-footer-page-info'])}>
                <span>
                  {' '}
                  {
                    <FormattedMessage id="molecules.revenuExpensePanel.totalLanguageId" defaultMessage="Toplam" />
                  } : {Math.ceil(totalCount / defaultParams.pageSize)}{' '}
                  <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />{' '}
                </span>
              </div>
              <div className={styles['table-footer-page-changer']}>
                {defaultParams.pageNumber}.{' '}
                <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />
                <div className={styles['table-footer-page-changer-wrapper']}>
                  <div
                    className={cx(styles['table-footer-page-changer-button'], {
                      [styles['table-footer-page-changer-button-disabled']]: defaultParams.pageNumber === 1,
                    })}
                    onClick={prevPage}>
                    <Icon name="icon-navigation-chevron-left-24-px" />
                  </div>
                  <div
                    className={cx(styles['table-footer-page-changer-button'], {
                      [styles['table-footer-page-changer-button-disabled']]:
                        defaultParams.pageNumber === Math.ceil(totalCount / defaultParams.pageSize),
                    })}
                    onClick={nextPage}>
                    <Icon name="icon-navigation-chevron-right-24-px" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(ActivitiesTable);
