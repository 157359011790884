import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

import { checkUri } from '../utils';

class Apollo {
  constructor({ uri, headers }) {
    this.client = null;
    checkUri(uri, 'Apollo');
    this.init(uri, headers);
  }

  init(uri, headers) {
    this.client = this.createApolloClient(uri, headers);
  }

  createApolloClient(uri, headers) {
    const httpLink = createHttpLink({
      uri,
    });

    const link = ApolloLink.from([httpLink]);

    return new ApolloClient({
      link: this.setHeaders(headers).concat(link),
      cache: new InMemoryCache(),
    });
  }

  setHeaders(params) {
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...params,
        },
      };
    });
  }
}

export default Apollo;
