import { local } from 'library/@e5p-request/helpers/index';

const FormattedCurrency = (currency) => {
  if (parseFloat(currency) === 0) {
    return '0.00';
  }
  const lang = local.getItem('@ep-language');
  const formattedPrice = new Intl.NumberFormat(`${lang}-${lang.toUpperCase()}`, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(currency);

  return formattedPrice;
};

export default FormattedCurrency;
