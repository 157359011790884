import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Text = ({
  tag = 'div',
  title,
  subtitle,
  subtitle2,
  body,
  body2,
  button,
  caption,
  breadcrumb,
  className,
  children,
  style,
}) => {
  const { theme } = useAppSelector((state) => state.theme);

  const Tag = `${tag}`;

  const textStyle = cx({
    [styles.default]: true,
    [styles.h1]: tag === 'h1',
    [styles.h2]: tag === 'h2',
    [styles.h3]: tag === 'h3',
    [styles.h4]: tag === 'h4',
    [styles.h5]: tag === 'h5',
    [styles.h6]: tag === 'h6',
    [styles.subtitle]: subtitle,
    [styles.subtitle2]: subtitle2,
    [styles.body]: body,
    [styles.body2]: body2,
    [styles.button]: button,
    [styles.caption]: caption,
    [styles.breadcrumb]: breadcrumb,
    [styles.title]: title,
    [styles[theme]]: theme,
    [className]: className,
  });

  return (
    <Tag className={textStyle} style={style}>
      {children}
    </Tag>
  );
};

export default Text;
