import { gql } from '@apollo/client';

const bankAccountGroupsQ = (selectedVkns = []) => {
  return {
    query: gql`
      query($selectedVkns: [String]) {
        bankAccountGroupQuery {
          list(selectedVkns: $selectedVkns) {
            name
            vkn
            guid
          }
        }
      }
    `,
    variables: {
      selectedVkns,
    },
  };
};

export default bankAccountGroupsQ;
