import { gql } from '@apollo/client';

const currencies = (baseCurrency, targetCurrencies) => {
  return {
    query: gql`
      query($baseCurrency: String, $targetCurrencies: [String]) {
        currencyQuery {
          currencyRates(baseCurrency: $baseCurrency, targetCurrencies: $targetCurrencies) {
            baseCurrency
            rates {
              currency
              rate
            }
            updatedDate
          }
        }
      }
    `,
    variables: {
      baseCurrency,
      targetCurrencies,
    },
  };
};

export default currencies;
