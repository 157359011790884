import React from 'react';
import cx from 'classnames';

import styles from './styles.scss';

const Switch = ({ round, checked, onChecked, containerStyle, className }) => {
  return (
    <label className={cx(styles.switch, { [containerStyle]: containerStyle })}>
      <input type="checkbox" onClick={onChecked} />
      <span
        className={cx({
          [styles.slider]: true,
          [styles.round]: round,
          [styles.checked]: checked,
          [className]: className,
        })}></span>
    </label>
  );
};

export default Switch;
