import { gql } from '@apollo/client';

const accountInformations = (
  selectedVkns = [],
  currency = '',
  selectedBankAccountGroups,
  onlyHasTransaction,
  startDate,
  endDate,
) => {
  return {
    query: gql`
      query(
        $selectedVkns: [String]
        $selectedBanks: [Int]
        $currency: String
        $selectedBankAccountGroups: [Guid]
        $onlyHasTransaction: Boolean
        $startDate: DateTime
        $endDate: DateTime
      ) {
        bankAccountQuery {
          accountInformations(
            selectedVkns: $selectedVkns
            selectedBanks: $selectedBanks
            currency: $currency
            selectedBankAccountGroups: $selectedBankAccountGroups
            onlyHasTransaction: $onlyHasTransaction
            startDate: $startDate
            endDate: $endDate
          ) {
            uid
            bankAccountNo
            bankAccountTitle
            bankIconLogo
            bankCode
            bankName
            branchNo
            branchName
            currencySymbol
            currency
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      currency,
      selectedBankAccountGroups,
      onlyHasTransaction,
      startDate: typeof startDate === 'string' ? startDate : startDate.format('YYYY-MM-DDT00:00:00.000Z'),
      endDate: typeof endDate === 'string' ? endDate : endDate.format('YYYY-MM-DDT23:59:59.000Z'),
    },
  };
};

export default accountInformations;
