import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Button = ({
  children,
  onClick = () => {},
  loading,
  loader,
  transparent,
  active,
  disabled,
  className,
  buttonStyle,
  ...props
}) => {
  const { theme } = useAppSelector((state) => state.theme);

  const onhandleButtonClick = (e) => {
    if (!disabled || !loading) {
      onClick(e);
    }
  };

  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onhandleButtonClick}
      className={cx(styles['button-wrapper'], styles[`button-wrapper-${theme}`], {
        [styles['button-wrapper-transparent']]: transparent,
        [styles['button-wrapper-active']]: active,
        [styles['button-wrapper-disable']]: disabled,
      })}
      style={buttonStyle}>
      <div
        {...props}
        className={cx(styles.button, styles[`button-${theme}`], className, {
          [styles[`button-transparent`]]: transparent,
          [styles[`button-transparent-${theme}`]]: transparent,
          [styles[`button-active-${theme}`]]: transparent && active,
          [styles[`button-disable-${theme}`]]: disabled || loading,
        })}>
        {loading ? loader : children}
      </div>
    </button>
  );
};

export default Button;
