import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';
import cx from 'classnames';

import Widget from 'components/widget';
import Loader from 'components/loader';

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="sidebar.bankAccounts" defaultMessage="Banka Hesapları" />,
    path: '/bank-accounts',
  },
];

import request from 'library/@e5p-request';

import { useAppSelector } from 'src/redux/store';

import BankBalanceCard from 'containers/elements/bank-accounts/bank-card';
import Checkbox from 'components/checkbox/index';
import styles from './styles.scss';
import { Card } from 'components/index';
const { getBankBalances } = request;

const BankAccounts = ({ ...props }) => {
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [accounts, setAccount] = React.useState(null);
  const [accountType, setAccountType] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    setLoading(true);
    getBankBalances({
      selectedVkn,
      currency: '',
      selectedBankAccountGroups,
      accountType,
      pageNumber: 1,
      pageSize: 9999,
    })
      .then((res) => {
        const sortedAccounts = res.sort((a, b) => a.bankName.localeCompare(b.bankName));
        setAccount(sortedAccounts);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [selectedVkn, selectedBankAccountGroups, accountType]);

  const handleSelectAccountType = (type) => {
    setAccountType(type);
  };
  return (
    <>
      <Widget between>
        <Breadcrumb
          title={<FormattedMessage id="sidebar.bankAccounts" defaultMessage="Banka Hesapları" />}
          breadcrumbs={breadcrumbs}
        />
        <div className={cx(styles['select-area'], styles[`select-area-${theme}`])}>
          <span
            onClick={() => {
              handleSelectAccountType(0);
            }}>
            <Checkbox checked={accountType === 0} />{' '}
            <FormattedMessage id="sidebar.bankAccounts.breadcrumb.checkbox.all" defaultMessage="Tümü" />
          </span>{' '}
          <span
            onClick={() => {
              handleSelectAccountType(2);
            }}>
            <Checkbox checked={accountType === 2} />{' '}
            <FormattedMessage id="sidebar.bankAccounts.breadcrumb.checkbox.deposit" defaultMessage="Vadeli" />
          </span>{' '}
          <span
            onClick={() => {
              handleSelectAccountType(1);
            }}>
            <Checkbox checked={accountType === 1} />{' '}
            <FormattedMessage id="sidebar.bankAccounts.breadcrumb.checkbox.checking" defaultMessage="Vadesiz" />
          </span>
        </div>
      </Widget>
      {loading ? (
        <Card>
          <Loader />
        </Card>
      ) : (
        <>
          <div className={styles.masonryWrapper}>
            {accounts !== null &&
              accounts.map((account, index) => {
                return (
                  <div className={styles.cardWrapper} key={index}>
                    <BankBalanceCard
                      selectedVkn={selectedVkn}
                      loading={loading}
                      loader={<Loader />}
                      account={account}
                      history={props.history}
                    />
                  </div>
                );
              })}
          </div>
          {accounts !== null && accounts.length === 0 && (
            <div className={cx(styles['emptyMessage'], styles[`emptyMessage-${theme}`])}>
              Belirlenen Kriterlere Göre Hesabınız Bulunmamaktadır
            </div>
          )}
        </>
      )}
    </>
  );
};

export default injectIntl(BankAccounts);
