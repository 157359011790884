import React from 'react';
import cx from 'classnames';

import styles from './styles.scss';

const Main = ({ children, ...props }) => {
  return (
    <div className={cx(styles.main)} {...props}>
      {children}
    </div>
  );
};

export default Main;
