import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';
import { Tooltip } from 'react-tippy';
import { useIntl } from 'react-intl';

import styles from './styles.scss';
import { Icon } from 'components/index';

const Card = ({
  children,
  contentClassNames,
  row,
  column,
  title,
  leftItem,
  centerItem,
  rightItem,
  className,
  wrap,
  loading,
  showLoadingOnData = false,
  loader,
  dataTag = 'card',
  info = '',
  ...props
}) => {
  const { theme } = useAppSelector((state) => state.theme);
  // <div className={styles['loader-container']}>{loader}</div>
  const intl = useIntl();
  const renderData = () => {
    if (loading) {
      if (showLoadingOnData) {
        return children ? (
          <>
            <div className={styles['loader-container']}>{loader}</div>
            {children}
          </>
        ) : null;
      }

      return loader;
    }

    return children ? children : null;
  };

  const renderHeader = () => {
    if (showLoadingOnData && (centerItem || leftItem || title || rightItem || info)) {
      return (
        <div className={cx(styles['card-header'])}>
          {title && <div className={cx(styles.title, styles[`title-${theme}`])}>{title}</div>}
          {leftItem && <div className={cx(styles['left-item'])}>{leftItem}</div>}
          {centerItem && <div className={cx(styles['center-item'])}>{centerItem}</div>}
          {rightItem && <div className={cx(styles['right-item'])}>{rightItem}</div>}
          {info && (
            <>
              <Tooltip title={intl.messages[info]} position="bottom" trigger="mouseenter">
                <Icon name={'icon-info-24-px'} />
              </Tooltip>
            </>
          )}
        </div>
      );
    }

    if (!loading && (centerItem || leftItem || title || rightItem || info)) {
      return (
        <div className={cx(styles['card-header'])}>
          {title && <div className={cx(styles.title, styles[`title-${theme}`])}>{title}</div>}
          {leftItem && <div className={cx(styles['left-item'])}>{leftItem}</div>}
          {centerItem && <div className={cx(styles['center-item'])}>{centerItem}</div>}
          {rightItem && <div className={cx(styles['right-item'])}>{rightItem}</div>}
          {info && (
            <>
              <Tooltip title={intl.messages[info]} position="bottom" trigger="mouseenter">
                <Icon name={'icon-info-24-px'} />
              </Tooltip>
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div data-tag={dataTag} className={cx(styles.card, styles[`card-${theme}`], className)} {...props}>
      {renderHeader()}
      <div
        className={cx(styles['card-content'], contentClassNames, {
          [styles['row']]: row,
          [styles['column']]: column,
          [styles['wrap']]: wrap,
        })}>
        {renderData()}
      </div>
    </div>
  );
};

export default Card;
