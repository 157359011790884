export { default as BankAccounts } from 'pages/bank-accounts';
export { default as BankActivity } from 'pages/bank-activity';
export { default as Banks } from 'pages/banks';
export { default as Dashboard } from 'pages/dashboard';
export { default as ForgotPassword } from 'pages/forgot-password';
export { default as ResetPassword } from 'pages/reset-password';
export { default as Login } from 'pages/login';
export { default as MoneyTransfers } from 'pages/money-transfers';
export { default as TangibleAssets } from 'pages/tangible-assets';
export { default as MaturityAccounts } from 'pages/maturity-accounts';
export { default as Verify } from 'pages/verify';
export { default as loginWithToken } from 'pages/loginWithToken';
export { default as DailySummary } from 'pages/daily-summary';
export { default as CompanyAccounts } from 'pages/company-accounts';
export { default as defaultCompanySelect } from 'pages/defaultCompanySelect';
export { default as ReceiptPreview } from 'pages/receipt-preview';
