import { createSlice } from '@reduxjs/toolkit';
import { selectCurrencyActions } from 'src/redux/actions';

const getDefaultCurrencyFromLocal = () => {
  const selectedCurrencyFromLocal = localStorage.getItem('selected-currency');

  try {
    if (selectedCurrencyFromLocal) {
      return JSON.parse(selectedCurrencyFromLocal);
    }
  } catch (error) {
    localStorage.removeItem('selected-currency');

    return {
      color: '#fbc34e',
      id: 100,
      name: 'Türk Lirası',
      summary: 'TRY',
      symbol: '₺',
    };
  }
};

const initialState = {
  defaultCurrencyItem: getDefaultCurrencyFromLocal(),
  currencyList: [],
  currencyListLoading: true,
};

export const selectCurrencyReducer = createSlice({
  extraReducers: (builder) => {
    builder.addCase(selectCurrencyActions.initializeCurrencies.pending, (state) => {
      state.currencyListLoading = true;
    });

    builder.addCase(selectCurrencyActions.initializeCurrencies.fulfilled, (state, action) => {
      const defaultCurrency = getDefaultCurrencyFromLocal();
      const { currencyList } = action.payload;

      const isContainDefaultCurrency = currencyList.find((c) => c.summary === defaultCurrency?.summary);

      if (!isContainDefaultCurrency) {
        state.defaultCurrencyItem = {
          ...currencyList[0],
        };
      } else {
        state.defaultCurrencyItem = {
          ...defaultCurrency,
        };
      }

      state.currencyList = currencyList;
      state.currencyListLoading = false;
    });
  },
  initialState,
  name: 'selectCurrency',
  reducers: {
    setSelectedCurrency: (state, action) => {
      const selectedCurrency = action.payload;

      state.defaultCurrencyItem = {
        ...selectedCurrency,
      };

      localStorage.setItem('selected-currency', JSON.stringify(selectedCurrency));
    },
    setCurrencyList: (state, action) => {
      state.currencies = action.payload;
    },
  },
});

export const { setSelectedCurrency } = selectCurrencyReducer.actions;

export default selectCurrencyReducer.reducer;
