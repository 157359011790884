import config from '../config';

const prefix = config['storage-prefix'];

class SessinStorage {
  getItem(key) {
    return sessionStorage.getItem(`${prefix}-${key}`);
  }

  setItem(key, params) {
    return sessionStorage.setItem(`${prefix}-${key}`, params);
  }

  removeItem(key) {
    return sessionStorage.removeItem(`${prefix}-${key}`);
  }

  clear() {
    sessionStorage.clear();
  }
}

export default new SessinStorage();
