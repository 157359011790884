import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from 'src/redux/store';
import { onFilterCompany, fadeOut } from 'src/redux/reducers/select-companies/select-companies-reducer';

import CompanySelectLabel from './components/company-select-label';
import CompanySelectItem from './components/company-select-item';
import CompanyFilterCard from './components/company-filter-card';
import { Select, Loader } from 'components';

import styles from './styles.scss';
import { local } from 'library/@e5p-request/helpers/index';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';

const SelectCompany = () => {
  const dispatch = useAppDispatch();
  const {
    selectCompanies: { selectedCompanies, formattedCompanies, loading, selectedVkn, fade },
    theme: { theme },
  } = useAppSelector((state) => state);
  const selectContainerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const [tempCompany, setTempCompany] = useState([]);
  const [searchTextbox, setSearchTextbox] = useState('');
  const clearSelectedItems = async () => {
    await handleCompany(formattedCompanies[0]);
    await clearApply();
    handleClose();
  };

  const handleCompany = async (company) => {
    await dispatch(fadeOut(3));

    if (company.isAll) {
      return setTempCompany([company]);
    }
    let result = tempCompany.find((item) => item.id === company.id);
    if (result === undefined) {
      let tempArray = tempCompany;
      tempArray = tempArray.filter((item) => item?.isAll === undefined);
      setTempCompany([...tempArray, company]);
    } else {
      let tempArray = tempCompany;
      tempArray = tempArray.filter((item) => item?.isAll === undefined);
      tempArray = tempCompany.filter((item) => item.id !== company.id);
      setTempCompany(tempArray);
    }
  };

  const filterApply = () => {
    dispatch(onFilterCompany(tempCompany));
  };

  const clearApply = () => {
    dispatch(onFilterCompany([formattedCompanies[0]]));
  };

  const selectedTreshold = () => {
    const isAllSelected = tempCompany.find((sc) => sc.isAll);
    if (isAllSelected) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    if (selectedVkn === null) return;
    let tempArray = [];
    selectedVkn.map((item) =>
      formattedCompanies.map((company) => company.vkn.map((value) => value === item && tempArray.push(company))),
    );
    const arrayUniqueByKey = [...new Map(tempArray.map((item) => [item['id'], item])).values()];
    if (arrayUniqueByKey.length > 0) {
      setTempCompany(arrayUniqueByKey);
      //dispatch(onFilterCompany(tempArray));
    } else {
      //setTempCompany(selectedVkn);
      //dispatch(onStartCompany(selectedVkn));
    }
  }, [formattedCompanies]);

  useEffect(() => {
    fade === 2 && setTempCompany(selectedCompanies);
  }, [fade]);

  useEffect(() => {
    if (selectedCompanies?.length > 0) {
      const arrayUniqueByKey = [...new Map(selectedCompanies.map((item) => [item['id'], item])).values()];
      local.setItemWithExpire('selectedCompanies', arrayUniqueByKey);
    }
  }, [selectedCompanies]);

  useEffect(() => {
    formattedCompanies?.length > 0 && local.setItemWithExpire('formattedCompanies', formattedCompanies);
  }, [selectedCompanies]);

  useEffect(() => {
    selectedVkn?.length >= 0 && local.setItemWithExpire('selectedVkn', selectedVkn);
  }, [selectedVkn]);

  const handleClose = () => {
    if (selectContainerRef.current && isOpen) {
      selectContainerRef.current.style.zIndex = 0;
      setIsOpen(false);
      document.dispatchEvent(new CustomEvent('closeSelects', { detail: true }));
    }
  };

  const handleClick = () => {
    if (selectContainerRef.current && isOpen) {
      handleClose();
    }

    if (selectContainerRef.current && !isOpen) {
      selectContainerRef.current.style.zIndex = 9999;
      setIsOpen(true);
    }
  };

  useOnClickOutside(selectContainerRef, () => handleClose());

  if (loading) {
    return (
      <div className={cx(styles['wrapper'], styles['wrapper-loading'], styles[`wrapper-${theme}`])}>
        <Loader />
      </div>
    );
  }

  return (
    <div ref={selectContainerRef} onClick={handleClick} className={cx(styles.wrapper, styles[`wrapper-${theme}`])}>
      <Select
        portalBlur
        multiple
        placeholder={<CompanySelectLabel />}
        placeholderClassName={styles['sidebar-select-company-placeholder']}
        selected={selectedCompanies}
        selectedTreshold={selectedTreshold()}
        className={cx(styles['sidebar-select-company'], styles[`sidebar-select-company-${theme}`])}
        selectClassName={styles['sidebar-select-company-container']}
        clearSelectedItems={() => clearSelectedItems()}>
        <CompanyFilterCard
          filterApplyButton={() => filterApply()}
          filterCompanyText={(text) => setSearchTextbox(text)}
          searchText={searchTextbox}
        />
        <CompanySelectItem
          items={formattedCompanies}
          selectedItems={tempCompany}
          onHandleCompany={(item) => handleCompany(item)}
          searchText={searchTextbox}
        />
      </Select>
    </div>
  );
};
export default SelectCompany;
