import React from 'react';
import cx from 'classnames';

import request from 'library/@e5p-request';

import { Card, Loader, Select, SelectItem } from 'components';

import formattedCurrency from 'utils/formatted-currency';
import { FormattedMessage } from 'react-intl';

const { getCurrencyRates, getBanks } = request;

import { useAppSelector } from 'src/redux/store';
import moment from 'moment-timezone';

import styles from './styles.scss';
import { SingleDatePicker } from 'components/datepicker/datepicker';

const BalanceConvertCard = () => {
  const lang = localStorage.getItem('@ep-language');

  const {
    selectCurrency: { defaultCurrencyItem, currencyList, currencyListLoading },
    selectGroups: { selectedGroupLoading, selectedGroupTotalBalance, selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [currencyRatesloading, setCurrencyRatesLoading] = React.useState(false);

  const [selectedCurrency, setSelectedCurrency] = React.useState(null);
  const [requestTimeoutList, setRequestTimeOutList] = React.useState([]);

  const [rate, setRate] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [balance, setBalance] = React.useState(0);

  React.useEffect(() => {
    setSelectedCurrency(currencyList[0]);
  }, [currencyList]);

  React.useEffect(() => {
    if (requestTimeoutList.length > 1) {
      for (let i = 0; i < requestTimeoutList.length - 1; i++) {
        const timeOut = requestTimeoutList[i];

        clearTimeout(timeOut);
      }
    }
  }, [requestTimeoutList]);

  React.useEffect(() => {
    setIsLoading(true);
    getBanks(
      selectedVkn,
      defaultCurrencyItem?.summary,
      true,
      selectedBankAccountGroups,
      selectedDate?.format('YYYY-MM-DD'),
    )
      .then((res) => {
        setBalance(res[0].totalBalance);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedDate, selectedBankAccountGroups, defaultCurrencyItem?.summary, selectedCurrency?.summary]);

  React.useEffect(() => {
    if (defaultCurrencyItem?.summary && selectedCurrency?.summary) {
      setCurrencyRatesLoading(true);

      const timeOut = setTimeout(() => {
        getCurrencyRates(defaultCurrencyItem.summary, [selectedCurrency.summary])
          .then((res) => {
            setRate(res.rates[0].rate?.toFixed(2));
            setCurrencyRatesLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setCurrencyRatesLoading(false);
          });
      }, 1000);

      /**
       * sitenin tamamını etkileyen currencyList değiştiğinde bu bölümde selectedCurrency, globalde ise defaultCurrencyItem değişiyor
       * bu nedenle useEffect 2 kez çalışıp 2 kere istek atıyor, bu da response süresine bağlı olarak bazen yanlış sonuçları ekrana getirebiliyor
       * bu nedenle requestleri handle etmek için setTimeout kullanıldı buraya gelen requestler bir array içine atıldı
       * ardından requestTimeoutList'i dinleyen useEffect içinde sonuncu request calısacak şekilde handle edildi
       */
      setRequestTimeOutList([...requestTimeoutList, timeOut]);
    }
  }, [defaultCurrencyItem?.summary, selectedCurrency?.summary]);

  const onChange = (value) => {
    setSelectedCurrency(value);
  };

  const isUSATimeZone = React.useMemo(() => {
    const tz = moment.tz.guess();

    if (tz.indexOf('America') !== -1) {
      return true;
    }

    return false;
  }, []);

  const renderFilters = () => {
    return (
      <div className={styles.filterArea}>
        <SingleDatePicker
          label={<FormattedMessage id="molecule.dropdown.chooseDate" defaultMessage="Tarih Seçin" />}
          dateFormat={isUSATimeZone ? 'MM.DD.YYYY' : 'DD.MM.YYYY'}
          icon={<i className="icon-icon-action-date" />}
          onChange={(date) => setSelectedDate(date)}
          className={styles.filterDate}
          dateProp={selectedDate}
        />
        <Select
          placeholder={selectedCurrency?.summary}
          className={cx(styles['card-select-currency'], styles[`card-select-currency-${theme}`])}>
          {currencyList.map((currency, index) => (
            <SelectItem
              key={`currency-item-${index}`}
              value={currency.summary}
              isActive={selectedCurrency?.summary === currency.summary}
              onClick={() => {
                onChange(currency);
              }}>
              {currency.summary}
            </SelectItem>
          ))}
        </Select>
      </div>
    );
  };

  const loading = currencyListLoading || currencyRatesloading || selectedGroupLoading || isLoading;
  const renderBalance = balance ?? selectedGroupTotalBalance;

  return (
    <>
      <Card
        loading={loading}
        loader={<Loader />}
        className={styles.card}
        contentClassNames={styles['card-content']}
        leftItem={(renderBalance >= 0 || renderBalance <= 0) && renderFilters()}
        info={'info.dashboard.totalBalance'}>
        {(renderBalance >= 0 || renderBalance <= 0) && rate ? (
          <>
            <div className={cx(styles['card-balance-2'], styles[`card-balance-2-${theme}`])}>
              {lang === 'tr' ? (
                <>
                  {formattedCurrency(renderBalance)}
                  <span>{selectedCurrency && `${defaultCurrencyItem?.symbol}`}</span>
                </>
              ) : (
                <>
                  <span>{selectedCurrency && `${defaultCurrencyItem?.symbol}`}</span> {formattedCurrency(renderBalance)}
                </>
              )}
            </div>
            <div className={cx(styles['card-balance'], styles[`card-balance-${theme}`])}>
              {lang === 'tr' ? (
                <>
                  {formattedCurrency(renderBalance / rate)}
                  <span>{selectedCurrency && `${selectedCurrency.symbol}`}</span>
                </>
              ) : (
                <>
                  <span>{selectedCurrency && `${selectedCurrency.symbol}`}</span>{' '}
                  {formattedCurrency(renderBalance / rate)}
                </>
              )}
            </div>

            <div className={cx(styles['card-balance-exchange'], styles[`card-balance-exchange-${theme}`])}>
              <span>
                {`1 ${selectedCurrency?.summary} `} = {` ${formattedCurrency(rate)} ${defaultCurrencyItem?.summary}`}
              </span>
            </div>
          </>
        ) : (
          <div className={cx(styles['card-balance-exchange'], styles[`card-balance-exchange-${theme}`])}>
            <FormattedMessage
              id="dashboard.convert.card.info"
              defaultMessage="Bankanın güncel bakiye bilgisi alınamadı."
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default BalanceConvertCard;
