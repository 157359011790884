import React, { useMemo } from 'react';

import cx from 'classnames';
import moment from 'moment-timezone';

import { FormattedMessage, injectIntl } from 'react-intl';
import SelectCurrencies from 'containers/elements/banks-activities/select-currencies';
// import SelectAccountType from 'containers/elements/banks-activities/select-account-type';
import SelectBanks from 'containers/elements/banks-activities/select-banks';
import SelectBankAccounts from 'containers/elements/banks-activities/select-bank-accounts';

import { Widget, Card, Button, Datepicker, Loader, Input } from 'components';

import { useAppSelector } from 'src/redux/store';
import { useDebounce } from 'src/hooks/useDebounce';

import styles from './styles.scss';
import { Checkbox, Text } from 'components/index';

const Filters = ({
  intl,
  filterParams,
  setFilterParams,
  onClear,
  onApplyFilter,
  hasActivityAccounts = [],
  accountInformations,
  bankAccountOptionsLoading,
  getBankAccounts,
}) => {
  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
  } = useAppSelector((state) => state);

  const [banksWithAccountsList, setBanksWithAccountsList] = React.useState([]);

  const [descriptionText, setDescriptionText] = React.useState('');
  const [accountTitleText, setAccountTitleText] = React.useState('');
  const [minAmountText, setMinAmountText] = React.useState(null);
  const [maxAmountText, setMaxAmountText] = React.useState(null);

  const descriptionDebouncedText = useDebounce(descriptionText, 750);
  const accountTitleDebouncedText = useDebounce(accountTitleText, 750);
  const minAmountDebouncedText = useDebounce(minAmountText, 750);
  const maxAmountDebouncedText = useDebounce(maxAmountText, 750);

  const isUSATimeZone = useMemo(() => {
    const tz = moment.tz.guess();

    if (tz.indexOf('America') !== -1) {
      return true;
    }

    return false;
  }, []);

  React.useEffect(() => {
    setFilterParams((prevState) => ({
      ...prevState,
      description: descriptionDebouncedText,
    }));
  }, [descriptionDebouncedText]);

  React.useEffect(() => {
    setFilterParams((prevState) => ({
      ...prevState,
      accountTitle: accountTitleDebouncedText,
    }));
  }, [accountTitleDebouncedText]);

  React.useEffect(() => {
    setFilterParams((prevState) => ({
      ...prevState,
      minAmount: minAmountDebouncedText,
    }));
  }, [minAmountDebouncedText]);

  React.useEffect(() => {
    setFilterParams((prevState) => ({
      ...prevState,
      maxAmount: maxAmountDebouncedText,
    }));
  }, [maxAmountDebouncedText]);

  const onChangeDatePicker = ({ endDate, startDate }) => {
    setFilterParams((prevState) => ({ ...prevState, endDate, startDate }));
  };

  const onChangeDescriptionText = (event) => {
    const { value, validity } = event.target;

    if (!validity.valid) {
      return;
    }

    setDescriptionText(value);
  };

  const onChangeAccountTitleText = (event) => {
    const { value, validity } = event.target;

    if (!validity.valid) {
      return;
    }

    setAccountTitleText(value);
  };

  const onChangeMinAmountText = (event) => {
    const { value, validity } = event.target;

    if (!validity.valid) {
      return;
    }

    if (value) {
      setMinAmountText(value);
    } else {
      setMinAmountText(null);
    }
  };

  const onChangeMaxAmountText = (event) => {
    const { value, validity } = event.target;

    if (!validity.valid) {
      return;
    }

    if (value) {
      setMaxAmountText(value);
    } else {
      setMaxAmountText(null);
    }
  };

  const onChangeCurrencies = (selectedCurrencies) => {
    setFilterParams((prevState) => ({ ...prevState, selectedCurrencies }));
  };

  const onChangeBanks = (selectedBanks) => {
    setFilterParams((prevState) => ({ ...prevState, selectedBanks, selectedBankAccounts: [] }));
  };

  const onChangeBankAccounts = (selectedBankAccounts) => {
    setFilterParams((prevState) => ({ ...prevState, selectedBankAccounts }));
  };

  React.useEffect(() => {
    if (selectedVkn === null) return;

    setFilterParams((prevState) => ({
      ...prevState,
      selectedCompanies: selectedVkn,
      selectedBankAccountGroups: selectedBankAccountGroups,
    }));

    onApplyFilter();
  }, [selectedBankAccountGroups.length, selectedVkn?.length]);

  React.useEffect(() => {
    let accountInformationsByCurrencies = accountInformations ? [...accountInformations] : [];

    if (filterParams.selectedCurrencies?.length > 0) {
      accountInformationsByCurrencies = accountInformations.filter((accountInformation) => {
        const hasSelectedCurrency = filterParams.selectedCurrencies.find(
          (selectedCurreny) => selectedCurreny === accountInformation.currency,
        );

        if (hasSelectedCurrency) {
          return accountInformation;
        }
      });
    }

    if (filterParams.selectedBanks?.length > 0) {
      const accountInformationsByBanks = accountInformationsByCurrencies.filter((accountInformation) => {
        const hasSelectedBank = filterParams.selectedBanks.find(
          (selectedBank) => selectedBank === accountInformation.bankCode,
        );

        if (hasSelectedBank) {
          return accountInformation;
        }
      });

      setBanksWithAccountsList(groupAccountsByBank(accountInformationsByBanks));
    } else {
      setBanksWithAccountsList(groupAccountsByBank(accountInformationsByCurrencies));
    }
  }, [
    accountInformations,
    filterParams.selectedCurrencies,
    filterParams.selectedBanks,
    filterParams.onlyHasTransaction,
    hasActivityAccounts,
  ]);

  const groupAccountsByBank = (accountItems = []) => {
    const flag = {};
    const grouppedItems = [];

    for (const accountItem of accountItems) {
      if (!flag[accountItem.bankCode]) {
        flag[accountItem.bankCode] = true;

        const item = {
          bankCode: accountItem.bankCode,
          bankName: accountItem.bankName,
          bankIconLogo: accountItem.bankIconLogo,
          accounts: accountItems.filter((acc) => acc.bankCode === accountItem.bankCode),
        };

        grouppedItems.push(item);
      }
    }

    return grouppedItems;
  };

  const clearFilter = () => {
    setDescriptionText('');
    setAccountTitleText('');
    setMinAmountText(null);
    setMaxAmountText(null);

    onClear();
  };

  return (
    <Widget title={<FormattedMessage id="molecule.accountActivities.accountFilter" defaultMessage="Hesap Filtrele" />}>
      <Card loader={<Loader />} className={cx(styles['card'])} row>
        <div className={cx(styles['card'])}>
          <div className={cx(styles['card-selectItem'])}></div>
          <div className={cx(styles['filter-input'])}>
            <div className={cx(styles['second-group'], styles['basis-group'])}>
              <Datepicker
                label={<FormattedMessage id="molecule.dropdown.chooseDateRange" defaultMessage="Tarih Aralığı Seçin" />}
                icon={<i className="icon-icon-action-date" />}
                defaultDate={{
                  startDate: filterParams.startDate,
                  endDate: filterParams.endDate,
                }}
                onChange={(date) => onChangeDatePicker(date)}
                className={cx(styles['filter-items'], styles['date-filter-item'])}
                dateFormat={isUSATimeZone ? 'MM.DD.YYYY' : 'DD.MM.YYYY'}
              />
              <div className={styles['input-container']}>
                <Input
                  onChange={onChangeDescriptionText}
                  value={descriptionText}
                  name="description"
                  className={styles['filter-input-item']}
                  placeholder={intl.formatMessage({
                    id: 'molecule.accountActivities.description',
                    defaultMessage: 'Açıklama',
                  })}
                />
                <Input
                  onChange={onChangeAccountTitleText}
                  value={accountTitleText}
                  name="accountTitle"
                  className={styles['filter-input-item']}
                  placeholder={intl.formatMessage({
                    id: 'molecule.accountActivities.accountTitle',
                    defaultMessage: 'Hesap Adı',
                  })}
                />
              </div>
            </div>

            <div className={cx(styles['second-group'], styles['basis-group-banks'])}>
              <SelectCurrencies
                selectedCurrencies={filterParams.selectedCurrencies}
                onChange={(params) => onChangeCurrencies(params)}
              />
              <div className={styles['input-container']}>
                <Input
                  type="number"
                  step="0.01"
                  onChange={onChangeMinAmountText}
                  value={minAmountText}
                  name="minAmount"
                  className={styles['filter-input-item']}
                  pattern="[0-9]*"
                  placeholder={intl.formatMessage({
                    id: 'molecule.accountActivities.minAmount',
                    defaultMessage: 'Min. Tutar',
                  })}
                />
                <Input
                  type="number"
                  step="0.01"
                  onChange={onChangeMaxAmountText}
                  value={maxAmountText}
                  name="maxAmount"
                  className={styles['filter-input-item']}
                  pattern="[0-9]*"
                  placeholder={intl.formatMessage({
                    id: 'molecule.accountActivities.maxAmount',
                    defaultMessage: 'Maks. Tutar',
                  })}
                />
              </div>
            </div>

            <div className={cx(styles['second-group'], styles['basis-group-banks'])}>
              <SelectBanks selectedBanks={filterParams.selectedBanks} onChange={(params) => onChangeBanks(params)} />
              <div className={cx(styles['button-container'])}>
                <Button className={cx(styles['button-group'])} onClick={clearFilter} style={{ padding: '12px' }}>
                  <FormattedMessage id="molecule.accountActivities.clearButton" defaultMessage="Temizle" />
                </Button>
              </div>
            </div>
            <div className={cx(styles['second-group'], styles['basis-group-banks'])}>
              <SelectBankAccounts
                currencies={filterParams.selectedCurrencies}
                banks={filterParams.selectedBanks}
                banksWithAccountsList={banksWithAccountsList}
                accounts={filterParams.selectedBankAccounts}
                loading={bankAccountOptionsLoading}
                onChange={(params) => onChangeBankAccounts(params)}
                className={cx(styles['filter-items'], styles['lastItem'])}
              />
              <div className={cx(styles['button-container'])}>
                <Button
                  className={cx(styles['button-group'])}
                  style={{ backgroundColor: '#0f62fe', color: '#fff', padding: '12px' }}
                  onClick={() => {
                    onApplyFilter();
                    getBankAccounts();
                  }}>
                  <FormattedMessage id="molecule.accountActivities.applyFilters" defaultMessage="Filtrele" />
                </Button>
              </div>
            </div>
          </div>

          <div className={styles.additionalFilters}>
            <div>
              <div className={styles['card-subContent-filter']}>
                <Checkbox
                  onClick={() => {
                    setFilterParams((prevState) => ({
                      ...prevState,
                      onlyHasTransaction: !filterParams.onlyHasTransaction,
                    }));
                  }}
                  checked={filterParams.onlyHasTransaction}
                />
                <Text>
                  <FormattedMessage
                    id="general.showOnlyActivity"
                    defaultMessage="Sadece hareketi olan hesapları göster"
                  />
                </Text>
              </div>
              <div className={styles['card-subContent-filter']}>
                <Checkbox
                  onClick={() => {
                    setFilterParams((prevState) => ({
                      ...prevState,
                      useAbsoluteValues: !filterParams.useAbsoluteValues,
                    }));
                  }}
                  checked={filterParams.useAbsoluteValues}
                />
                <Text>
                  <FormattedMessage
                    id="general.showOnlyAbsoluteValues"
                    defaultMessage="Mutlak değere göre tutar filtrele"
                  />
                </Text>
              </div>
            </div>
            <div>
              <div className={styles['card-subContent-filter']}>
                <Checkbox
                  onClick={() => {
                    setFilterParams((prevState) => ({
                      ...prevState,
                      incomeActivities: !filterParams.incomeActivities,
                    }));
                  }}
                  checked={filterParams.incomeActivities}
                />
                <Text>
                  <FormattedMessage id="general.incomeActivities" defaultMessage="Gelir Hareketleri" />
                </Text>
              </div>
              <div className={styles['card-subContent-filter']}>
                <Checkbox
                  onClick={() => {
                    setFilterParams((prevState) => ({
                      ...prevState,
                      outgoingActivities: !filterParams.outgoingActivities,
                    }));
                  }}
                  checked={filterParams.outgoingActivities}
                />
                <Text>
                  <FormattedMessage id="general.outgoingActivities" defaultMessage="Çıkış Hareketleri" />
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Widget>
  );
};

export default injectIntl(Filters);
