import React, { useState } from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import request from 'library/@e5p-request';

import Icon from 'components/icon';
import Loader from 'components/loader';
import Checkbox from 'components/checkbox';
import { Select, SelectItem } from 'components';

import formattedCurrency from 'utils/formatted-currency';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const { getAccountActivitiesDetail } = request;

const subTableColumns = [
  {
    name: <FormattedMessage id="general.receipt" defaultMessage="Dekont" />,
    key: 'receipt',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.operation" defaultMessage="İşlem" />,
    key: 'operation',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.operationDate" defaultMessage="İşlem Tarihi" />,
    key: 'operationDate',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.vkn" defaultMessage="Vkn" />,
    key: 'vkn',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.description" defaultMessage="Açıklama" />,
    key: 'description',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.balance" defaultMessage="Tutar" />,
    key: 'balance',
    isSort: true,
  },
  {
    name: <FormattedMessage id="general.netBalance" defaultMessage="Net Bakiye" />,
    key: 'totalBalance',
    isSort: true,
  },
];

const BankAccountActivitiesTable = ({
  accountNo,
  startDate,
  endDate,
  description,
  minAmount,
  maxAmount,
  useAbsoluteValues,
  incomeActivities,
  outgoingActivities,
  bankCode,
  selectedBranches,
  onSelectSingleReceipt,
  onSelectMultiReceipt,
  selectedReceipts,
  pageSizesArray,
  showDailyActivities = false,
  customColumns = null,
}) => {
  const {
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [loading, setLoading] = React.useState(true);

  const [accountActivities, setAccountActivities] = React.useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const handleChangePageSize = (size) => {
    setPageNumber(1);
    setPageSize(size);
  };

  const getActivitiesDetail = () => {
    if (selectedVkn === null) return;
    const requestParams = {
      selectedVkns: [...selectedVkn],
      selectedBanks: typeof bankCode === 'object' ? bankCode : [bankCode],
      selectedBranches: typeof selectedBranches === 'object' ? selectedBranches : [selectedBranches],
      incomeActivities: incomeActivities,
      outgoingActivities: outgoingActivities,
      selectedBankAccounts: typeof accountNo === 'object' ? accountNo : [accountNo],
      pageNumber,
      pageSize,
      startDate:
        !startDate || startDate === null
          ? moment().subtract(30, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0]
          : moment(startDate).format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
      endDate:
        !endDate || endDate === null
          ? moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0]
          : moment(endDate).format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    };

    if (description) {
      requestParams.description = description;
    }

    if (minAmount) {
      requestParams.minAmount = minAmount;
    }

    if (maxAmount) {
      requestParams.maxAmount = maxAmount;
    }

    if (useAbsoluteValues) {
      requestParams.useAbsoluteValues = useAbsoluteValues;
    }

    getAccountActivitiesDetail(requestParams)
      .then((res) => {
        setLoading(false);
        setTotalCount(res.totalCount);
        setAccountActivities(res.result);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  };

  React.useEffect(() => {
    getActivitiesDetail();
  }, []);
  React.useEffect(() => {
    getActivitiesDetail();
  }, [pageNumber, pageSize]);
  const getReceipt = async (item) => {
    try {
      const { bankCode, accountNo, transactionId } = item;
      // await getBankReceipt(bankCode, accountNo, transactionId);
      window.open(`/receipt-preview?bankCode=${bankCode}&accountNo=${accountNo}&transactionId=${transactionId}`);
      //setShowPreviewModal(item);
    } catch (error) {
      return error;
    }
  };

  const selectControl = (acc) => {
    const accInSelectedsLenght = acc.reduce((total, item) => {
      if (selectedReceipts.findIndex((e) => e.transactionId === item.transactionId) !== -1) {
        total += 1;
      }
      return total;
    }, 0);

    if (accInSelectedsLenght === acc.length) {
      return true;
    }
    return false;
  };

  const renderFormattedDate = (d) => {
    const tz = moment.tz.guess();

    if (tz.indexOf('America') !== -1) {
      return moment(d).format('MM.DD.YYYY hh:mm');
    }

    return moment(d).format('DD.MM.YYYY HH:mm');
  };

  const renderColumns = customColumns || subTableColumns;

  return (
    <>
      <div
        className={cx(
          styles['sub-table'],
          styles[`sub-table-${theme}`],
          showDailyActivities ? styles[`sub-table-extented`] : '',
        )}>
        <div
          className={cx(
            styles['table-header'],
            styles[`table-header-${theme}`],
            styles[`sub-table-header`],
            styles[`sub-table-header-${theme}`],
          )}>
          <div className={cx(styles[`header-checkbox`])}>
            <Checkbox
              onClick={() => {
                onSelectMultiReceipt(accountActivities, selectControl(accountActivities));
              }}
              checked={selectControl(accountActivities)}
            />
          </div>
          {!loading &&
            renderColumns.map((column, key) => (
              <div
                key={`header-cell-${column - key}-${key}`}
                className={cx(
                  styles[column.key],
                  styles[`table-header-cell`],
                  styles[`sub-table-header-cell`],
                  styles[`table-header-cell-${theme}`],
                )}
                style={
                  column.key === 'totalBalance' || column.key === 'balance'
                    ? { justifyContent: 'flex-end', paddingRight: '20px' }
                    : {}
                }>
                {column.name}
              </div>
            ))}
        </div>
        <div className={cx(styles['table-body'], styles[`table-body-${theme}`], styles['sub-table-body'])}>
          <>
            {!loading &&
              accountActivities.length > 0 &&
              accountActivities.map((column, key) => {
                return (
                  <div
                    key={`body-row-${column - key}-${key}`}
                    className={cx(styles['row-wrapper'], styles[`row-wrapper-${theme}`])}>
                    <div
                      key={`body-row-${column - key}-${key}`}
                      className={cx(styles[column.key], styles[`table-body-row`], styles[`table-body-row-${theme}`])}>
                      <div
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`table-body-cell`],
                          styles[`table-body-cell-${theme}`],
                          styles[`receipt-select`],
                        )}
                        style={{ cursor: 'pointer' }}>
                        <div
                          onClick={() => {
                            onSelectSingleReceipt(column);
                          }}>
                          <Checkbox
                            checked={selectedReceipts.findIndex((e) => e.transactionId === column.transactionId) !== -1}
                          />
                        </div>
                      </div>
                      <div
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`table-body-cell`],
                          styles[`table-body-cell-${theme}`],
                          styles[`receipt`],
                        )}
                        style={{ cursor: 'pointer' }}>
                        <div
                          className={cx(styles[`receipt-button`], styles[`receipt-button-${theme}`])}
                          onClick={() => {
                            getReceipt(column);
                          }}>
                          <Icon name="icon-navigation-receipt-long-black-24-dp" />{' '}
                        </div>
                      </div>
                      {showDailyActivities && (
                        <>
                          <div
                            title={column.branchCode}
                            className={cx(
                              styles[`table-body-cell`],
                              styles[`branchCode`],
                              styles[`table-body-cell-${theme}`],
                            )}>
                            {column.branchCode}
                          </div>
                          <div
                            title={column.accountNo}
                            className={cx(
                              styles[`table-body-cell`],
                              styles[`accountNo`],
                              styles[`table-body-cell-${theme}`],
                            )}>
                            {column.accountNo}
                          </div>
                        </>
                      )}
                      <div
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`table-body-cell-${theme}`],
                          styles[`operation`],
                          styles[`operation-margin`],
                        )}>
                        {column.amount[0] === '-' ? (
                          <>
                            <Icon name="icon-navigation-i-24-px" style={{ marginRight: '4px' }} />{' '}
                            <FormattedMessage id="general.outgoing" defaultMessage="Giden." />
                          </>
                        ) : (
                          <>
                            <Icon name="icon-navigation-d-24-px" style={{ marginRight: '4px' }} />{' '}
                            <FormattedMessage id="general.incoming" defaultMessage="Gelen." />
                          </>
                        )}
                      </div>
                      <div
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`table-body-cell-${theme}`],
                          styles[`operationDate`],
                        )}>
                        {renderFormattedDate(column.date)}
                      </div>

                      <div className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`], styles[`vkn`])}>
                        {column.vkn}
                      </div>
                      <div
                        title={column.description}
                        className={cx(
                          styles[`table-body-cell`],
                          styles[`description`],
                          styles[`table-body-cell-${theme}`],
                        )}>
                        {column.description}
                      </div>
                      <div
                        className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`])}
                        style={{
                          paddingRight: '12px',
                          textAlign: 'right',
                          color: column.amount[0] === '-' ? 'rgba(244, 67, 54, 0.87)' : 'rgba(19, 205, 60, 0.87)',
                        }}>
                        {formattedCurrency(column.amount)}
                      </div>
                      <div
                        className={cx(styles[`table-body-cell`], styles[`table-body-cell-${theme}`])}
                        style={{ paddingRight: '8px', textAlign: 'right', color: 'rgba(15, 98, 254, 0.87)' }}>
                        {formattedCurrency(column.totalBalance)}
                      </div>
                    </div>
                  </div>
                );
              })}

            {Math.ceil(totalCount / pageSize) > 0 && (
              <div className={cx(styles['table-footer'], styles[`table-footer-${theme}`])}>
                <div className={cx(styles['table-footer-page-size'], styles[`table-footer-page-size-${theme}`])}>
                  <FormattedMessage id="molecule.activitiesTable.listItem" defaultMessage="Sayfa Başına Öğe" />{' '}
                  <Select
                    placeholder={<b>{pageSize < 99999 ? pageSize : 'Max.'}</b>}
                    selected={pageSize}
                    className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}>
                    {pageSizesArray.map((item) => (
                      <SelectItem
                        key={`filters-currency-item-${item}`}
                        className={styles['filters-select-item']}
                        onClick={() => handleChangePageSize(item)}>
                        {item < 99999 ? item : 'Max.'}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
                <div className={cx(styles['table-footer-page-info'])}>
                  <span>
                    {' '}
                    {
                      <FormattedMessage id="molecules.revenuExpensePanel.totalLanguageId" defaultMessage="Toplam" />
                    } : {Math.ceil(totalCount / pageSize)}{' '}
                    <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />{' '}
                  </span>
                </div>
                <div className={styles['table-footer-page-changer']}>
                  {pageNumber}. <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />
                  <div className={styles['table-footer-page-changer-wrapper']}>
                    <div
                      className={cx(styles['table-footer-page-changer-button'], {
                        [styles['table-footer-page-changer-button-disabled']]: pageNumber === 1,
                      })}
                      onClick={() => {
                        setPageNumber(pageNumber - 1);
                      }}>
                      <Icon name="icon-navigation-chevron-left-24-px" />
                    </div>
                    <div
                      className={cx(styles['table-footer-page-changer-button'], {
                        [styles['table-footer-page-changer-button-disabled']]:
                          pageNumber === Math.ceil(totalCount / pageSize),
                      })}
                      onClick={() => {
                        setPageNumber(pageNumber + 1);
                      }}>
                      <Icon name="icon-navigation-chevron-right-24-px" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>

          {loading && <Loader />}

          {!loading && accountActivities.length === 0 && (
            <div className={styles.emptyView}>
              <FormattedMessage
                id="general.nullMessageForAccountActivity"
                defaultMessage="Hesap hareketi bulunmamaktadır."
              />
            </div>
          )}
        </div>
      </div>
      {/* <ReceiptPreview
        isOpen={showPreviewModal}
        handleToogle={setShowPreviewModal}
        dailyActivities={showDailyActivities}
      /> */}
    </>
  );
};

export default React.memo(BankAccountActivitiesTable);
