import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Authentication from 'utils/authentication';
import cx from 'classnames';

import Page from 'containers/layouts/page';
import Content from 'containers/layouts/content';
import Main from 'containers/layouts/main';

import Card from 'components/card';
import Widget from 'components/widget';
import Input from 'components/input';
import Button from 'components/button';

import Loader from 'components/loader';

import Logo from 'components/logo';

//import SelectLanguage from 'containers/elements/@commons/select-language';
//import SelectTheme from 'containers/elements/@commons/select-theme';

import Text from 'components/text';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const ForgotPassword = ({ history }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  const [error, setError] = useState(false);

  const onSubmit = () => {
    setError(false);
    if (!email) {
      setError(true);
      return;
    }

    if (isEmail(email)) {
      setLoading(true);
      try {
        Authentication.forgotPassword({ email });
        setSuccess(true);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } catch (error) {
        setLoading(false);
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  if (success) {
    return (
      <Page>
        <Content className={styles.content}>
          <Main>
            <Widget className={styles.widget} row={2} center>
              <Card
                loader={<Loader />}
                loading={loading}
                className={styles.cardWrapper}
                contentClassNames={styles.card}
                leftItem={<div />}
                rightItem={<div />}>
                <Logo className={styles.logo} />

                <Text className={styles.cardTitle}>
                  <FormattedMessage id="login.forgotPassword" defaultMessage="Şifremi Unuttum" />
                </Text>
                <Text className={styles.cardSubtitle}>
                  <FormattedMessage
                    id="login.forgotPassword.Subtitle"
                    defaultMessage="Parolanızı sıfırlama talimatlarını içeren bir e‑posta göndereceğiz."
                  />
                </Text>
                <div className={styles.buttonWrapped}>
                  <Text className={styles.submittedMessage}>
                    <Text className={styles['button-text']}>
                      <FormattedMessage
                        id="login.forgotPassword.submittedButton"
                        defaultMessage="E-posta adresinize gönderildi."
                      />
                    </Text>
                  </Text>
                </div>
              </Card>
            </Widget>
          </Main>
        </Content>
      </Page>
    );
  }

  return (
    <Page>
      <Content className={styles.content}>
        <Main>
          <Widget className={styles.widget} row={3} center>
            <Card
              loader={<Loader />}
              loading={loading}
              contentClassNames={styles.card}
              leftItem={<div />}
              rightItem={<div />}>
              <Logo className={styles.logo} />
              {error && (
                <Text className={styles.error}>
                  <FormattedMessage id="login.error" defaultMessage="Lütfen bilgilerinizi kontrol ediniz." />
                </Text>
              )}
              <Text className={styles.cardTitle}>
                <FormattedMessage id="login.forgotPassword" defaultMessage="Şifremi Unuttum" />
              </Text>
              <Text className={styles.cardSubtitle}>
                <FormattedMessage
                  id="login.forgotPassword.Subtitle"
                  defaultMessage="Parolanızı sıfırlama talimatlarını içeren bir e‑posta göndereceğiz."
                />
              </Text>
              <FormattedMessage
                id="login.forgotPassword.enterMailAdress"
                defaultMessage="Lütfen mail adresinizi giriniz.">
                {(msg) => {
                  return (
                    <Input
                      value={email}
                      placeholder={msg}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  );
                }}
              </FormattedMessage>

              <div className={styles.buttonWrapper}>
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                  className={cx(styles['backButton'], styles[`backButton-${theme}`])}>
                  <Text button className={cx(styles['backButton-text'], styles[`backButton-text-${theme}`])}>
                    <FormattedMessage id="login.forgotPassword.cancelButton" defaultMessage="Vazgeç" />
                  </Text>
                </Button>
                <Button onClick={onSubmit} className={cx(styles['submitButton'], styles[`submitButton-${theme}`])}>
                  <Text button className={cx(styles['submitButton-text'], styles[`submitButton-text-${theme}`])}>
                    <FormattedMessage id="login.forgotPassword.submitButton" defaultMessage="E-posta Gönder" />
                  </Text>
                </Button>
              </div>
            </Card>
          </Widget>
        </Main>
      </Content>
    </Page>
  );
};

export default ForgotPassword;
