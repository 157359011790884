import React from 'react';

import request from 'library/@e5p-request';

import formattedCurrency from 'utils/formatted-currency';
import { FormattedMessage, useIntl } from 'react-intl';

import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';
import { useModal } from 'src/hooks/useModal';

import styles from './styles.scss';
import Card from 'components/card';
import Loader from 'components/loader';
import Text from 'components/text';
import Modal from 'components/modal';
import moment from '../../../../../node_modules/moment-timezone/index';
import ActivitiesTable from 'containers/elements/banks-activities/activities-table/activities-table';
import DailySummaryModalDownloadArea from '../daily-summary-modal-download-area/daily-summary-modal-download-area';
import { Checkbox } from 'components/index';
import { Tooltip } from 'react-tippy';
import Icon from 'components/icon';

const DailySummaryTable = () => {
  const intl = useIntl();
  const lang = intl.locale;

  const {
    selectGroups: { selectedBankAccountGroups },
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [currencies, setCurrencies] = React.useState([]);
  const [currenciesLoading, setCurrenciesLoading] = React.useState(true);

  const [budgets, setBudgets] = React.useState([]);
  const [budgetsLoading, setBudgetsLoading] = React.useState(true);
  const [activitiesDetailTableData, setActivitiesDetailTableData] = React.useState(null);
  const [selectedReceipts, setSelectedReceipts] = React.useState([]);
  const [selectedAccounts, setSelectedAccounts] = React.useState([]);
  const [totalBalance, setTotalBalance] = React.useState();
  const [totalCount, setTotalCount] = React.useState();
  const [transactionIds, setTransactionIds] = React.useState([]);
  const [bankAccountsListByFilter, setBankAccountsListByFilter] = React.useState([]);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [filterParams, setFilterParams] = React.useState({
    selectedCompanies: selectedVkn,
    selectedBankAccountGroups: selectedBankAccountGroups,
    selectedBanks: [],
    selectedBankAccounts: [],
    selectedCurrencies: [],
    pageNumber: 1,
    onlyHasTransaction: false,
    useAbsoluteValues: false,
    incomeActivities: true,
    outgoingActivities: true,
    startDate: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
    endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
    pageSize: 20,
    description: '',
    accountTitle: '',
    minAmount: null,
    maxAmount: null,
    deneme: null,
  });

  const { isShowing, toggle } = useModal();

  React.useEffect(() => {
    if (!isShowing) {
      setFilterParams((prevState) => ({ ...prevState, pageNumber: 1 }));
      setBankAccountsListByFilter([]);
      setTotalBalance(0);
      setTotalCount(0);
      setSelectedReceipts([]);
    }
  }, [isShowing]);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    setCurrenciesLoading(true);
    request
      .currencies(selectedVkn, selectedBankAccountGroups)
      .then((res) => {
        setCurrencies(res);
        setCurrenciesLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCurrenciesLoading(false);
      });

    request
      .getBudgetDailyBalances(selectedVkn, selectedBankAccountGroups)
      .then((res) => {
        setBudgets(res);
        setBudgetsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setBudgetsLoading(false);
      });
  }, [selectedVkn, selectedBankAccountGroups]);

  const renderTableBody = () => {
    return budgets.map((budget, index) => (
      <tr
        key={`${budget.bankCode}-${index}`}
        className={cx('', {
          [styles[`table-body-${theme}-row`]]: index % 2 === 0,
          [styles[`table-body-totalBalanceRow`]]: !budget.bankName,
        })}>
        {!budget.bankName ? (
          <td className={styles['table-cell']}>
            <Text
              className={cx(
                styles['table-cell-text'],
                styles['table-cell-textBankName'],
                styles['table-cell-textTotalBalance'],
              )}>
              <FormattedMessage id="dailySummary.totalBalance" defaultMessage="Toplam Bakiye" />
            </Text>
          </td>
        ) : (
          <td className={styles['table-cell']}>
            <Text className={cx(styles['table-cell-text'], styles['table-cell-textBankName'])}>{budget.bankName}</Text>
          </td>
        )}

        {currencies.map((currency, key) => {
          const balance = budget.balances.find((balance) => balance.currency === currency.summary);
          return balance ? (
            <td
              key={`daily-summary-table-${key}`}
              className={cx(styles['table-cell'], {
                [styles['table-cell-hasBalance']]: balance.lastBalance,
                [styles['table-cell-rightAlign']]: balance.lastBalance !== null,
              })}
              onClick={() =>
                balance.lastBalance && priceCellOnClickHandler(balance, currency.summary, budget.bankCode)
              }>
              <Text
                className={cx(styles['table-cell-text'], {
                  [styles['table-cell-textTotalBalance']]: !budget.bankName,
                })}>
                {lang === 'tr' ? (
                  <>
                    {balance.lastBalance ? formattedCurrency(balance.lastBalance) : ' '}{' '}
                    {balance.lastBalance && <span style={{ color: currency.color }}>{currency.symbol}</span>}
                  </>
                ) : (
                  <>
                    {balance.lastBalance && <span style={{ color: currency.color }}>{currency.symbol}</span>}{' '}
                    {balance.lastBalance ? formattedCurrency(balance.lastBalance) : ' '}
                  </>
                )}
              </Text>
            </td>
          ) : undefined;
        })}
      </tr>
    ));
  };

  const priceCellOnClickHandler = (budget, currency, bankCode) => {
    setActivitiesDetailTableData({
      budget,
      currency,
      bankCode,
    });
    !isShowing && toggle();
  };

  React.useEffect(() => {
    if ((activitiesDetailTableData, isShowing)) {
      setModalLoading(true);
      request
        .getBankAccountsByFilter({
          ...filterParams,
          selectedCompanies: selectedVkn,
          selectedBankAccountGroups: selectedBankAccountGroups,
          selectedBanks: [activitiesDetailTableData.bankCode],
          selectedBankAccounts: [],
          selectedCurrencies: [activitiesDetailTableData.currency],
          startDate: moment().format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
          endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
        })
        .then((res) => {
          const newData = res.result.map((item) => {
            return {
              ...item,
              branchAndAccountNo: item.branchCode + ' / ' + item.accountNo,
            };
          });

          setBankAccountsListByFilter(newData);
          setTotalBalance(res.totalBalance);
          setTotalCount(res.totalCount);
          setSelectedReceipts([]);
        })
        .catch((err) => {
          console.log('err', err);
        })
        .finally(() => {
          setModalLoading(false);
        });
    }
  }, [activitiesDetailTableData, filterParams, isShowing]);

  const handleSelectSingleReceipt = React.useCallback(
    (column) => {
      const { accountNo, bankCode, transactionId } = column;
      const selectedData = { accountNo, bankCode, transactionId };

      if (selectedReceipts.findIndex((e) => e.transactionId === selectedData.transactionId) === -1) {
        setSelectedReceipts([...selectedReceipts, selectedData]);
        setTransactionIds([...transactionIds, selectedData.transactionId]);
      } else {
        const removedArray = selectedReceipts.filter((e) => e.transactionId !== selectedData.transactionId);
        const removedTransactionIdsArray = transactionIds.filter((t) => t !== selectedData.transactionId);

        setSelectedReceipts(removedArray);
        setTransactionIds(removedTransactionIdsArray);
      }
    },
    [selectedReceipts],
  );

  const handleSelectMultiReceipts = React.useCallback(
    (selectedAccountActivities, allSelect) => {
      const newData = selectedAccountActivities.reduce(
        (total, item) => {
          if (selectedReceipts.findIndex((e) => e.transactionId === item.transactionId) === -1) {
            const { accountNo, bankCode, transactionId } = item;
            const selectedData = { accountNo, bankCode, transactionId };
            total.push(selectedData);
          } else {
            if (allSelect) {
              total = total.filter((e) => e.transactionId !== item.transactionId);
            }
          }
          return total;
        },
        [...selectedReceipts],
      );

      setSelectedReceipts([...newData]);
    },
    [selectedReceipts],
  );

  const handleSelectSingleAccount = React.useCallback(
    (column) => {
      const { accountNo } = column;
      if (selectedAccounts.findIndex((acc) => acc === accountNo) === -1) {
        setSelectedAccounts([...selectedAccounts, accountNo]);
      } else {
        const removedArray = selectedAccounts.filter((acc) => acc !== accountNo);
        setSelectedAccounts([...removedArray]);
      }
    },
    [selectedAccounts],
  );

  const handleSelectMultiAccount = React.useCallback(
    (accounts, allSelect) => {
      const newData = accounts.reduce(
        (total, item) => {
          if (selectedAccounts.findIndex((acc) => acc === item.accountNo) === -1) {
            total.push(item.accountNo);
          } else {
            if (allSelect) {
              total = total.filter((acc) => acc !== item.accountNo);
            }
          }
          return total;
        },
        [...selectedAccounts],
      );
      setSelectedAccounts([...newData]);
    },
    [selectedAccounts],
  );

  const renderModalContent = () => {
    return (
      <>
        <Card loading={modalLoading} loader={<Loader />}>
          <div className={styles.modalFilterArea}>
            <div className={styles.checkbox}>
              <Checkbox
                onClick={() => {
                  setFilterParams((prevState) => ({
                    ...prevState,
                    onlyHasTransaction: !filterParams.onlyHasTransaction,
                  }));
                }}
                checked={filterParams.onlyHasTransaction}
              />
              <Text>
                <FormattedMessage
                  id="general.showOnlyActivity"
                  defaultMessage="Sadece hareketi olan hesapları göster"
                />
              </Text>
            </div>
            <DailySummaryModalDownloadArea
              selectedReceipts={selectedReceipts}
              selectedAccounts={selectedAccounts}
              filterParams={filterParams}
              activitiesDetailTableData={activitiesDetailTableData}
              dateRange={{
                startDate: moment().subtract(1, 'days').format('YYYY-MM-DDT00:00:00.000Z').split('+')[0],
                endDate: moment().format('YYYY-MM-DDT23:59:59.000Z').split('+')[0],
              }}
              transactionIds={transactionIds}
            />
          </div>
          <ActivitiesTable
            totalBalance={totalBalance}
            data={bankAccountsListByFilter}
            totalCount={totalCount}
            defaultParams={filterParams}
            handleSelectSingleReceipt={handleSelectSingleReceipt}
            handleSelectMultiReceipts={handleSelectMultiReceipts}
            selectedReceipts={selectedReceipts}
            onChange={onChangeActivitiesTable}
            handleChangePageSize={handleChangePageSize}
            showGoDetailButton
            handleSelectSingleAccount={handleSelectSingleAccount}
            handleSelectMultiAccount={handleSelectMultiAccount}
            selectedAccounts={selectedAccounts}
          />
        </Card>
      </>
    );
  };

  const onChangeActivitiesTable = (params) => {
    setFilterParams((prevState) => ({ ...prevState, pageNumber: params.pageNumber }));
  };

  const handleChangePageSize = (size) => {
    setFilterParams((prevState) => ({ ...prevState, pageSize: size, pageNumber: 1 }));
  };

  const loading = currenciesLoading || budgetsLoading;

  return (
    <>
      {isShowing && (
        <Modal
          theme={theme}
          hide={toggle}
          title={intl.formatMessage({
            id: 'dashboard.daily.activities.modal.title',
            defaultMessage: 'Hesap Hareketleri',
          })}
          closeOnClickOutside={!loading}
          showCloseIcon={!loading}
          className={styles.accountsModal}>
          {renderModalContent()}
        </Modal>
      )}

      <Card loading={loading} loader={<Loader />} className={styles['card-wrapper']}>
        <table className={cx(styles['table'], styles[`table-${theme}`])} style={{ display: 'inline-table' }}>
          <thead className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
            <tr style={{ textAlign: 'end' }}>
              <th colSpan={currencies.length + 1}>
                <Tooltip title={intl.messages['info.dailySummary.table']} position="bottom" trigger="mouseenter">
                  <Icon name={'icon-info-24-px'} />
                </Tooltip>
              </th>
            </tr>
            <tr>
              <th>
                <Text className={styles['table-cell-text']}>
                  <FormattedMessage id="dailySummary.bankName" defaultMessage="Banka Adı" />
                </Text>
              </th>

              {currencies.map((currency, key) => (
                <th key={`${currency.summary}-${key}`} className={styles['table-cell']}>
                  <div className={cx(styles['table-cell-wrapper'], styles[`table-cell-wrapper-${theme}`])}>
                    <Text className={styles['table-cell-text']}>{currency.summary}</Text>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={cx(styles['table-body'], styles[`table-body-${theme}`])}>{renderTableBody()}</tbody>

          <tfoot className={styles['table-footer']}>
            <tr className={styles['table-footer-row']}>
              <td className={styles['table-footer-row']}>
                {!budgets ||
                  (budgets.length === 0 && (
                    <Text className={styles['table-cell-noData']}>
                      <FormattedMessage id="general.emptyMessage" defaultMessage="Gösterilecek öğe bulunmamaktadır." />
                    </Text>
                  ))}
              </td>
            </tr>
          </tfoot>
        </table>
      </Card>
    </>
  );
};

export default React.memo(DailySummaryTable);
