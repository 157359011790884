import React from 'react';
import cx from 'classnames';
import request from 'library/@e5p-request';

import { FormattedMessage } from 'react-intl';

import Authentication from 'utils/authentication';

import { Select, SelectItem } from 'components';

import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const SelectCurrencies = ({ selectedCurrencies, onChange }) => {
  const {
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [currencies, setCurrencies] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const [defaultCurrencies, setDefaultCurrencies] = React.useState([]);

  React.useEffect(() => {
    if (selectedCurrencies.length === 0) {
      setDefaultCurrencies([]);
    }
  }, [selectedCurrencies]);

  React.useEffect(() => {
    if (selectedVkn === null) return;
    request
      .getCurrenciesOptions(selectedVkn)
      .then((res) => {
        setCurrencies(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [selectedVkn]);

  React.useEffect(() => {
    if (currencies.length > 0) {
      const selectedItems = selectedCurrencies.reduce((acc, cur) => {
        const arr = [...acc];
        const index = currencies.findIndex((i) => i.value === cur);

        if (index > -1) {
          arr.push(cur);
        }

        return arr;
      }, []);
      setDefaultCurrencies(selectedItems);
    }
  }, [currencies]);

  const session = Authentication.hasSession();

  if (loading & !session) {
    return <div />;
  }

  const onChangeOption = (currency) => {
    const isCurrencySelected = defaultCurrencies.find((dc) => dc === currency);

    if (isCurrencySelected) {
      const defaultCurrencyList = defaultCurrencies.filter((dc) => dc !== currency);

      setDefaultCurrencies(defaultCurrencyList);
      onChange(defaultCurrencyList);

      return;
    }

    const defaultCurrencyList = [...defaultCurrencies, currency];

    setDefaultCurrencies(defaultCurrencyList);
    onChange(defaultCurrencyList);
  };

  const clearSelectedItems = () => {
    setDefaultCurrencies([]);
    onChange([]);
  };

  const renderPlaceholder = () => {
    if (defaultCurrencies.length > 1) {
      return (
        <>
          {defaultCurrencies.length}{' '}
          <FormattedMessage id="organisms.activities.filter.currencySelectionLabel.selected" defaultMessage="seçim" />
        </>
      );
    }

    if (defaultCurrencies.length === 1) {
      return (
        <div className={cx(styles['select-item-selected'], styles[`select-item-selected-${theme}`])}>
          {defaultCurrencies[0]}
        </div>
      );
    }

    return <FormattedMessage id="molecule.dropdown.chooseCurrencyType" defaultMessage="Döviz Kuru Seçin" />;
  };

  return (
    <Select
      multiple
      placeholder={renderPlaceholder()}
      selected={defaultCurrencies}
      className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}
      selectClassName={styles['filters-select-currency-container']}
      clearSelectedItems={clearSelectedItems}>
      {currencies.map((currency, index) => (
        <SelectItem
          key={`filters-currency-item-${index}`}
          className={
            defaultCurrencies.find((dc) => dc === currency.value)
              ? cx(styles['filters-select-item'], styles['filters-select-item-active'])
              : styles['filters-select-item']
          }
          value={currency.value}
          isActive={defaultCurrencies.find((dc) => dc === currency.value)}
          onClick={(val) => {
            onChangeOption(val);
          }}>
          {currency.label}
        </SelectItem>
      ))}
    </Select>
  );
};

export default SelectCurrencies;
