// import config from '../config';
// const prefix = config['storage-prefix'];

class LocalStorage {
  getItem(key) {
    return localStorage.getItem(`${key}`);
  }

  setItem(key, params) {
    return localStorage.setItem(`${key}`, params);
  }

  removeItem(key) {
    return localStorage.removeItem(`${key}`);
  }

  clear() {
    localStorage.clear();
  }

  setItemWithExpire(key, data, timestamp = new Date().getTime() + 24 * 60 * 60 * 1000) {
    const item = {
      data,
      timestamp,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  getItemIfNotExpired(key) {
    const data = localStorage.getItem(`${key}`);
    if (!data) return undefined;

    const parsed = JSON.parse(data);
    const expireTime = new Date(parsed.timestamp).getTime();
    if (24 * 60 * 60 * 1000 > new Date().getTime() - expireTime) {
      return parsed;
    } else {
      return undefined;
    }
  }
}

export default new LocalStorage();
