import { gql } from '@apollo/client';

const bankAccountActivities = ({
  selectedVkns,
  selectedBankAccounts,
  endDate,
  startDate,
  pageSize,
  pageNumber,
  description,
  minAmount,
  maxAmount,
  selectedBanks,
  selectedBranches,
  useAbsoluteValues = false,
  incomeActivities = true,
  outgoingActivities = true,
}) => {
  return {
    query: gql`
      query(
        $selectedVkns: [String]
        $selectedBankAccounts: [String]
        $selectedBanks: [Int]
        $selectedBranches: [Int]
        $startDate: DateTime
        $endDate: DateTime
        $pageSize: Int
        $pageNumber: Int
        $description: String
        $minAmount: Decimal
        $maxAmount: Decimal
        $useAbsoluteValues: Boolean
        $incomeActivities: Boolean
        $outgoingActivities: Boolean
      ) {
        bankAccountActivityQuery {
          activityHistory(
            selectedVkns: $selectedVkns
            selectedBankAccounts: $selectedBankAccounts
            startDate: $startDate
            endDate: $endDate
            pageSize: $pageSize
            pageNumber: $pageNumber
            description: $description
            minAmount: $minAmount
            maxAmount: $maxAmount
            selectedBanks: $selectedBanks
            selectedBranches: $selectedBranches
            useAbsoluteValues: $useAbsoluteValues
            incomeActivities: $incomeActivities
            outgoingActivities: $outgoingActivities
          ) {
            totalCount
            result {
              date
              vkn
              description
              amount
              accountNo
              totalBalance
              bankCode
              transactionId
              branchCode
            }
          }
        }
      }
    `,
    variables: {
      selectedVkns,
      selectedBankAccounts,
      endDate,
      startDate,
      pageSize,
      pageNumber,
      description,
      minAmount,
      selectedBanks,
      maxAmount,
      selectedBranches,
      useAbsoluteValues,
      incomeActivities,
      outgoingActivities,
    },
  };
};

export default bankAccountActivities;
