import React, { useEffect, useState } from 'react';

import request from 'library/@e5p-request';

import { FormattedMessage, useIntl } from 'react-intl';

import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tippy';

import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';
const { getBanks, getAccountsByPlaidUid } = request;

import Filters from 'containers/elements/company-accounts/filters';

import { Select, SelectItem } from 'components';
import Card from 'components/card';
import Loader from 'components/loader';
import Text from 'components/text';
import Button from 'components/button';
import Widget from 'components/widget';
import Icon from 'components/icon';
import Modal from 'src/components/modal/modal';

import styles from './styles.scss';
import { useModal } from 'src/hooks/useModal';
const pageSizesArray = [20, 50, 100, 250, 500, 1000, 99999];

const CompanyAccountsTable = () => {
  const {
    selectCompanies: { selectedVkn },
    theme: { theme },
    selectGroups: { selectedBankAccountGroups },
    selectCurrency: { defaultCurrencyItem },
  } = useAppSelector((state) => state);
  const intl = useIntl();
  const { isShowing, toggle } = useModal();

  const [companyAccounts, setCompanyAccounts] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [companyAccountsLoading, setCompanyAccountsRefreshLoading] = useState(false);
  const [plaidData, setPlaidData] = useState();

  const [banks, setBanks] = useState([]);
  const [filterParams, setFilterParams] = useState({ selectedBanks: [], banks: [], status: null });
  const onClear = () => {
    setFilterParams({ banks: [], status: null });
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isPlaidModalDataLoading, setIsPlaidModalDataLoading] = useState(false);
  const [plaidModalData, setPlaidModalData] = useState([]);
  const [plaidModalPageNumber, setPlaidModalPageNumber] = useState(1);
  const [plaidModalPageSize, setPlaidModalPageSize] = useState(20);

  const handleChangePageSize = (size) => {
    setPageNumber(1);
    setPageSize(size);
  };

  const applyFilter = () => {
    setPageNumber(1);
    getCompanyAccounts();
  };

  useEffect(() => {
    if (selectedVkn === null) return;
    getCompanyAccounts();
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [selectedVkn, pageSize]);

  useEffect(() => {
    if (selectedVkn === null) return;
    if (!selectedVkn) return;
    getBanks(selectedVkn, defaultCurrencyItem?.summary, false, selectedBankAccountGroups)
      .then((res) => {
        setBanks(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedVkn === null) return;
    if (!selectedVkn) return;
    onClear();
    getBanks(selectedVkn, defaultCurrencyItem?.summary, false, selectedBankAccountGroups)
      .then((res) => {
        setBanks(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedVkn, selectedBankAccountGroups, defaultCurrencyItem?.summary]);

  useEffect(() => {
    if (plaidData) {
      localStorage.setItem('isConnectToPlaid', 'true');
      window.location.href = plaidData.redirectUrl;
    }
  }, [plaidData]);

  const getCompanyAccounts = async () => {
    setCompanyAccountsRefreshLoading(true);
    const response = await request.getCompanyAccount({
      selectedVkns: selectedVkn,
      selectedBanks: filterParams.selectedBanks,
      status: filterParams.status,
      pageNumber: pageNumber,
      pageSize: pageSize,
    });
    setCompanyAccountsRefreshLoading(false);
    setCompanyAccounts(response.result);
    setTotalCount(response.totalCount);
  };

  const handleRefresh = async ({ vkn, uid, onlyRefreshBool = true }) => {
    setRefreshLoading(true);

    const data = { vkn, oAuthType: 'PLAID', uid, onlyRefreshBool };

    try {
      const response = await request.connectToPlaid(data);

      setPlaidData(response?.data);
      setRefreshLoading(false);
    } catch (error) {
      setPlaidData(undefined);
      setRefreshLoading(false);

      toast.error(error.response?.data?.message, {
        duration: 4000,
        style: {
          fontFamily: '"ProximaNova-Medium", sans-serif',
        },
      });
    }
  };

  const uidOnClickHandler = async (bankName, vkn, uid) => {
    setIsPlaidModalDataLoading(true);
    setPlaidModalPageNumber(1);
    toggle();
    const bankCode = banks.find((bank) => bank.bankName === bankName)?.bankCode;

    try {
      if (bankCode) {
        const result = await getAccountsByPlaidUid(bankCode, vkn, uid);
        setPlaidModalData([...result]);
      }
    } catch (error) {
      setPlaidModalData([]);
      toast.error(
        intl.formatMessage({
          id: 'general.toast.error',
          defaultMessage: 'Bir hata oluştu, lütfen tekrar deneyin.',
        }),
        {
          duration: 4000,
          style: {
            fontFamily: '"ProximaNova-Medium", sans-serif',
          },
        },
      );
    } finally {
      setIsPlaidModalDataLoading(false);
    }
  };

  const renderModalContent = () => {
    if (isPlaidModalDataLoading) {
      return (
        <div className={styles['card-download-loader']}>
          <Loader />
        </div>
      );
    }

    if (!plaidModalData || plaidModalData.length === 0) {
      return (
        <Text>
          <FormattedMessage id="general.emptyMessage" defaultMessage="Gösterilecek öğe bulunmamaktadır." />
        </Text>
      );
    }

    return (
      <>
        <Card loading={isPlaidModalDataLoading} loader={<Loader />} className={styles['card-wrapper']}>
          <table className={cx(styles['table'], styles[`table-${theme}`])}>
            <thead className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
              <tr>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="general.companyName" defaultMessage="Şirket Adı" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="general.accountNumber" defaultMessage="Hesap No" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="companyAccounts.bankName" defaultMessage="Banka Adı" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="general.accountName" defaultMessage="Başlık" />
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody className={cx(styles['table-body'], styles[`table-body-${theme}`])}>
              {plaidModalData
                .slice((plaidModalPageNumber - 1) * plaidModalPageSize, plaidModalPageNumber * plaidModalPageSize)
                .map((data, index) => (
                  <tr key={`companyAccount-${index}`} className={cx(styles[`table-body-${theme}-row`])}>
                    <td className={styles['table-cell']}>
                      <Text className={cx(styles['table-cell-text'])}>{data.companyName}</Text>
                    </td>
                    <td className={styles['table-cell']}>
                      <Text className={cx(styles['table-cell-text'])}>{data.accountNo}</Text>
                    </td>
                    <td className={styles['table-cell']}>
                      <Text className={cx(styles['table-cell-text'])}>{data.bankName}</Text>
                    </td>
                    <td className={styles['table-cell']}>
                      <Text className={cx(styles['table-cell-text'])}>{data.title}</Text>
                    </td>
                  </tr>
                ))}
            </tbody>
            {Math.ceil(plaidModalData.length / plaidModalPageSize) > 0 && (
              <div className={cx(styles['table-page-footer'], styles[`table-page-footer-${theme}`])}>
                <div
                  className={cx(styles['table-page-footer-page-size'], styles[`table-page-footer-page-size-${theme}`])}>
                  <FormattedMessage id="molecule.activitiesTable.listItem" defaultMessage="Sayfa Başına Öğe" />
                  <Select
                    placeholder={<b>{plaidModalPageSize < 99999 ? plaidModalPageSize : 'Max.'}</b>}
                    selected={plaidModalPageSize}
                    className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}>
                    {pageSizesArray.map((item) => (
                      <SelectItem
                        key={`filters-currency-item-${item}`}
                        className={styles['filters-select-item']}
                        onClick={() => {
                          setPlaidModalPageNumber(1);
                          setPlaidModalPageSize(item);
                        }}>
                        {item < 99999 ? item : 'Max.'}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
                <div className={cx(styles['table-page-footer-page-info'])}>
                  <span>
                    {<FormattedMessage id="molecules.revenuExpensePanel.totalLanguageId" defaultMessage="Toplam" />} :{' '}
                    {Math.ceil(plaidModalData.length / plaidModalPageSize)}{' '}
                    <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />{' '}
                  </span>
                </div>
                <div className={styles['table-page-footer-page-changer']}>
                  {plaidModalPageNumber}. <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />
                  <div className={styles['table-page-footer-page-changer-wrapper']}>
                    <div
                      className={cx(styles['table-page-footer-page-changer-button'], {
                        [styles['table-page-footer-page-changer-button-disabled']]: plaidModalPageNumber === 1,
                      })}
                      onClick={() => {
                        plaidModalPageNumber > 1 && setPlaidModalPageNumber(plaidModalPageNumber - 1);
                      }}>
                      <Icon name="icon-navigation-chevron-left-24-px" />
                    </div>
                    <div
                      className={cx(styles['table-page-footer-page-changer-button'], {
                        [styles['table-page-footer-page-changer-button-disabled']]:
                          plaidModalPageNumber === Math.ceil(plaidModalData.length / plaidModalPageSize),
                      })}
                      onClick={() => {
                        plaidModalPageNumber !== Math.ceil(plaidModalData.length / plaidModalPageSize) &&
                          setPlaidModalPageNumber(plaidModalPageNumber + 1);
                      }}>
                      <Icon name="icon-navigation-chevron-right-24-px" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </table>
        </Card>
      </>
    );
  };

  const renderTableBody = () => {
    return companyAccounts.map((companyAccount, companyAccountIndex) => (
      <tr key={`companyAccount-${companyAccountIndex}`} className={cx(styles[`table-body-${theme}-row`])}>
        <td className={styles['table-cell']}>
          <Tooltip title={companyAccount.sonIslemMesaj} position="top" trigger="mouseenter">
            <Text
              className={cx(styles['table-cell-text'], {
                [styles['table-cell-warning']]: companyAccount.onlyRefresh,
                [styles['table-cell-success']]: companyAccount.sonIslemDurum,
                [styles['table-cell-error']]: !companyAccount.sonIslemDurum,
              })}>
              {companyAccount.bankaAdi}
            </Text>
          </Tooltip>
        </td>
        <td
          onClick={() => uidOnClickHandler(companyAccount.bankaAdi, companyAccount.vkn, companyAccount.uid)}
          className={cx(styles['table-cell'], styles['table-cell-plaidUid'])}>
          <Text className={cx(styles['table-cell-text'])}>{companyAccount.uid}</Text>
        </td>
        <td className={styles['table-cell']}>
          <Text className={cx(styles['table-cell-text'])}>{companyAccount.vkn}</Text>
        </td>
        <td className={styles['table-cell']}>
          <Text className={cx(styles['table-cell-text'])}>{companyAccount.sirketUnvan}</Text>
        </td>
        <td className={styles['table-cell']}>
          <Text className={cx(styles['table-cell-text'])}>{companyAccount.sirketKodu}</Text>
        </td>
        <td className={cx(styles['table-cell'], styles['table-cell-refreshButton'])}>
          <Text className={cx(styles['table-cell-text'], styles['table-cell-refreshButtonText'])}>
            {companyAccount.bankDataSource && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRefresh({
                    vkn: companyAccount.vkn,
                    uid: companyAccount.uid,
                    onlyRefreshBool: companyAccount.onlyRefreshBool,
                  });
                }}
                disabled={refreshLoading}
                className={styles['company-account-refresh-button']}>
                ⟳
              </Button>
            )}
          </Text>
        </td>
      </tr>
    ));
  };

  return (
    <Widget classname={companyAccountsLoading ? '' : styles['widget-companyAccounts']}>
      {isShowing && (
        <Modal
          theme={theme}
          hide={toggle}
          title={intl.formatMessage({
            id: 'companyAccounts.plaidModal.title',
            defaultMessage: 'UID İle Eşleşen Hesaplar',
          })}
          closeOnClickOutside={true}
          showCloseIcon={true}
          className={styles.plaidAccountsModal}>
          {renderModalContent()}
        </Modal>
      )}
      <Filters
        classname={styles['widget-companyAccounts-filters']}
        banks={banks}
        filterParams={filterParams}
        setFilterParams={(params) => setFilterParams(params)}
        onClear={onClear}
        onApplyFilter={() => applyFilter()}
      />
      &nbsp;
      <br />
      {companyAccounts.length > 0 ? (
        <Card loading={companyAccountsLoading} loader={<Loader />} className={styles['card-wrapper']}>
          <table className={cx(styles['table'], styles[`table-${theme}`])}>
            <thead className={cx(styles['table-header'], styles[`table-header-${theme}`])}>
              <tr>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="companyAccounts.bankName" defaultMessage="Banka Adı" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="companyAccounts.uid" defaultMessage="UID" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="companyAccounts.vkn" defaultMessage="Vkn" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="companyAccounts.title" defaultMessage="Ünvan" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="companyAccounts.companyCode" defaultMessage="Şirket Kodu" />
                  </Text>
                </th>
                <th>
                  <Text className={styles['table-cell-text']}>
                    <FormattedMessage id="companyAccounts.actions" defaultMessage="Plaid Relink" />
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody className={cx(styles['table-body'], styles[`table-body-${theme}`])}>{renderTableBody()}</tbody>
            {Math.ceil(totalCount / pageSize) > 0 && (
              <div className={cx(styles['table-page-footer'], styles[`table-page-footer-${theme}`])}>
                <div
                  className={cx(styles['table-page-footer-page-size'], styles[`table-page-footer-page-size-${theme}`])}>
                  <FormattedMessage id="molecule.activitiesTable.listItem" defaultMessage="Sayfa Başına Öğe" />
                  <Select
                    placeholder={<b>{pageSize < 99999 ? pageSize : 'Max.'}</b>}
                    selected={pageSize}
                    className={cx(styles['filters-select-currency'], styles[`filters-select-currency-${theme}`])}>
                    {pageSizesArray.map((item) => (
                      <SelectItem
                        key={`filters-currency-item-${item}`}
                        className={styles['filters-select-item']}
                        onClick={() => handleChangePageSize(item)}>
                        {item < 99999 ? item : 'Max.'}
                      </SelectItem>
                    ))}
                  </Select>
                </div>
                <div className={cx(styles['table-page-footer-page-info'])}>
                  <span>
                    {<FormattedMessage id="molecules.revenuExpensePanel.totalLanguageId" defaultMessage="Toplam" />} :{' '}
                    {Math.ceil(totalCount / pageSize)}{' '}
                    <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />{' '}
                  </span>
                </div>
                <div className={styles['table-page-footer-page-changer']}>
                  {pageNumber}. <FormattedMessage id="molecule.activitiesTable.page" defaultMessage="Sayfa" />
                  <div className={styles['table-page-footer-page-changer-wrapper']}>
                    <div
                      className={cx(styles['table-page-footer-page-changer-button'], {
                        [styles['table-page-footer-page-changer-button-disabled']]: pageNumber === 1,
                      })}
                      onClick={() => {
                        pageNumber > 1 && setPageNumber(pageNumber - 1);
                      }}>
                      <Icon name="icon-navigation-chevron-left-24-px" />
                    </div>
                    <div
                      className={cx(styles['table-page-footer-page-changer-button'], {
                        [styles['table-page-footer-page-changer-button-disabled']]:
                          pageNumber === Math.ceil(totalCount / pageSize),
                      })}
                      onClick={() => {
                        pageNumber !== Math.ceil(totalCount / pageSize) && setPageNumber(pageNumber + 1);
                      }}>
                      <Icon name="icon-navigation-chevron-right-24-px" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </table>
        </Card>
      ) : (
        <Text className={styles['table-cell-noData']}>
          <FormattedMessage id="general.emptyMessage" defaultMessage="Gösterilecek öğe bulunmamaktadır." />
        </Text>
      )}
      <Toaster position="top-center" reverseOrder={false} />
    </Widget>
  );
};

export default React.memo(CompanyAccountsTable);
