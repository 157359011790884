import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const Breadcrumb = ({ title, breadcrumbs }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div className={cx(styles.wrapper)}>
      <div className={cx(styles.title, styles[`title-${theme}`])}>{title}</div>
      <div className={cx(styles['breadcrumbs-list'])}>
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((item, index) => {
            const isLastItem = index === breadcrumbs.length - 1;
            return (
              <div key={index.toString()} className={cx(styles.breadcrumb, styles[`breadcrumb-${theme}`])}>
                {item.name} {!isLastItem && '/'}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Breadcrumb;
