//* itemin child'i varmı varsa nerede bulur
const childRouteFinder = (groupCompanies, children) =>
  Array.isArray(groupCompanies) ? 'groupCompanies' : Array.isArray(children) ? 'children' : null;

const companyFormatter = (data) => {
  const combinedData = [...data.companies, ...data.groups];

  const formattedData = combinedData.reduce((total, item) => {
    const childRoute = childRouteFinder(item.groupCompanies, item.children);

    if (childRoute) {
      let vkn = [];
      const newChild = item[childRoute].reduce((total2, item2) => {
        const childRoute2 = childRouteFinder(item2.groupCompanies, item2.children);
        if (childRoute2) {
          const newnewChild = item2[childRoute2].reduce((total3, item3) => {
            total3.push({
              name: item3.name,
              vkn: [item3.vkn],
              child: null,
            });
            return total3;
          }, []);

          // test
          total2.push({
            name: item2.name,
            vkn: newnewChild.reduce((totalx, itemx) => {
              totalx.push(...itemx.vkn);
              return totalx;
            }, []),
            child: newnewChild,
          });
        } else {
          total2.push({
            name: item2.name,
            vkn: [item2.vkn],
            child: null,
          });
        }
        return total2;
      }, []);
      newChild.forEach((element) => {
        vkn.push(element.vkn);
      });
      total.push({
        name: item.name,
        vkn,
        child: newChild,
      });
    } else {
      //* bu gelen item direk şirkettir.
      total.push({
        name: item.name,
        vkn: [item.vkn],
        child: null,
      });
    }

    total = total.sort((a, b) => a.name.localeCompare(b.name));

    return total;
  }, []);

  //*  ilk sırada default tüm şirketler seçenegi gelmesi için manuel buradan ekledik.
  formattedData.unshift({
    name: 'Tüm Şirketler',
    vkn: [],
  });

  return formattedData;
};

export default companyFormatter;
