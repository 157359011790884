import { v4 as uuidv4 } from 'uuid';

export const storageKey = 'selected-companies-vkn';

export const getSelectedCompanyFromStorage = () => {
  const selectedCompaniesFromStorage = localStorage.getItem(storageKey);
  return JSON.parse(selectedCompaniesFromStorage);
};

export const setValuesToLocalStorage = (vkn, label, selectedCompanies) => {
  localStorage.setItem(
    storageKey,
    JSON.stringify({
      vkn,
      label,
      selectedCompanies,
    }),
  );
};

export const setSelectedCompanyToStorage = (selectedCompanies) => {
  localStorage.setItem(storageKey, JSON.stringify(selectedCompanies));
};

export const deepFormat = (items, parentUIName) => {
  return items.map((item, index) => {
    item.vkn = getFlattenedVkn(item.vkn);
    item.id = uuidv4();

    const { id, name, vkn } = item;

    const optionUIName = createUIName(parentUIName, index);
    const properties = getOtherPropertiesForDeepFormat(item, optionUIName);

    return {
      id,
      name,
      vkn,
      optionUIName,
      ...properties,
      ...(parentUIName && { parentUIName }),
    };
  });
};

/**
 * @description
 * İç içe gelen vkn arraylerini tek bir arraye çevirir.
 * @param {['000000003', ['000000004', ['000000005', '000000006']]]}
 * @returns {["000000003", "000000004", "000000005", "000000006"]}
 */
export const getFlattenedVkn = (params = []) => {
  const res = params.flat(Infinity);

  return res;
};

export const getDistincVkn = (selectedCompanies = []) => {
  const vknSet = new Set();

  selectedCompanies.forEach((selectedCompany) => {
    selectedCompany.vkn.forEach((vkn) => {
      vknSet.add(vkn);
    });
  });

  return Array.from(vknSet);
};

export const createUIName = (parentUIName, index) => {
  const uiName = parentUIName || 'root';
  return `${uiName}-${index}`;
};

export const getOtherPropertiesForDeepFormat = (item, ...props) => {
  if (item.child && item.child.length > 0) {
    return { id: uuidv4(), children: deepFormat(item.child, ...props), type: 'node', isNode: true };
  }

  return {
    type: 'leaf',
    isLeaf: true,
    ...isAllItemsButton(item.vkn),
  };
};

export const isAllItemsButton = (vkn) => {
  return vkn && !vkn.length ? { isAll: true } : {};
};

export const getOptions = (options, selectedOptions) => {
  const cloneSelectedOptions = selectedOptions || getSelectedCompanyFromStorage();

  if (cloneSelectedOptions && cloneSelectedOptions.length) return cloneSelectedOptions;

  const defaultOptionIndex = options.findIndex((option) => !option.vkn.length);

  const { id, name = 'TÜM ŞİRKETLER', vkn = [], type = 'leaf', optionUIName = 'root-0' } = options[defaultOptionIndex];

  return [{ id, name, vkn, type, optionUIName, isLeaf: true, isAll: true }];
};
