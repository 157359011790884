import React, { useEffect } from 'react';
import cx from 'classnames';
import { createPortal } from 'react-dom';

import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';

import styles from './styles.scss';

export default function Modal({
  children,
  hide,
  closeOnClickOutside = true,
  showCloseIcon = true,
  title = ' ',
  theme,
  className = '',
}) {
  const closeModalOnClickOutside = () => {
    if (closeOnClickOutside) {
      hide();
    }
  };

  const closeModalByPressingEsc = (e) => {
    if (e.key === 'Escape') return hide();
  };

  useEffect(() => {
    document.addEventListener('keydown', closeModalByPressingEsc);

    return () => {
      document.removeEventListener('keydown', closeModalByPressingEsc);
    };
  }, []);

  useLockBodyScroll();

  return (
    <>
      {createPortal(
        <>
          <div
            className={cx(styles['modal-overlay'], styles[`modal-overlay-${theme}`])}
            onClick={closeModalOnClickOutside}
          />
          <div
            className={cx(styles['modal-container'], styles[`modal-container-${theme}`], className)}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog">
            <div className={styles['modal-header']}>
              {title && <span className={cx(styles['modal-title'], styles[`modal-title-${theme}`])}>{title}</span>}

              {showCloseIcon && (
                <button
                  type="button"
                  className={styles['modal-closeButton']}
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}>
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>

            <div className={cx(styles['modal-content'], styles[`modal-content-${theme}`])}>{children}</div>
          </div>
        </>,
        document.getElementById('modal-root'),
      )}
    </>
  );
}
