import React from 'react';

import Breadcrumb from 'containers/elements/@commons/breadcrumb';

import Widget from 'components/widget';

const breadcrumbs = [
  {
    name: 'Anasayfa',
    path: '/dashboard',
  },
  {
    name: 'Para Transferi',
    path: '/money-transfers',
  },
];

const TangibleAssets = () => {
  return (
    <Widget>
      <Breadcrumb title="Para Transferi" breadcrumbs={breadcrumbs} />
    </Widget>
  );
};

export default TangibleAssets;
