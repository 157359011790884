import { Icon, Loader } from 'components/index';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import styles from './styles.scss';
import request from 'library/@e5p-request';
import { useAppSelector } from 'src/redux/store';
import { ExcelIcon } from 'assets/icons/icons';

const { getBankReceipts, getExportHistory, getBankAccBalancesReport, getBankBalancesReport } = request;

export default function DailySummaryModalDownloadArea({
  selectedReceipts = [],
  filterParams,
  activitiesDetailTableData,
  dateRange,
  transactionIds,
  selectedAccounts = [],
}) {
  const {
    selectCompanies: { selectedVkn },
    theme: { theme },
  } = useAppSelector((state) => state);

  const [downloadLoading, setDownloadloading] = useState(false);

  const exportActivitiesByType = async (activityType) => {
    try {
      setDownloadloading(true);

      await getExportHistory({
        vknList: selectedVkn,
        bankCodes: [activitiesDetailTableData.budget.bankCode || activitiesDetailTableData.bankCode],
        bankAccountNos:
          selectedAccounts.length > 0
            ? selectedAccounts
            : filterParams.selectedBankAccounts.length > 0
            ? filterParams.selectedBankAccounts
            : [],
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        description: filterParams.description,
        minAmount: filterParams.minAmount ? Number(filterParams.minAmount) : null,
        maxAmount: filterParams.maxAmount ? Number(filterParams.maxAmount) : null,
        activityType: 'All',
        fileType: activityType,
        exportType: activityType === 'CSV' ? 0 : 1,
        currencies: filterParams.selectedCurrencies,
      });

      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const getBankAccountBalancesReportFunc = async () => {
    try {
      setDownloadloading(true);

      await getBankAccBalancesReport({
        vknList: selectedVkn,
        bankCodes: [activitiesDetailTableData.budget.bankCode || activitiesDetailTableData.bankCode],
        bankAccountNos: filterParams.selectedBankAccounts,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const getBankBalancesReportFunc = async () => {
    try {
      setDownloadloading(true);

      await getBankBalancesReport({
        vknList: selectedVkn,
        bankCodes: [activitiesDetailTableData.budget.bankCode || activitiesDetailTableData.bankCode],
        bankAccountNos: filterParams.selectedBankAccounts,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
      setDownloadloading(false);
    } catch (error) {
      console.log({ error });
      setDownloadloading(false);
    }
  };

  const getCollectiveReceipts = async () => {
    try {
      setDownloadloading(true);
      await getBankReceipts(selectedReceipts);
    } catch (error) {
      console.log({ error });
    } finally {
      setDownloadloading(false);
    }
  };

  const exportActivitiesByTransactionIds = async (activityType) => {
    try {
      setDownloadloading(true);
      await getExportHistory({
        vknList: selectedVkn,
        bankCodes: [activitiesDetailTableData.budget.bankCode || activitiesDetailTableData.bankCode],
        bankAccountNos: [],
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        description: '',
        minAmount: null,
        maxAmount: null,
        transactionIds,
        activityType: 'All',
        fileType: activityType,
        exportType: activityType === 'CSV' ? 0 : 1,
        currencies: [activitiesDetailTableData.currency],
      });
    } catch (error) {
      console.log({ error });
    } finally {
      setDownloadloading(false);
    }
  };

  if (downloadLoading) {
    return (
      <div className={styles['card-download-loader']}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.downloadArea}>
      {selectedReceipts.length === 0 && (
        <>
          <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
            <ExcelIcon />
            <div
              onClick={() => {
                getBankAccountBalancesReportFunc('XLSX');
              }}>
              <FormattedMessage id="molecule.accountActivities.accountBalance" defaultMessage="HESAP BAKİYELERİ" />
            </div>
          </div>
          <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
            <ExcelIcon />
            <div
              onClick={() => {
                getBankBalancesReportFunc('XLSX');
              }}>
              <FormattedMessage id="molecule.accountActivities.bankAccounts" defaultMessage="BANKA BAKİYELERİ" />
            </div>
          </div>
          <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
            <ExcelIcon />
            <div
              onClick={() => {
                exportActivitiesByType('XLSX');
              }}>
              <FormattedMessage id="sidebar.accountActivities.accountActivities" defaultMessage="HESAP HAREKETLERİ" />
              {selectedAccounts.length > 0 && `(${selectedAccounts.length})`}
            </div>
          </div>
        </>
      )}

      {selectedReceipts.length > 0 && (
        <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
          <Icon name="icon-navigation-file-download-24-px" className={styles.downloadIcon} />
          <div
            onClick={() => {
              getCollectiveReceipts('XLSX');
            }}>
            <FormattedMessage
              id="molecule.accountActivities.batchReceiptDownloadText"
              defaultMessage="Toplu Dekont İndir"
            />{' '}
            ({selectedReceipts.length})
          </div>
        </div>
      )}

      {selectedReceipts.length > 0 && (
        <div className={cx(styles['card-right-item'], styles[`card-right-item-${theme}`])}>
          <ExcelIcon />
          <div
            onClick={() => {
              exportActivitiesByTransactionIds('XLSX');
            }}>
            <FormattedMessage
              id="molecule.accountActivities.batchXLSXDownloadText"
              defaultMessage="Toplu Hareket İndir"
            />{' '}
            ({selectedReceipts.length})
          </div>
        </div>
      )}
    </div>
  );
}
