import React from 'react';
import cx from 'classnames';
import { useAppSelector } from 'src/redux/store';

import styles from '../styles.scss';

const Option = ({ value, label, isSelected, onChangeOption, className, ...props }) => {
  const { theme } = useAppSelector((state) => state.theme);

  return (
    <div
      className={cx(styles.option, styles[`option-${theme}`], className, {
        [styles['option-selected']]: isSelected,
      })}
      onClick={onChangeOption.bind(this, value)}
      {...props}>
      <div>{label}</div>
    </div>
  );
};

export default Option;
