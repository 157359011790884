import React from 'react';
import cx from 'classnames';

import { FormattedMessage } from 'react-intl';

import Authentication from 'utils/authentication';

import Icon from 'components/icon';
import NotificationItem from 'components/notification-item';
import Loader from 'components/loader';

import request from 'library/@e5p-request';
import { useAppSelector } from 'src/redux/store';

import styles from './styles.scss';

const { getNotifications } = request;

const Notifications = ({ notification }) => {
  const { theme } = useAppSelector((state) => state.theme);

  const [open, setOpen] = React.useState(false);

  const [list, setList] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const handleToogle = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  React.useEffect(() => {
    getNotifications()
      .then((res) => {
        setList(res.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (open === true) {
      document.addEventListener('click', () => {
        setOpen(false);
      });

      document.removeEventListener('click', () => {
        // setOpen(false);
      });
    }
  }, [open]);

  const deleteNotifications = () => {
    setList([]);
  };

  const isSession = Authentication.hasSession();
  if (!isSession) {
    return <div />;
  }

  return (
    <div className={styles.notification}>
      <div className={cx(styles['notification-button'])} onClick={handleToogle}>
        <Icon className={cx('icon-icon-social-notifications-24-px1', styles['notification-icon'])} />
        {notification && (
          <span className={cx(styles['notification-icon-badge'], styles[`notification-icon-badge-${theme}`])}></span>
        )}
        <input type="checkbox" className={styles['notification-input']} />
      </div>
      {open && (
        <div
          className={cx(styles['notification-list-wrapper'])}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <div className={cx(styles['notification-list-container'], styles[`notification-list-container-${theme}`])}>
            <div className={cx(styles['notification-list'], styles[`notification-list-${theme}`])}>
              <div className={cx(styles['notification-header'], styles[`notification-header-${theme}`])}>
                <div className={cx(styles['notification-header-text'], styles[`notification-header-text-${theme}`])}>
                  <FormattedMessage id="notification.title" defaultMessage="BİLDİRİMLER" />
                </div>
                <div onClick={deleteNotifications} className={cx(styles['notification-header-clean'])}>
                  <FormattedMessage id="notification.delete" defaultMessage="Hepsini Temizle" />
                </div>
              </div>

              <div className={styles['notification-items']}>
                {loading && (
                  <div
                    className={cx(styles['notification-items-loading'], styles[`notification-items-loading-${theme}`])}>
                    <Loader />
                  </div>
                )}
                {!loading && list.length === 0 && (
                  <div
                    className={cx(
                      styles['notification-items-empty-state'],
                      styles[`notification-items-empty-state-${theme}`],
                    )}>
                    <FormattedMessage
                      tagName="span"
                      id="notification.empty"
                      defaultMessage="Herhangi bir bildirim bulunmamaktadır."
                    />
                  </div>
                )}
                {!loading && (
                  <div className={styles['list-content']}>
                    {list.length > 0 &&
                      list.map((item, index) => {
                        return <NotificationItem key={index.toString()} {...item} />;
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
