import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import Breadcrumb from 'containers/elements/@commons/breadcrumb';

import BankList from 'containers/elements/banks/bank-list';
import BankSummary from 'containers/elements/banks/bank-summary';

/* import BalanceChart from 'containers/elements/dashboard/balance-chart'; */
import BalanceConvertCard from 'containers/elements/banks/balance-convert-card';

import BankBranchList from 'containers/elements/banks/bank-brach-list';

import { useAppSelector } from 'src/redux/store';

import Widget from 'components/widget';

const breadcrumbs = [
  {
    name: <FormattedMessage id="general.mainPage" defaultMessage="Ana Sayfa" />,
    path: '/dashboard',
  },
  {
    name: <FormattedMessage id="sidebar.banks" defaultMessage="Bankalar" />,
    path: '/banks',
  },
];

const Banks = (props) => {
  const { selectedBank } = useAppSelector((state) => state.selectBank);

  const [tempLoading, setTempLoading] = React.useState(false);
  return (
    <>
      <Widget>
        <Breadcrumb
          title={<FormattedMessage id="sidebar.banks" defaultMessage="Bankalar" />}
          breadcrumbs={breadcrumbs}
        />
      </Widget>
      <BankList changeTempLoading={(value) => setTempLoading(value)} />
      <Widget>
        <BankSummary loading={tempLoading} />
      </Widget>
      <Widget row={window.screen.width === 768 ? 1 : 2} style={{ flexDirection: 'row !important' }}>
        <BalanceConvertCard selectedBank={selectedBank} />
      </Widget>
      <BankBranchList loading={tempLoading} history={props.history} />
    </>
  );
};

export default injectIntl(Banks);
