import React from 'react';

import styles from './styles.scss';

const HalfCircleDonatChart = ({ children, ratio }) => {
  return (
    <div
      className={styles['semi-donut']}
      style={{
        '--percentage': String(ratio).replace(',', '.'),
        '--fill': '#0f62fe',
      }}>
      {children}
    </div>
  );
};

export default HalfCircleDonatChart;
