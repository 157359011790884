import Axios from './axios';
import Apollo from './apollo';

class Services {
  constructor({ baseURL, staticURL, uri, headers }) {
    const axios = new Axios({ baseURL });
    const staticAxios = new Axios({ baseURL: staticURL });
    const client = new Apollo({ uri, headers });
    return {
      axios: axios.axios,
      staticAxios: staticAxios.axios,
      setHeaders: axios.setHeaders,
      client: client.client,
    };
  }
}

export default Services;
